.header-wrapper {
  position: relative;
}

.sticky-banner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.has-banner .sticky-banner-container {
  transform: translateY(0);
}

.navbar {
  top: 0 !important;
}

.navbar.sticky-header {
  top: 58px !important;
}

/* Dark mode button positioning */
.navbar .dark-mode--btn {
  top: 31px !important;
}

.has-banner .navbar .dark-mode--btn {
  top: 71px !important;
}

.navbar.sticky-header .dark-mode--btn {
  top: 22px !important;
}

.has-banner .navbar.sticky-header .dark-mode--btn {
  top: 62px !important;
}

@media (max-width: 991px) {
  .navbar .dark-mode--btn {
    top: 21px !important;
  }
  
  .has-banner .navbar .dark-mode--btn {
    top: 61px !important;
  }
}

/* Google translate positioning */
.g-translate {
  top: 7px !important;
}

.has-banner .g-translate {
  top: 37px !important;
}

@media (max-width: 991px) {
  .g-translate {
    top: 3px !important;
  }
  
  .has-banner .g-translate {
    top: 23px !important;
  }
}
