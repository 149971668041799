:root {
  --primary-color: 254, 91, 234;
  --opacity-fade: 0.5;
  --border-radius: 10px;
  --secondary-color: 195, 231, 17;
  --purple-color: #5714bc;
  /* --width-100: 100%; */
  --inactive-color: 189, 192, 198;
}

/* Fonts */
@font-face {
  font-family: "Artega";
  src: url("./assets/fonts/artega.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Teriyake Halenoir UltraLight";
  src: url("./assets/fonts/Teriyake-Halenoir-UltraLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Teriyake HalenoirExpanded Medium";
  src: url("./assets/fonts/Teriyake-HalenoirExpanded-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url("./assets/fonts/PlusJakartaSans-VariableFont_wght.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* button style start */
.btn {
  --btn-padding-x: 0.75rem;
  --btn-padding-y: 0.375rem;
  /* --btn-font-family: 'Artega', sans-serif; */
  --btn-font-family: "Montserrat", sans-serif;
  --btn-font-size: 1rem;
  --btn-font-weight: 500;
  --btn-line-height: 1.5;
  --btn-color: #212529;
  --btn-bg: transparent;
  --btn-border-width: 1px;
  --btn-border-color: transparent;
  --btn-border-radius: 100px;
  --btn-hover-border-color: transparent;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 0.65;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.primary-btn {
  --btn-color: #fff;
  --btn-bg: var(--pc-btn-primary-bg);
  --btn-border-color: var(--pc-btn-primary-bg);
  --btn-hover-color: #fff;
  --btn-hover-bg: var(--pc-btn-primary-bg);
  --btn-hover-border-color: var(--pc-btn-primary-bg);
  --btn-focus-shadow-rgb: 49, 132, 253;
  --btn-active-color: var(--pc-btn-primary-bg);
  --btn-active-bg: var(--pc-btn-primary-bg);
  --btn-active-border-color: var(--pc-btn-primary-bg);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: var(--pc-btn-primary-bg);
  --btn-disabled-border-color: var(--pc-btn-primary-bg);
}

.primary-btn2 {
  --btn-color: #fff;
  --btn-bg: var(--pc-btn-primary-bg);
  --btn-border-color: var(--pc-btn-primary-bg);
  --btn-hover-color: #fff;
  --btn-hover-bg: var(--pc-btn-primary-bg);
  --btn-hover-border-color: var(--pc-btn-primary-bg);
  --btn-focus-shadow-rgb: 49, 132, 253;
  --btn-active-color: var(--pc-btn-primary-bg);
  --btn-active-bg: var(--pc-btn-primary-bg);
  --btn-active-border-color: var(--pc-btn-primary-bg);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: var(--pc-btn-primary-bg);
  --btn-disabled-border-color: var(--pc-btn-primary-bg);

}



.btn-small {
  padding: 8px 13px;
  font-size: 14px;
  line-height: 32px;
}

.primary-btn:hover {
  background: #c33dad;
  color: var(--white-color);
  border-color: transparent;
}

.secondary-btn {
  --btn-color: #333333;
  --btn-bg: rgba(var(--secondary-color), 1);
  --btn-border-color: rgba(var(--secondary-color), 1);
  --btn-hover-color: #ffffff;
  --btn-hover-bg: #333333;
  --btn-hover-border-color: #333333;
  --btn-focus-shadow-rgb: 49, 132, 253;
  --btn-active-color: rgba(var(--secondary-color), 1);
  --btn-active-bg: rgba(var(--secondary-color), 1);
  --btn-active-border-color: rgba(var(--secondary-color), 1);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: var(--pc-btn-primary-bg);
  --btn-disabled-border-color: var(--pc-btn-primary-bg);
}

.inactive-btn {
  --btn-color: #333333;
  --btn-bg: rgba(var(--inactive-color), 0.5);
  --btn-border-color: rgba(var(--inactive-color), 0.5);
  --btn-hover-color: #ffffff;
  --btn-hover-bg: #333333;
  --btn-hover-border-color: #333333;
  --btn-focus-shadow-rgb: 49, 132, 253;
  --btn-active-color: rgba(var(--inactive-color), 0.5);
  --btn-active-bg: rgba(var(--inactive-color), 0.5);
  --btn-active-border-color: rgba(var(--inactive-color), 0.5);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: var(--pc-btn-primary-bg);
  --btn-disabled-border-color: var(--pc-btn-primary-bg);
}

.secondary-btn:hover {
  border-color: var(--btn-hover-border-color);
  background: #333333;
  --btn-color: #ffffff;
}

.secondary-btn path {
  stroke: #333333;
}

.secondary-btn:hover path {
  stroke: #ffffff;
}

.purple-btn {
  background: #5714bc;
  border: 1px solid #5714bc;
  color: #ffffff;
}

.purple-btn:hover {
  background: #460f99;
}

.filter-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btn-icon svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.btn-icon {
  justify-content: center;
}

.btn-bg-none {
  --btn-color: #222222;
  background-color: transparent;
  color: var(--btn-color);
  --btn-border-color: var(--btn-color);
}

.darkMode--on .btn-bg-none {
  --btn-color: #ffffff;
}

.primary-btn path,
.btn-bg-none:hover path {
  fill: #ffffff;
}

.btn-bg-none path {
  fill: var(--btn-color);
}

.wishlist-btn {
  margin-right: 20px;
  --btn-border-color: rgba(var(--secondary-color));
}

.btn-large {
  --btn-padding-y: 15px;
  --btn-padding-x: 40px;
}

.btn-medium {
  --btn-padding-y: 10px;
  --btn-padding-x: 30px;
}

.btn+.btn {
  margin-left: 10px;
}

/* .btn-small{
    padding: 8px 13px;
} */

/* buttons style end */

/* width style */

.width-100 {
  width: var(--width-100);
}

.bg-white {
  background-color: var(--white-color);
}

.darkMode--on .bg-white {
  background-color: #101010;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.outer-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.outer-row>div {
  padding-left: 15px;
  padding-right: 15px;
}

.outer-row.uploadimages-inforow-outer>div {
  padding: 0;
}

.primary-bg-color {
  background-color: rgba(var(--primary-color), var(--opacity-fade));
}

.darkMode--on .primary-bg-color {
  background-color: rgba(var(--primary-color), 1);
}

.secondary-bg-color {
  background-color: rgba(var(--secondary-color), var(--opacity-fade));
}

.darkMode--on .secondary-bg-color {
  background-color: rgba(var(--secondary-color), 1);
}

.rounded-corner {
  border-radius: var(--border-radius);
}

.mt-5 {
  margin-top: 50px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.ms-10 {
  margin-left: 10px !important;
}

.my-2 {
  margin-block: 20px !important;
}

.p-3 {
  padding: 30px;
}

.fs-3 {
  font-size: 30px;
}

.column-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.prev-image {
  border-top: 5px solid rgba(var(--primary-color), 1);
  border-right: 5px solid rgba(var(--primary-color), 1);
  border-bottom: 5px solid rgba(var(--secondary-color), 1);
  border-left: 5px solid rgba(var(--secondary-color), 1);
  border-radius: 25px;
  padding: 15px;
}

.prev-image img {
  max-width: 100%;
  border-radius: 10px;
}

.search-result-column {
  flex: 0 0 20%;
  padding-block: 15px;
  position: relative;
}

.search-result-column img {
  aspect-ratio: 1/1;
  object-fit: cover;
}

.search-result-column::after {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  z-index: 1;
}

.dashboard-main .search-result-column::after {
  width: 100%;
}

.search-result-column p {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  text-align: center;
  color: #fff;
  z-index: 2;
  padding: 10px;
}

.darkMode--on h4,
.darkMode--on .switchLable {
  color: #d7d7d7;
}

.position-relative {
  position: relative !important;
}

.resources-static .flex-column-20 {
  flex: 0 0 20%;
}

.resources-static .inner-column img {
  width: auto;
  height: auto;
  transition: 0.3s all;
}

.resources-static .inner-column:hover img {
  transform: scale(1.05);
}

.weaving-stories-wrap .resources-static h2 {
  text-align: center;
}

.weaving-stories-wrap .resources-static .flex-column-20 {
  flex: 0 0 33.33%;
}

@media (min-width: 768px) {
  .column-medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .p-medium-4 {
    padding: 40px;
  }
}

@media (min-width: 992px) {
  .column-large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .p-large-6 {
    padding: 60px;
  }
}

@media (min-width: 1200px) {
  .column-large-3 {
    flex: 1 0 400px;
  }
}

@media (max-width: 1400px) {
  .search-result-column {
    flex: 0 0 25%;
  }
}

@media (max-width: 1400px) {
  .search-result-column {
    flex: 0 0 25%;
  }
}

@media (max-width: 1279px) {
  .search-result-column {
    flex: 0 0 33.33%;
  }
}

@media (max-width: 767px) {
  .search-result-column {
    flex: 0 0 50%;
  }
}

@media (max-width: 575px) {
  .search-result-column {
    flex: 0 0 100%;
  }
}

/**/

.section-heading img {
  max-width: 40px;
}

.darkMode--on .section-heading img {
  filter: contrast(0);
}

.member-column {
  padding: 30px;
  --opacity-fade: 1;
}

.member-column img {
  max-width: 90px;
}

.member-column p {
  color: var(--pc-heading-clr) !important;
  font-size: var(--pc-primary-text);
  line-height: 24px;
}

.sizes-btn {
  display: inline-block;
  padding: 9px 20px;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  margin: 0 5px;
  font-weight: 700;
  font-size: 18px;
}

.onhoverIcon {
  position: absolute;
  right: 12px;
  top: 5px;
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 12px 0 0;
  gap: 8px;
  opacity: 0;
  transition: 0.3s all;
  /* z-index: 99; */
  cursor: pointer;
}

.contributorCardWrap:hover .onhoverIcon {
  top: 0;
  opacity: 1;
  z-index: 99;
}

.contributorCardWrap:hover .onhoverIcon2 {
  top: 0;
  opacity: 1;
  z-index: 99;
}

.onhoverIcon path {
  /* fill: #ffffff; */
}

.AIIcon path {
  fill: #000000 !important;

}

.onhoverIcon p {
  background: rgba(0, 0, 0, 0.5);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.onhoverIcon2 {
  position: absolute;
  right: 0px;
  left: 12px;
  top: 5px;
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 12px 0 0;
  gap: 8px;
  opacity: 0;
  transition: 0.3s all;
  cursor: pointer;
}

.onhoverIcon2B {
  position: absolute;
  right: 0px;
  left: 12px;
  top: 2px;
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 12px 0 0;
  gap: 8px;
  opacity: 1;
  transition: 0.3s all;
  cursor: pointer;
}

.contributorCardWrap:hover .onhoverIcon2:hover {
  top: 0;
  opacity: 1;
  z-index: 99;
}



.onhoverIcon2 p, .onhoverIcon2B p {
  background: rgba(0, 0, 0, 0.5);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.onhoverIcon p svg {
  width: 17px;
  height: 17px;
}

.form-container {
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button {
  cursor: pointer;
}

.ms-10 {
  margin-left: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*  Home Page Css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}

:root {
  --pc-heading: 50px;
  --pc-heading-clr: #333333;
  --white-color: #ffffff;
  --pc-heading-lh: 38px;
  --pc-heading-fw: 700;
  --pc-primary-text: 16px;
  --pc-btn-br: 3px;
  --pc-default-transition: all 0.3s;
  --pc-btn-primary-bg: #fe5bea;
  --pc-btn-text-fs: 16px;
  --pc-btn-text-lh: 38px;
  --pc-dark-heading-clr: #d7d7d7;
  --pc-dark-text-clr: var(--pc-dark-heading-clr);
  --pc-form-input-bg: #f4f4f4;
  --pc-form-input-br: 3px;
  --pc-form-input-btn-bg: #222222;
  --pc-form-input-text-fs: 16px;
  --pc-form-input-text-clr: #666666;
  --pc-dark-background-clr: rgba(0, 0, 0, 0.1);
}

/* buttons */

/* .btn {
   min-width: 100px;
   border-radius: var(--pc-btn-br);
   font-size: var(--pc-btn-text-fs);
   line-height: var(--pc-btn-text-lh);
   color: var(--white-color);
   font-weight: 500;
   padding: 8px 40px;
   display: inline-block;
   text-transform: capitalize;
   text-align: center;
   transition: 0.3s all;
   cursor: pointer;
   font-family: 'Montserrat', sans-serif;
 } */

/* .primary-btn {
   border: 1px solid var(--pc-btn-primary-bg);
   background-color: var(--pc-btn-primary-bg);
 } */

.container .container {
  padding: 0;
}

.collection--btn-browse {
  position: relative;
}

.pichastock--btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pichastock--btn svg {
  margin-right: 5px;
  vertical-align: middle;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px !important;
}

body,
input,
textarea {
  font-family: "PlusJakartaSans", sans-serif !important;
}

/* h1 {
  font-family: 'Artega', sans-serif !important;
  font-size: 36px; 
}

h2 {
  font-family: 'Artega', sans-serif !important;
  font-size: 30px; 
}

h3 {
  font-family: 'Artega', sans-serif !important;
  font-size: 24px; 
}

h4 {
  font-family: 'Artega', sans-serif !important;
  font-size: 20px; 
}

h5 {
  font-family: 'Artega', sans-serif !important;
  font-size: 18px; 
}

h6 {
  font-family: 'Artega', sans-serif !important;
  font-size: 16px; 
} */

/* h1, h2, h3, h4, h5, h6 {
  font-family: 'Artega', sans-serif !important;
} */

/* h1, h2, h3, h4, h5, h6 {
  font-family: 'Artega', sans-serif !important;
} */

/* body {
  font-family: 'Teriyake Halenoir UltraLight', sans-serif !important;
} */

.subheadings {
  font-family: "PlusJakartaSans", sans-serif !important;
}

.headings {
  font-family: "Artega", sans-serif !important;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  vertical-align: middle;
}

.container {
  padding: 0 70px;
}

/* common css */

.section-heading {
  font-size: var(--pc-heading);
  line-height: var(--pc-heading-lh);
  color: var(--pc-heading-clr);
  font-weight: var(--pc-heading-fw);
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-heading img,
.section-heading svg {
  margin-right: 12px;
}

.text-center {
  text-align: center !important;
}

.left-text {
  text-align: left !important;
}

.m-auto {
  margin: auto !important;
}

.ms-50 {
  margin-left: 50px;
}

.d-none {
  display: none !important;
}

.m-right {
  margin-right: 20px;
}

/* Top Bar */
.topBar {
  background-color: var(--pc-btn-primary-bg);
  padding: 8px 12px;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.topBar p {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.darkMode--on .topBar p {
  color: #fff;
}

.topBar p span {
  text-decoration: underline;
}

/*navbar section*/

.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: 70px;
  padding-block: 13px;
  position: fixed;
  top: 0px;
  /* top: 40px !important; Adjusted to be just below the banner */
  z-index: 10;
  background: var(--white-color);
  transition: 0.5s all;
  box-shadow: 0 0 10px -1px rgb(0 0 0 / 7%);
  left: 0;
}


.navbar .dark-mode--btn {
  cursor: pointer;
  position: fixed;
  top: 31px; 
  /* top: 71px; Adjusted to be just below the banner */
  right: 0;
}


.navbar.sticky-header .dark-mode--btn {
  top: 22px;
  /* top: 62px; Adjusted to be just below the banner */
}





main {
  margin-top: 98px;
}

.navbar.home-header.sticky-header {
  background: #fafafa;
}

.darkMode--on .navbar.home-header.sticky-header,
.darkMode--on .navbar {
  background: #1a1a1a;
}

/* .sticky-header .sitelogo {
     max-width: 80px;
     display: block;
 } */
.sitelogo img {
  transition: 0.3s all;
}

.sticky-header .sitelogo img {
  width: 80px;
  filter: brightness(0);
}

.darkMode--on .home-header.sticky-header .sitelogo img {
  filter: brightness(1);
}

.sticky-header .navbar--menus ul li a {
  font-size: 14px;
}

.home-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 34px;
  left: 0;
  top: 0;
  color: #fff;
  background: none;
  z-index: 9999;
  box-shadow: none;
}

.darkMode--on .navbar.home-header {
  background-color: transparent;
}

.navbar--menus {
  display: flex;
  align-items: center;
  margin-right: 70px;
  position: relative;
}

.navbar--searchbar-outer {
  position: absolute;
  max-width: 500px;
  right: -30px;
  top: 70px;
  height: auto;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s all;
  width: 90%;
}

.open-search .navbar--searchbar-outer {
  max-height: 100vh;
  opacity: 1;
}

.search-menu {
  cursor: pointer;
}

.search-menu path {
  fill: #333;
}

.darkMode--on .search-menu path {
  fill: var(--white-color);
}

.home-header .search-menu path {
  fill: var(--white-color);
}

.navbar--menus ul {
  display: flex;
  align-items: center;
  /* margin-right: 50px; */
}

.home-header .search-field-outer,
.home-header .header-search-bar {
  display: none;
}

.home-header.sticky-header .header-search-bar {
  display: block;
}

.search-field-outer {
  margin-left: 50px;
  display: none;
}

.navbar--menus ul li {
  display: inline-block;
  position: relative;
}

.navbar--submenu-content:before {
  content: "";
  border-bottom: 12px solid #f5f5f5;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  z-index: 9;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%, 0);
}

.sitelogo {
  transition: 0.5s all;
  width: auto;
  display: block;
}

.sticky-header .sitelogo {
  width: 80px;
}

.navbar--menus ul li a {
  font-size: var(--pc-primary-text);
  line-height: 24px;
  color: var(--pc-heading-clr);
  font-weight: 400;
  position: relative;
  transition: 0.3s all;
}

[href] {
  cursor: pointer;
}

.navbar--menus ul li a:hover,
.navbar--menus ul li p:hover {
  color: var(--pc-btn-primary-bg) !important;
}

.navbar--menus-dropdown:hover:before {
  border-top: 2px solid #fe5bea !important;
  border-right: 2px solid #fe5bea !important;
}

.darkMode--on .navbar--menus ul li a {
  color: var(--white-color);
}

.home-header .navbar--menus ul li a,
.darkMode--on .home-header.sticky-header .navbar--menus ul li a {
  color: var(--white-color);
}

@media (min-width: 992px) {
  .home-header.sticky-header .navbar--menus ul li a {
    color: var(--pc-heading-clr);
  }
}

.navbar:not(.home-header) .sitelogo img {
  filter: brightness(0);
}

.navbar--menus-dropdown {
  padding-right: 16px;
}

.navbar--menus-dropdown:before,
.darkMode--on .home-header.sticky-header .navbar--menus-dropdown:before {
  content: "";
  position: absolute;
  height: 6px;
  width: 6px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  top: 4px;
  right: 0;
  transform: rotate(135deg);
}

.home-header.sticky-header .navbar--menus-dropdown:before {
  border-top: 2px solid var(--pc-heading-clr);
  border-right: 2px solid var(--pc-heading-clr);
}

.navbar:not(.home-header) .navbar--menus-dropdown:before {
  border-color: var(--pc-heading-clr);
}

.navbar--menus ul li+li {
  margin-left: 45px;
}

.navbar--menus ul li i {
  font-size: 18px;
  line-height: 26px;
}

.navbar--submenu {
  position: absolute;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: var(--pc-default-transition);
  top: 50 px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: var(--pc-btn-br);
  z-index: 1;
  min-width: 248px;
  width: 100%;
  left: 20px;
  transform: translate(-50%, 0px);
}

.darkMode--on .navbar--submenu {
  box-shadow: 5px 5px 5px -2px #111111;
}

.darkMode--on .navbar--submenu {
  background: #222222;
}

.darkMode--on .navbar--submenu-content:before {
  border-bottom: 12px solid #222222;
}

.navbar--menus ul li:hover .navbar--submenu {
  opacity: 1;
  visibility: visible;
}

.navbar--submenu-mega ul {
  flex-wrap: wrap;
  border-radius: 5px;
  overflow: hidden;
  margin: 0;
}

.navbar--submenu-mega ul li {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0 !important;
}

.darkMode--on .navbar--submenu-mega ul li:nth-child(odd) {
  background-color: transparent;
}

.navbar--submenu-mega ul li:nth-child(odd) {
  background-color: transparent;
}

.navbar--submenu-mega ul li:hover {
  background-color: #f5f5f5;
}

.darkMode--on .navbar--submenu-mega ul li:hover {
  background-color: transparent;
}

.navbar--submenu .navbar--submenu-mega ul li a {
  font-size: 16px;
  line-height: 36px;
  color: #666666;
  padding: 6px 20px;
  display: block;
  width: 100%;
}

.darkMode--on .navbar--submenu .navbar--submenu-mega ul li a {
  color: var(--white-color);
  width: 100%;
}

.darkMode--on .navbar--submenu .navbar--submenu-mega ul li a:hover {
  background-color: var(--pc-heading-clr);
}

.navbar--submenu .navbar--submenu-mega ul li+li {
  margin-top: 8px;
}




.navbar .dark-mode--btn-black {
  display: none;
}

.navbar .dark-mode--btn img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  display: none;
}

.breadcrumb-arrow {
  padding: 0 12px 0;
}

.breadcrumb-arrow .svg-inline--fa.fa-angle-right {
  height: 13px;
}

.navbar--searchbar {
  margin-right: 0;
  position: relative;
  width: 100%;
}

.navbar--searchbar input {
  border-radius: 4px;
  background-color: #ededed;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  padding: 17px 60px 17px 30px;
  color: #666666;
  border-radius: var(--pc-btn-br);
  width: 100%;
}

/* .home-header .navbar--searchbar {
   display: none;
 } */

.search-action {
  position: absolute;
  right: 0;
  height: 100%;
  width: 60px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s all;
}

.search-action:hover {
  background: #000000;
  border-radius: 0 3px 3px 0px;
}

.search-action:hover path {
  fill: var(--white-color);
}

.search-action svg {
  height: 22px;
}

.dark-mode--btn-white {
  border: none;
  background: #222222;
  padding: 0 0px 0 0px;
  border-radius: 3px 0 0 3px;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.dark-mode--btn-white:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 22px;
  background: #ebebeb;
  position: absolute;
  left: 7px;
  top: 6px;
  box-sizing: border-box;
}

.dark-mode--btn-white:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 14px;
  background: #222222;
  width: 14px;
  height: 14px;
  border-radius: 30px;
}

.darkMode--on .dark-mode--btn-white {
  background: #ebebeb;
}

.darkMode--on .dark-mode--btn-white:before {
  background: #222222;
}

.darkMode--on .dark-mode--btn-white:after {
  background: #ebebeb;
}

/*navbar-fixed section*/

.navbar--fixed {
  background: #fff;
  position: relative;
  border-bottom: 1px solid #e3e3e3;
}

.navbar--fixed ul li a {
  color: var(--pc-heading-clr);
}

.navbar--fixed .navbar--menus-dropdown:before {
  border-top: 2px solid var(--pc-heading-clr);
  border-right: 2px solid var(--pc-heading-clr);
}

.navbar--fixed .navbar--submenu {
  border: 1px solid #e3e3e3;
}

.navbar--fixed .navbar--menus {
  display: flex;
  align-items: center;
}

.navbar--fixed .navbar--searchbar {
  margin-right: 70px;
  position: relative;
}

.navbar--fixed .navbar--searchbar input {
  border-radius: 4px;
  background-color: #f4f4f4;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  padding: 17px 60px 17px 30px;
  color: #666666;
  border-radius: var(--pc-btn-br);
  margin-left: 50px;
}

.navbar--fixed .navbar--searchbar i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--pc-heading-clr);
  font-size: 20px;
  cursor: pointer;
}

.navbar--fixed .navbar--submenu-mega ul {
  margin: 0;
  overflow: inherit;
}

.navbar--fixed .dark-mode--btn-black {
  display: block;
}

.navbar--fixed .dark-mode--btn-white {
  display: none;
}

/*banner section*/

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 213px;
  padding-bottom: 165px;
}

.banner--main-inside {
  color: #fff;
  margin: auto;
  max-width: 65%;
  text-align: center;
  z-index: 9;
  position: relative;
}

.banner--main-inside h1 {
  font-size: 34px;
  line-height: 44px;
  color: var(--white-color);
  text-align: left;
  margin-left: 10px;

}

.banner--main-inside p {
  font-size: 18px;
  line-height: 27px;
  margin: 9px 15px 42px;
  text-align: left;
}

.banner--main-search-txt {
  max-width: 900px;
  margin: auto;
}

.banner .search_box {
  align-items: flex-start;
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 6px;
}

.search_box svg {
  margin-right: 10px;
}

.banner .dropdown {
  background: #ffffff;
  border-radius: 10px 0 0 10px;
  position: relative;
  max-width: 210px;
  flex: 0 0 210px;
}

.banner .MuiAutocomplete-root .MuiAutocomplete-tag {
  order: 2;
}

.banner .search_box input[type="search"] {
  order: 0;
  border: none;
  padding: 15px 90px 0px 10px !important;
  height: 100%;
  width: 100% !important;
  background: #ffffff;
}

.banner .css-1jruifh-MuiStack-root,
.banner .dropdown+div {
  width: calc(100% - 280px);
}

@media (max-width: 575px) {

  .banner .css-1jruifh-MuiStack-root,
  .banner .dropdown+div {
    width: calc(100% - 170px);
  }
}

.banner .banner-search-btn {
  z-index: 9;
  background: #fff;
}

.banner .dropdown+div .MuiOutlinedInput-root {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.banner .dropdown+div .MuiOutlinedInput-root {
  background: #ffffff;
  position: absolute;
  width: 100%;
  border-radius: 0;
}

.darkMode--on .banner .dropdown+div .MuiOutlinedInput-root {
  background: #0e0e0e;
}

.banner .dropbtn {
  background-color: transparent;
  color: var(--pc-heading-clr);
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Artega', sans-serif; */
  width: 100%;
  padding: 25px 0;
  text-indent: -30px;
}

.banner .dropbtn:before {
  content: "";
  position: absolute;
  height: 6px;
  width: 6px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  top: 29px;
  right: 20px;
  transform: rotate(135deg);
}

.banner .dropbtn:after {
  content: "";
  height: calc(100px - 55px);
  right: 0;
  width: 1px;
  position: absolute;
  top: 13px;
  background: #c3e711;
}

.banner .dropdown-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 69px;
  left: 0;
  color: #000;
  border-radius: 6px;
  transition: var(--pc-default-transition);
  width: 100%;
}

.banner .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.banner .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.banner .show {
  opacity: 1;
  visibility: visible;
}

.banner .dropdown-content li {
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
  text-align: left;
  /* font-size: 15px; */
  cursor: pointer;
}

.dropdownIconText {
  display: 'flex';
  align-items: 'center';

}

.banner .dropdown li:hover {
  background-color: #f5f5f5;
}

.darkMode--on .banner .dropdown-content li:hover {
  background-color: #0e0e0e;
}

/* .banner .search_box .search_field {
   width: 100%;
   height: 100%;
   position: relative;
 } */

.banner .search_box .search_field .input {
  width: 100%;
  height: 100%;
  border: 0px;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 70px;
  color: var(--pc-heading-clr);
  border-radius: 6px;
}

.banner-search-btn {
  position: absolute;
  top: 0;
  font-size: 24px;
  line-height: 38px;
  color: #c3e711;
  right: 0px;
  cursor: pointer;
  width: 70px;
  height: 100%;
  border: none;
  background: none;
  transition: 0.3s all;
  border-radius: 0 6px 6px 0;
}

.banner-search-btn:hover {
  background: #c3e711 !important;
  color: var(--white-color);
}

.banner .search_box .search_field input::placeholder {
  color: var(--pc-heading-clr);
  font-weight: 500;
}

.banner .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.darkMode--on .banner .search_box .search_field input::placeholder {
  color: var(--pc-dark-heading-clr);
}

.movingIconTitle {
  /* color: red; */
  font-size: 25px !important;
}

.banner--trending ul li {
  display: inline-block;
  font-size: 15px;
  line-height: 0px;
  color: var(--white-color);
  margin-right: 5px;

}

.banner--trending {
  text-align: left;
  padding-top: 30px;
}

.banner--trending ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.banner--trending ul li+li {
  margin-left: 10px;
}

/* .banner--trending a {
  font-weight: 700;
  padding: 12px 5px;
  width: 120px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 30px;
} */

.banner--trending ul li svg {
  max-width: 30px;
  height: auto;
  margin-right: 7px;
  vertical-align: middle;
}

/* collection section */

.collection--main {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 75px;
}

.collection--main h2 {
  width: fit-content;
  margin: auto;
  position: relative;
}

.collection--main h2:before,
.collection--main h2:after {
  position: absolute;
  top: 10px;
  content: "";
  width: 93px;
  height: 104px;
  background: url("./assets/greenstar.png") no-repeat;
  background-position: center center;
}

.collection--main h2:before {
  /* content: url('./assets/greenstar.png'); */
  left: -183px;
}

.collection--main h2:after {
  /* content: url('./assets/greenstar.png'); */
  right: -183px;
}

.collection--main p {
  max-width: 690px;
  font-size: 18px;
  line-height: 27px;
  color: #666666;
  font-weight: 500;
  margin: 25px auto 20px;
}

.collection--main-btns {
  max-width: 1275px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.collection-tags {
  padding: 10px;
  text-align: center;
}

.collection--main-btns .collection-multi-btn {
  border-radius: 23px;
  background-color: transparent;
  padding: 10px 30px;
  font-size: 15px;
  line-height: 26px;
  color: #666666;
  font-weight: 500;
  text-transform: capitalize;
  transition: var(--pc-default-transition);
  display: block;
  white-space: pre;
  border: 1px solid rgba(var(--secondary-color), 1);
}

.collection--main-btns .collection-multi-btn:hover {
  background: rgba(var(--secondary-color), 1);
  /* color: var(--white-color); */
}

.collection--multiimg-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 12px;
}

.collection--multiimg-view p {
  font-size: 20px;
  line-height: 30px;
  color: var(--pc-heading-clr);
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
  flex: 0 0 100%;
  text-align: left;
  padding-left: 10px;
}

.collection--multiimg-view a {
  font-size: 16px;
  line-height: 38px;
  color: var(--pc-heading-clr);
  font-weight: 600;
  text-transform: capitalize;
  transition: var(--pc-default-transition);
}

.collection_headings_div {
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.collection_headings {
  font-size: 21px;
  font-family: "Artega", sans-serif !important;
}

.collection_headings_2 {
  font-size: 18px;
  /* font-family: "Artega", sans-serif !important; */
}

.collection--multiimg-view a:hover {
  color: #fe5bea;
}

.banner--trending li:last-child a {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.banner--trending li:last-child svg {
  margin: 0;
}

.collection--multiimg {
  display: flex;
  margin-top: 60px;
  margin-left: -30px;
  flex-wrap: wrap;
}

.collection--multiimg-box {
  width: calc(33.33% - 30px);
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 30px;
  margin-left: 30px;
  /* border-top: 3px solid #c3e711;
  border-right: 3px solid #c3e711;
  border-left: 3px solid #fe5bea; */
  /* border-bottom: 3px solid #fe5bea; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.wishlistPage-Outer .wishlistPage-inner .collection--multiimg-box {
  width: calc(25% - 30px);
}

.collection-carousel .collection--multiimg-box {
  width: 100%;
  margin: 0;
}

.carousel-item-padding-40-px {
  padding: 0 15px;
}

.collection--multiimg-box-img {
  position: relative;
  display: flex;
  width: 100%;
  height: 0;
  padding-top: 72%;
  transition: 0.3s ease-in-out;
}

.collection--multiimg-left,
.collection--multiimg-right {
  position: absolute;
  top: 0;
  height: 100%;
}

.collection--multiimg-right {
  right: 0;
  display: flex;
  flex-direction: column;
  width: calc(100% - 65%);
}

.collection--multiimg-left {
  overflow: hidden;
  width: calc(65% - 10px);
  border-radius: 20px;
}

.collection--multiimg-right-top,
.collection--multiimg-right-bottom {
  position: relative;
  overflow: hidden;
}

.collection--multiimg-right-bottom {
  flex: 1 1 50%;
  margin-top: 5px;
  border-radius: 20px;
}

.collection--multiimg-right-top {
  flex: 1 1 50%;
  margin-bottom: 5px;
  border-radius: 20px;
}

.collection--multiimg-box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.four-column-list .collection--multiimg-box {
  width: calc(25% - 30px);
}

@media (max-width: 1279px) {

  .wishlistPage-Outer .wishlistPage-inner .collection--multiimg-box,
  .four-column-list .collection--multiimg-box {
    width: calc(33.33% - 30px);
  }
}

@media (max-width: 991px) {

  .four-column-list .collection--multiimg-box,
  .wishlistPage-Outer .wishlistPage-inner .collection--multiimg-box {
    width: calc(50% - 30px);
  }
}

@media (max-width: 767px) {

  .four-column-list .collection--multiimg-box,
  .wishlistPage-Outer .wishlistPage-inner .collection--multiimg-box {
    width: calc(100% - 30px);
  }

  .topBar p {
    font-size: 13px;
  }
}

/*pichastock section*/

.pichastock--main {
  padding-bottom: 70px;
}

.pichastock--main-imgs {
  margin-top: 70px;
  column-count: 4;
  gap: 0;
  margin-left: -30px;
}

.pichastock--main-imgs img {
  width: 100%;
  object-fit: cover;
}

.pichastock--imgs-content {
  position: relative;
  margin-bottom: 30px;
  margin-left: 30px;
}

.pichastock-user {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 27px;
  color: var(--white-color);
  font-weight: 500;
}

.pichastock-user img {
  height: 50px;
  width: 50px !important;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
  border: 3px solid var(--white-color);
  z-index: 9;
}

.pichastock-user p {
  color: #000 !important;
  z-index: 99;
  font-size: 18px;
}

.pichastock-btn-outer {
  margin-top: 68px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.pichastock-user:after {
  content: "";
  background: var(--white-color);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0.7;
  padding: 0 9px 0px;
  border-radius: 69px 32px 32px 62px;
}

.outerShape-btn:before,
.outerShape-btn:after {
  content: url("./assets/btnsIcon.png");
  position: absolute;
  height: 30px;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.outerShape-btn:before {
  left: -80px;
}

.outerShape-btn:after {
  right: -80px;
}

/* contributor section */

.contributor {
  padding-top: 65px;
  padding-bottom: 65px;
  /* background-image: url("https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/contributor-banner.634f0d1358a905d30692.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 5px solid var(--pc-dark-background-clr);
  border-bottom: 5px solid var(--pc-dark-background-clr);
  background-position: bottom center;
}

.contributor img {
  width: 100%;
}

.contributor--inside {
  color: #fff;
  text-align: center;
  max-width: 1500px;
  margin: auto;
}

.white-color {
  color: var(--white-color);
}

.contributor p {
  font-size: 20px;
  line-height: 27px;
  color: var(--white-color);
  font-weight: 500;
  margin: 25px 0 20px;
}

.contributor--inside span {
  color: #b8b8b8;
  font-size: var(--pc-primary-text);
  line-height: 24px;
}

.contributorBtn-outer {
  margin-top: 50px;
}

.contributorBtn-outer .btn {
  min-width: 210px;
}

.contributorCardWrap {
  position: relative;
  border-radius: 20px;
}

.contributorLogo {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.4s all;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
  width: 100%;
  padding: 0px 20px;
}

.contributorLogo-user {
  padding-top: 18px;
}

.contributorImgBlock {
  border-radius: 20px;
  /* overflow: hidden; */
  cursor: pointer;
  text-align: center;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
}

@media (min-width: 2000px) {
  .contributorImgBlock {
    max-width: 100%;
  }

  .contributorImgBlock img {
    width: 100%;
  }
}

.contributorCardWrap .contributorImgBlock:after {
  background: rgba(255, 255, 255, 0.3);
  /*  content: ''; */
  inset: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: absolute;
  transition: 0.4s all;
  opacity: 0;
}

.pdp-product-image-inner {
  position: relative;
  overflow: hidden;
  background: #F9F9F9;
  border-radius: 15px;
  /* box-shadow: 0 0 80.5px rgba(0, 0, 0, 0.3); */
}

.detailsImage {
  border-radius: 15px;
}

.contributorCardWrap .contributorImgBlock:before {
  /* background: url(https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/pichaWatermark.png); */
  border-radius: 20px;
  content: "";
  height: 100%;
  inset: 0;
  position: absolute;
  transition: all 0.4s;
  width: 100%;
  background-size: 48%;
  opacity: 0;
  z-index: 0;
  height: 0;
}

.contributorCardWrap:hover .contributorImgBlock:before {
  opacity: 1;
}

.contributorCardWrap .contributorImgBlock:hover:before,
.pdp-product-image-inner:after {
  opacity: 0.5;
}

.contributorCardWrap:hover .contributorLogo {
  bottom: 20px;
  opacity: 1;
}

.contributorLogo-user:hover .contributed-information {
  opacity: 1;
  visibility: visible;
  z-index: 999;
  bottom: 65px;
  box-shadow: 0 0 10px -2px rgb(0 0 0 / 30%);
}

.contributorCardWrap:hover .contributorImgBlock:after {
  opacity: 0.2;
}

/* .contributorLogo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid #fff;
} */

.contributorLogo img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid #fff;
  object-fit: cover;
}

.contributorLogo h1 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 1px 5px #000;
  padding-top: 18px;
}

/* membership section */

.member {
  position: relative;
}

.member:before {
  content: "";
  background-image: url("./assets/blockfooter.png");
  height: 50px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.member--main {
  padding: 0px 0 150px;
  text-align: center;
}

.member-center-content {
  max-width: 717px;
  font-size: 20px;
  line-height: 32px;
  color: #666666;
  font-weight: 500;
  margin: 30px auto 0;
}

/* footer section */

.footer {
  /* background: #f5f5f5; */

}

.footer--main {
  display: flex;
  padding: 60px 0;
  flex-wrap: wrap;
}

.footer--main-menu {
  width: 20%;
  padding-right: 15px;
}

.footer--main-menu:last-child {
  padding-right: 0;
}

.footer--main-menu h3 {
  font-size: 20px;
  line-height: 38px;
  color: var(--pc-heading-clr);
  font-weight: 500;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.footer--main-menu li {
  font-size: var(--pc-primary-text);
  line-height: 24px;
  color: #666666;
}

.footer--main-menu li+li {
  margin-top: 15px;
}

.footer--main-menu li .icon-right-angle {
  margin-right: 10px;
  font-size: 12px;
}

.footer--main-menu p {
  font-size: var(--pc-primary-text);
  color: #666666;
  margin-bottom: 25px;
}

.footer--main-menu input {
  width: 100%;
  background-color: #e8e8e8;
  border: 0;
  font-size: var(--pc-primary-text);
  line-height: 24px;
  padding: 16px;
  border-radius: var(--pc-btn-br);
  margin-bottom: 15px;
  color: var(--pc-heading-clr);
}

.footer--main-menu-btn {
  width: 100%;
  font-weight: 500;
  margin: 0;
}

/* .footer--main-menu-btn:hover {
   background-color: #f5f5f5;
   color: var(--pc-btn-primary-bg);
 } */

.footer--copyright {
  border-top: 1px solid #cdcdcd;
  padding: 11px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer--copyright p {
  font-size: var(--pc-primary-text);
  line-height: 38px;
  color: #666666;
  font-weight: 400;
}

.footer--copyright-icons .social-icon {
  color: #666666;
  font-size: 22px;
  line-height: normal;
  transition: var(--pc-default-transition);
}

.footer--copyright-icons ul li a path {
  transition: 0.3s all;
}

.footer--copyright-icons ul li a:hover path {
  color: #ff5be5;
  transform: scale(1.1);
  fill: #ff5be5;
}

.footer--copyright-icons ul li {
  display: inline-block;
}

.footer--copyright-icons ul li+li {
  margin-left: 25px;
}

.footer--main-menu li a {
  transition: var(--pc-default-transition);
}

.footer--main-menu li a:hover {
  color: var(--pc-btn-primary-bg);
}

/* DARK MODE CSS START HERE */

.darkMode--on {
  background: #0d0d0d;
}

/* .darkMode--on .navbar .dark-mode--btn-black {
   display: block;
 }
 
 .darkMode--on .dark-mode--btn-white {
   display: none;
 } */

.darkMode--on .collection--multiimg-view a,
/* .darkMode--on .member--main p, */
.darkMode--on .footer--main-menu li,
.darkMode--on .footer--main-menu p,
.darkMode--on .footer--main-menu input::placeholder,
.darkMode--on .footer--copyright p,
.darkMode--on .footer--copyright-icons i,
.darkMode--on .footer--main-menu input,
.darkMode--on .banner .search_box .search_field .input::placeholder,
.darkMode--on .banner .dropbtn,
.darkMode--on .collection--main p,
.darkMode--on .footer--copyright-icons ul li a:hover i {
  color: var(--pc-dark-text-clr);
}

.darkMode--on .section-heading,
.darkMode--on .contributor--inside h2,
.darkMode--on .member--main h2,
.darkMode--on .footer--main-menu h3,
.darkMode--on .collection--main h2,
.darkMode--on p,
.darkMode--on .contactForm--main-left p,
.darkMode--on .contactForm--main-left span,
.darkMode--on .content-page-wrap * {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .section-heading svg * {
  fill: var(--pc-dark-heading-clr);
}

.darkMode--on .collection--multiimg-view a:hover {
  color: var(--pc-btn-primary-bg);
}

.darkMode--on .banner .search_box,
.darkMode--on .footer {
  background-color: #0e0e0e;
  border-radius: 10px;
}

.darkMode--on .banner .dropdown,
.darkMode--on .banner .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root,
.darkMode--on .banner .search_box input[type="search"],
.darkMode--on .banner .banner-search-btn {
  background: #0e0e0e;
}

.darkMode--on .banner .MuiAutocomplete-root .MuiAutocomplete-tag {
  background: #222;
  color: #fff;
}

.darkMode--on .banner .MuiAutocomplete-root .MuiChip-deleteIcon {
  fill: #fff;
}

.darkMode--on .banner .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.darkMode--on .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.darkMode--on .banner .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.darkMode--on .banner .search_box .search_field .input {
  background: #0e0e0e;
  color: var(--pc-dark-text-clr);
}

.darkMode--on .banner .dropbtn:before {
  border-top: 2px solid var(--pc-dark-text-clr);
  border-right: 2px solid var(--pc-dark-text-clr);
}

.darkMode--on .collection--main-btns .collection-multi-btn {
  background: #222222;
  color: var(--pc-dark-text-clr);
}

.darkMode--on .collection--main-btns .collection-multi-btn:hover {
  /* background-color: var(--pc-btn-primary-bg); */
  color: var(--white-color);
}

.darkMode--on .collection--multiimg-box,
.darkMode--on .footer--main-menu input {
  background: #222222;
}

.darkMode--on .footer--copyright {
  border-top: 0;
  background-color: #1a1a1a;
}

.darkMode--on .banner .dropdown-content {
  background-color: #222222;
  color: #888888;
}

/* .darkMode--on .collection--btn-browse:hover,
 .darkMode--on .pichastock--main .pichastock--btn:hover,
 .darkMode--on .member--main-btn:hover,
 .darkMode--on .footer--main-menu-btn:hover {
   background-color: var(--white-color);;
   color: var(--pc-heading-clr);;
   border: 1px solid var(--white-color);;
 } */

/* .darkMode--on .footer--main-menu-btn:hover {
   color: var(--pc-dark-text-clr);
   background: #333;
   border-color: transparent;
 } */

/* Forms Common CSS */

.common--form_box {
  padding: 70px;
  filter: drop-shadow(0 0 80.5px rgba(0, 0, 0, 0.06));
  background-color: var(--white-color);
  text-align: center;
  margin: 70px auto;
}

/*--------------- About Us Page ----------------------- */
/* banner section */

.aboutUs-banner {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 85px;
  padding-bottom: 80px;
}

.aboutUs-banner .banner--main-inside h1 {
  font-size: var(--pc-heading);
  line-height: var(--pc-heading-lh);
  font-weight: var(--pc-heading-fw);
  text-transform: initial;
  margin-bottom: 25px;
}

.aboutUs-banner .banner--main-inside p {
  margin-bottom: 0;
}

.aboutUs-banner .banner--main-inside i {
  font-size: 16px;
  margin: 0 7px;
}

/* welcome section */

.welcome {
  padding: 68px 0;
}

.welcome--inside {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.welcome--inside-images {
  flex: 0 0 50%;
  max-width: 50%;
  border-top: 3px solid #c3e711;
  border-right: 3px solid #c3e711;
  border-left: 3px solid #fe5bea;
  border-bottom: 3px solid #fe5bea;
  padding: 15px;
  border-radius: 20px;
}

.welcome--inside-content {
  width: 100%;
  padding-left: 67px;
  margin: 30px 0 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.welcome .collection--multiimg-box-img {
  padding-top: 74%;
}

.welcome .collection--multiimg-box-img img {
  padding: 0px;
  border-radius: 0;
}

.welcome .collection--multiimg-left {
  width: calc(60% - 30px);
  /* border-radius: 20px; */
}

.welcome .collection--multiimg-right {
  width: calc(100% - 60%);
}

.welcome .collection--multiimg-right-top {
  flex: 1 1 68%;
  margin-bottom: 15px;
  /* border-top-right-radius: 0px; */
}

.welcome .collection--multiimg-right-bottom {
  flex: 1 1 32%;
  margin-top: 15px;
}

.welcome--inside-content h2 {
  margin-bottom: 35px;
  justify-content: flex-start;
}

.welcome--inside-content p {
  color: #666666;
  font-size: var(--pc-primary-text);
  font-weight: 400;
  line-height: 24px;
}

.darkMode--on .welcome--inside-content p {
  color: #888888;
}

.welcome--inside-content p+p {
  margin-top: 30px;
}

.welcome--inside-btn {
  margin-top: 50px;
}

.darkMode--on .welcome--inside-btn {
  background-color: var(--pc-btn-primary-bg);
  border: 1px solid var(--pc-btn-primary-bg);
}

.darkMode--on .welcome--inside-btn:hover {
  background: #c33dad;
  color: var(--white-color);
  border-color: transparent;
}

.welcome--inside-btn:hover {
  background: #000000;
}

.welcome--inside.welcome--inside-full {
  max-width: 900px;
  margin: 0 auto;
}

.welcome--inside-full .welcome--inside-content {
  padding-left: 0;
  margin: 30px 0 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.welcome--inside-full .welcome--inside-content img {
  width: 100%;
  margin-bottom: 20px;
}

.welcome--inside-full .welcome--inside-content p {
  margin: 20px 0;
}

/* partner section */

.partner {
  background: #f4f4f4;
  padding: 70px 70px;
}

.partner--logos {
  display: flex;
  margin-top: 60px;
}

.partner--logos-img {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner--logos-img img {
  width: 100%;
  object-fit: cover;
  max-width: 55%;
}

/*founder section*/

.about-founder .welcome--inside-content {
  padding-right: 67px;
  padding-left: 0;
}

.about-founder .founder--socials {
  margin-top: 30px;
}

.about-founder .founder--socials li {
  display: inline-block;
  color: var(--pc-heading-clr);
  font-size: var(--pc-primary-text);
  color: #666666;
  margin-right: 30px;
}

.about-founder .founder--socials li:last-child {
  margin-right: 0;
}

.about-founder .founder--socials li+li {
  padding-left: 30px;
  border-left: 1px solid #666666;
}

.about-founder .founder--socials li i {
  margin-right: 10px;
}

.founder--socials a {
  align-items: center;
  display: flex;
}

.founder--socials svg {
  margin-right: 12px;
  width: 18px;
}

/*collection section*/

.aboutUs-collection {
  margin-bottom: 40px;
}

.aboutUs-collection .collection--multiimg {
  margin-top: 0;
}

/* AboutUs Page End */
/*--------------------- Contact Us -------------------------------*/
.contactUs-banner {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 85px;
  padding-bottom: 80px;
}

.contactUs-banner .banner--main-inside h1 {
  font-size: var(--pc-heading);
  line-height: var(--pc-heading-lh);
  font-weight: var(--pc-heading-fw);
}

.contactUs-banner .banner--main-inside p {
  margin-bottom: 0;
}

.contactUs-banner .banner--main-inside i {
  font-size: 16px;
  margin: 0 7px;
}

/* contactForm section */

.contactForm {
  padding: 70px 0;
}

.contactForm--container {
  max-width: 1490px;
  margin: 0 auto;
  padding: 0 20px;
}

.contactForm--main {
  display: flex;
  flex-wrap: wrap;
}

.contactForm--main-left,
.contactForm--main-right {
  flex: 0 0 50%;
  max-width: 50%;
}

.contactForm--main-left {
  padding-right: 70px;
}

.contactForm--main-left img {
  max-width: 100%;
}

.contactForm--main-right .contactForm--main-full {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
  margin-top: 50px;
}

.contactForm--main h2 {
  margin-bottom: 50px;
  margin-bottom: 50px;
  /* text-transform: none; */
  justify-content: flex-start;
}

.contactForm--main-left p {
  font-size: 20px;
  line-height: 38px;
  color: var(--pc-heading-clr);
  font-weight: 700;
}

.contactForm--main-left span {
  font-size: var(--pc-primary-text);
  line-height: 24px;
  color: #666666;
  margin: 7px 0;
  display: block;
}

.contactForm--content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.conatactForm-btn {
  min-width: 142px;
  font-size: var(--pc-form-input-text-fs);
  line-height: var(--pc-btn-text-lh);
  /* border-radius: var(--pc-form-input-br); */
  border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: var(--pc-form-input-btn-bg);
  color: var(--white-color);
  font-weight: 500;
  margin-bottom: 0;
  cursor: pointer;
  transition: var(--pc-default-transition);
  border: 1px solid var(--pc-form-input-btn-bg);
  padding: 8px;
}

.contactForm--left-mail {
  display: flex;
  flex-wrap: wrap;
}

.contactForm--left-mail p {
  margin-right: 20px;
  font-weight: 500;
}

.darkMode--on .conatactForm-btn {
  background-color: var(--pc-btn-primary-bg);
  color: var(--white-color);
}

.darkMode--on .contactForm--left-mail a {
  color: var(--white-color);
}

.darkMode--on .contactForm--left-mail a:hover {
  color: rgba(var(--primary-color), 1);
}

.contactForm--content .conatactForm-btn:hover {
  background: #000;
}

.darkMode--on .contactForm--content .conatactForm-btn:hover {
  background: #c33dad;
}

.right-text {
  text-align: right;
}

/*------------ Contact Us page End ---------------------*/

/* ----------Search Result Page Starts ---------------*/
.faishon .collection--main-inside {
  overflow: hidden;
}

.faishon .collection--main-btns {
  flex-wrap: nowrap;
  justify-content: inherit;
  margin: 30px 60px 50px;
  max-width: 100%;
  overflow: hidden;
}

.faishon .collection--main-btns .collection-multi-btn {
  min-width: fit-content;
  margin-bottom: 0;
}

.faishon .collection--main-btns .collection-multi-btn.arrows {
  position: relative;
  padding: 0;
  min-width: 46px;
}

.faishon .collection--main-btns .collection-multi-btn.arrows:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border-top: 2px solid #666666;
  border-right: 2px solid #666666;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  left: 15px;
  transition: var(--pc-default-transition);
}

.faishon .collection--main-btns .collection-multi-btn.arrows:hover:before {
  border-top: 2px solid var(--white-color);
  border-right: 2px solid var(--white-color);
}

.faishonPhotos-main h2 {
  margin-bottom: 30px;
}

.faishonPhotos-btns-filter {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
}

.faishonPhotos-btns-filter.faishonPhotos-btns-filter-pdp {
  justify-content: center;
}

/* .faishonPhotos-filter .filter-btn {
  min-width: 120px;
  border-radius: 23px;
  background-color: var(--pc-heading-clr);
  font-size: 15px;
  line-height: 28px;
  color: #e8e8e8;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  border: 1px solid var(--pc-heading-clr);
  transition: var(--pc-default-transition);
  margin-bottom: 0px;
} */

/* .faishonPhotos-filter .filter-btn:hover {
  background: #000000;

} */

/* .faishonPhotos-filter .filter-btn svg path {
  stroke: #e8e8e8;
  transition: var(--pc-default-transition);
} */

/* .faishonPhotos-filter .filter-btn:hover svg path {
  stroke: #e8e8e8;
} */

.faishonPhotos-filter .filter-btn svg {
  margin-right: 10px;
  height: 21px;
  width: 21px;
}

.faishonPhotos-buttons {
  margin-top: 15px;
}

.faishonPhotos-btns-filter .tab-links {
  font-size: 20px;
  line-height: 27px;
  color: #999999;
  font-weight: 500;
  padding: 20px 20px;
  transition: var(--pc-default-transition);
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 0px;
}

.faishonPhotos-btns-filter .tab-links.active,
.faishonPhotos-btns-filter .tab-links:hover {
  border-bottom-color: #fe5bea;
  color: var(--pc-heading-clr);
  /* background: #f1f1f1; */
}

.darkMode--on .faishonPhotos-btns-filter .tab-links.active,
.darkMode--on .faishonPhotos-btns-filter .tab-links:hover {
  /* border: 2px solid #fe5bea; */
  color: var(--pc-dark-heading-clr);
  /* background: #222; */
}

.faishonPhotos-btns-filter .tab-links+.tab-links {
  margin-left: 45px;
}

.faishonPhotos-btns-filter .tab-links .icon {
  margin-right: 15px;
}

.faishonPhotos-main .pichastock--main .pichastock--btn:before,
.faishonPhotos-main .pichastock--main .pichastock--btn:after {
  content: none;
}

.faishonPhotos-main .tab-content {
  display: none;
}

.faishonPhotos-main .show {
  display: block !important;
}

/* Search Page End */

/*Responsive CSS start here*/

@media (min-width: 1800px) {
  .navbar--fixed .navbar--searchbar input {
    min-width: 780px;
  }
}

/* .darkMode--on *:not(:hover){
   color:var(--white-color) !important;
 } */

.darkMode--on input {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .partner {
  background: #2e2e2e;
}

.founder--socials svg {
  margin-right: 7px;
  max-width: 16px;
  height: auto;
  vertical-align: middle;
}

.darkMode--on .founder--socials svg path {
  fill: #888888 !important;
}

.darkMode--on .navbar:not(.home-header) .sitelogo img {
  filter: brightness(1);
}

.darkMode--on .navbar:not(.home-header) .navbar--menus-dropdown:before {
  border-color: var(--white-color);
}

.darkMode--on .common--form_box h2,
.darkMode--on .common--form_box label,
.darkMode--on .common--form_box label a,
.darkMode--on .login-forgot-pswrd,
.darkMode--on .loginuser-join *,
.darkMode--on .registerForm-join * {
  /* color: var(--pc-heading-clr) !important; */
  /* color: #888888 !important */
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .loginForm--box .loginForm-btn {
  background: #000000 !important;
}

/* .darkMode--on input, .forgotpswrdForm--reg-login a{
   color:var(--pc-heading-clr) !important
 } */

.darkMode--on .banner .dropdown a:hover {
  background-color: #3e3d3d;
}

/* login */

/* loginForm section */

.auth-container {
  max-width: 500px;
  /* margin: 70px auto;
   padding: 70px; */
  width: 90%;
  margin: 0 auto;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.auth-page-outer .section-heading {
  text-transform: capitalize;
}

.inputField,
.selectField,
.rti--container .rti--input {
  width: 100%;
  background-color: #f4f4f4;
  line-height: 38px;
  border: none;
  padding: 9px 20px;
  font-size: 16px;
  border-radius: 3px;
  color: #666666;
}

.selectField {
  padding: 17.5px 20px;
}

.field-outer+.field-outer {
  margin-top: 40px;
}

.field-outer {
  position: relative;
}

.checkedbox-outer {
  position: relative;
}

.checkedbox-outer label {
  font-size: 16px;
  color: #666666;
  position: relative;
  padding: 4px 0 0 40px;
  display: block;
}

.darkMode--on .checkedbox-outer label {
  color: var(--pc-dark-heading-clr);
}

.checkedbox-outer input[type="checkbox"] {
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  opacity: 0;

  z-index: 12;
  cursor: pointer;
}

.checkedbox-outer label:after,
.checkedbox-outer label:before {
  content: "";
  position: absolute;
}

.checkedbox-outer label:after {
  width: 22px;
  border-radius: 3px;
  height: 22px;
  left: 0;
  top: 0px;
  border: 1px solid var(--pc-heading-clr);
}

.pswd-show {
  position: absolute;
  right: 20px;
  top: 17px;
  cursor: pointer;
}

.pswd-show path {
  fill: #333333;
}

.darkMode--on .pswd-show path {
  fill: #d7d7d7;
}

.darkMode--on .checkedbox-outer label:after {
  border: 1px solid var(--pc-btn-primary-bg);
}

.register-checkbox.checkedbox-outer label:after {
  top: 8px;
}

.checkedbox-outer label:before {
  width: 12px;
  height: 5px;
  border-left: 3px solid var(--white-color);
  border-bottom: 3px solid var(--white-color);
  left: 5px;
  top: 6px;
  transform: rotate(-45deg);
  opacity: 0;
  z-index: 2;
}

.checkedbox-outer input[type="checkbox"]:checked+label:before {
  opacity: 1;
}

.checkedbox-outer input[type="checkbox"]:checked+label:after {
  background: var(--pc-heading-clr);
}

.checkedbox-outer.register-checkbox label:before {
  top: 14px;
}

.auth-btn {
  width: 100%;
  background: #222222;
  border: 2px solid #222222;
  font-size: 16px;
  transition: var(--defaultTransition);
  cursor: pointer;
}

.auth-btn:hover {
  border-color: transparent;
  background: #000000;
}

/* .forgotpassword {
   text-align: center;
   margin-top: 50px;
 } */

.forgotpassword a {
  font-size: 16px;
  color: #666666;
  text-decoration: underline;
}

.forgotpassword a:hover {
  color: #222222;
}

.darkMode--on .forgotpassword a:hover,
.darkMode--on .loginuser-join-pichastock:hover {
  color: var(--pc-btn-primary-bg);
}

.signuplink {
  font-size: 16px;
  color: #666666;
  margin-top: 20px;
  text-align: center;
}

.signuplink a,
.register-checkbox a {
  color: var(--pc-heading-clr);
  text-decoration: underline;
  font-weight: 500;
}

.register-checkbox a {
  color: var(--pc-heading-clr);
  text-decoration: underline;
  font-weight: 400;
  font-style: italic;
}

.signuplink a:hover,
.forgotpassword a:hover,
.forgotpswrdForm-reg-login li a:hover,
.forgotpswrdForm-pichastck:hover {
  color: #ff5be5;
}

.register-checkbox label {
  line-height: 24px;
}

.register-container {
  max-width: 900px;
  padding: 50px;
}

.register-container .auth-outer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.register-checkbox {
  position: relative;
  padding: 0 15px;
}

.contributor-register-paragraph {
  color: #838383;
  text-align: center;
  margin-bottom: 40px;
  line-height: 24px;
}

.m-0 {
  margin: 0 !important;
}

.full-width {
  max-width: 100%;
  width: 100%;
}

.half-width {
  max-width: 50%;
  width: 50%;
}

.register-btn {
  margin: 10px auto 0;
  max-width: 360px;
}

@media (min-width: 768px) {
  .m-half-width {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .full-width {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.full-width {
  flex: 0 0 100%;
  max-width: 100%;
}

.forgotpassword-outer .auth-container {
  max-width: 600px;
}

.forgotpswrdForm-reg-login {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 50px 0 0;
}

.forgotpswrdForm-subheading {
  font-size: 16px;
  color: #666666;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  margin-bottom: 50px;
}

.forgotpassword-outer .auth-btn {
  margin: 0;
}

.forgotpswrdForm-reg-login li {
  padding: 0 20px;
}

.forgotpswrdForm-reg-login li+li {
  border-left: 1px solid var(--pc-heading-clr);
}

.forgotpswrdForm-reg-login li a {
  display: inline-block;
  font-size: 16px;
  color: var(--pc-heading-clr);
  font-weight: 500;
  transition: var(--defaultTransition);
}

.darkMode--on .forgotpswrdForm-reg-login a,
.darkMode--on .forgotpswrdForm-pichastck {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .forgotpswrdForm-reg-login li+li {
  border-left: 1px solid var(--pc-dark-heading-clr);
}

.forgotpswrdForm-pichastck {
  font-size: 16px;
  color: var(--pc-heading-clr);
  font-weight: 500;
  margin-top: 20px;
  display: block;
  transition: var(--defaultTransition);
}

.footer--main .svg-inline--fa {
  vertical-align: 0 !important;
}

/* .auth-page-outer {
  padding: 100px 30px;
  margin: 0 auto;
  max-width: 1080px;
} */

.forminner-part {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.social-btn-fb.register-socialbtn button {
  margin-right: 0;
}

.login-page-form-outer {
  align-items: flex-start;
  background-color: #fff;
  background-color: var(--white-color);
  display: flex;
  -webkit-filter: drop-shadow(0 0 80.5px rgba(0, 0, 0, 0.06));
  filter: drop-shadow(0 0 80.5px rgba(0, 0, 0, 0.06));
  min-height: 80vh;
  align-items: stretch;
}

/* .auth-page-outer.login-page-form-outer.forgotpassword-outer, .auth-page-outer.login-page-form-outer {
  padding: 0;
  margin: 200px auto 100px;
  width: 95%;
} */

.darkMode--on .login-page-form-outer {
  background-color: #222222;
}

.login-page-form-outer .auth-container {
  margin: 0;
  width: 100%;
  padding: 0;
}

.login-image-wrap,
.login-page-form-outer .auth-container {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 0 0 40px;
}

.login-page-form-outer .auth-container {
  /* padding: 100px 0 50px 10px; */
  padding-top: 30px;
}

.login-image-wrap {
  padding-right: 0;
  text-align: right;
}

/* .auth-page-outer.login-page-form-outer {
   padding: 0 70px;
   display: flex;
   align-items: center;
 } */

.login-image-wrap img {
  max-width: 100%;
  height: 100%;
}

@media (min-width: 2000px) {
  .login-image-wrap img {
    object-fit: cover;
    width: 100%;
  }
}

.contributed-registeration .section-heading {
  font-size: 35px;
  margin-bottom: 20px;
}

/* dark mode*/

.darkMode--on .auth-container {
  background-color: #222222;
}

.darkMode--on .inputField,
.darkMode--on .selectField {
  background-color: #111111;
}

.darkMode--on .contactForm--content .inputField {
  background-color: #222222;
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .auth-btn {
  background: var(--pc-btn-primary-bg);
  border: 2px solid var(--pc-btn-primary-bg);
  color: var(--white-color);
}

.darkMode--on .auth-btn:hover {
  background: rgb(149, 10, 132);
  background: linear-gradient(90deg,
      rgba(226, 0, 198, 0.87718837535014) 0%,
      rgba(245, 50, 221, 1) 47%,
      rgba(246, 8, 217, 1) 100%);
  color: var(--white-color);
  border-color: rgba(149, 10, 132, 0);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.darkMode--on .signuplink a,
.darkMode--on .register-checkbox a {
  color: var(--pc-dark-heading-clr);
}

.validation-error {
  text-align: left;
  color: #e91f1f;
  font-size: 12px;
  position: absolute;
  left: 6px;
  bottom: -30px;
  padding: 2px 10px;
  border-radius: 3px;
}

.validation-error.pos-static {
  position: static;
}

@media (max-width: 991px) {
  .register-container .m-half-width {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .register-container .field-outer+.field-outer {
    margin-top: 20px !important;
  }

  .auth-page-outer.login-page-form-outer.register-container-wrap {
    align-items: flex-start;
  }

  .auth-page-outer.login-page-form-outer.register-container-wrap .login-image-wrap {
    margin-top: 94px;
  }

  /* .auth-page-outer.login-page-form-outer.forgotpassword-outer, .auth-page-outer.login-page-form-outer {
    margin: 140px auto 100px;
  } */
}

@media (max-width: 767px) {
  .validation-error {
    left: 0;
    padding: 2px 0px;
  }
}

/* faq page css start */

.faq-main {
  padding: 70px 0;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
}

.faq-column {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.faq-tab {
  box-shadow: 0 0 14px rgb(0 0 0 / 10%);
}

.faq-tab+.faq-tab {
  margin-top: 20px;
}

.faq-tab-click {
  font-size: 16px;
  color: var(--pc-heading-clr);
  font-weight: 500;
  padding: 18px 40px 18px 20px;
  border-radius: 2px 2px 0 0;
  position: relative;
}

.faq-image-content img {
  max-width: 100%;
}

.open-tab .faq-tab-click {
  background-color: #000000;
  color: var(--white-color);
}

.faq-tab-content {
  background-color: #f6f6f6;
  border-radius: 0 0 2px 2px;
  height: 0;
  overflow: hidden;
  transition: 0.3s all;
  max-height: 0;
}

.faq-tab-content p {
  font-size: 16px;
  color: #666666;
  line-height: 24px;
  padding: 20px;
}

.open-tab .faq-tab-content {
  max-height: 100vh;
  height: 100%;
}

.faq-tab-click span {
  position: absolute;
  width: 40px;
  height: 100%;
  right: 0;
  z-index: 2;
  top: 0;
  display: block;
  cursor: pointer;
}

.faq-tab-click span:after,
.faq-tab-click span:before {
  content: "";
  position: absolute;
  background-color: #000000;
}

.faq-tab-click span:after {
  width: 2px;
  height: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.faq-tab-click span:before {
  width: 16px;
  height: 2px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.open-tab .faq-tab-click span:after {
  display: none;
}

.open-tab .faq-tab-click span:after,
.open-tab .faq-tab-click span:before {
  background-color: var(--white-color);
}

.darkMode--on .faq-tab-click {
  color: var(--white-color);
  background: #222222;
}

.darkMode--on .faq-tab-click span:after,
.darkMode--on .faq-tab-click span:before {
  background-color: var(--white-color);
}

.darkMode--on .faq-tab-content {
  background-color: #222222;
}

.darkMode--on .faq-tab-content p {
  color: #d7d7d7;
}

.darkMode--on .explore-collection-content * {
  color: #d7d7d7 !important;
}

/*   media query  */

@media (min-width: 992px) {
  .humburger-icon {
    display: none;
  }
}

@media (max-width: 1800px) {
  div:not(".header-search-bar") .navbar--searchbar {
    display: none;
  }

  /* .auth-page-outer {
    padding: 50px 30px;
  } */
}

@media (max-width: 1399px) {
  .section-heading {
    font-size: 35px;
  }

  .login-page-form-outer .auth-container {
    padding: 40px 0 0px 40px;
  }
}

@media (max-width: 1359px) {

  .collection--main h2:before,
  .collection--main h2:after {
    background-size: contain;
    width: 50px;
    height: 50px;
  }

  .collection--main h2:before {
    left: -133px;
  }

  .collection--main h2:after {
    right: -133px;
  }

  .contactForm--main h2 {
    margin-bottom: 30px;
  }

  .aboutUs-banner .banner--main-inside h1 {
    font-size: 35px;
    margin-bottom: 15px;
  }

  .banner--main-inside h1 {
    font-size: 24px;
  }

  .banner--main-inside p {
    font-size: 16px;
    line-height: 24px;
    margin: 4px 0 30px;
  }

  .section-heading {
    font-size: 24px;
  }

  .collection--multiimg-view {
    padding: 13px 10px 0px;
  }

  .collection--multiimg-view p {
    font-size: 16px;
  }

  .collection--main p {
    font-size: 16px;
  }

  .collection--main-btns .collection-multi-btn {
    padding: 7px 22px;
    font-size: 14px;
  }

  .collection-tags {
    padding: 5px;
  }

  .collection--multiimg-view {
    padding: 7px 10px 0;
  }

  .collection--main .mt-40 {
    margin-top: 24px;
  }

  .collection--main {
    padding-top: 50px;
    padding-bottom: 55px;
  }

  .pichastock--main-imgs {
    margin-top: 35px;
    column-count: 3;
  }

  .pichastock--main {
    padding-bottom: 50px;
  }

  .pichastock-btn-outer {
    margin-top: 45px;
  }

  .contributor {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .member--main {
    padding: 60px 0 130px;
  }

  .member--main p {
    font-size: 16px;
    line-height: 28px;
    margin: 20px auto 0;
  }

  .contributor .mt-50 {
    margin-top: 30px;
  }

  .welcome--inside-content h2 {
    margin-bottom: 20px;
  }

  .faishonPhotos-main h2 {
    margin-bottom: 11px;
  }

  .faishonPhotos-btns-filter .tab-links {
    font-size: 16px;
    line-height: 30px;
    padding: 20px 0;
  }

  .faishonPhotos-filter a {
    min-width: 100px;
    font-size: 14px;
    padding: 4px 5px;
    margin-bottom: 22px;
  }

  .banner .search_box input[type="search"] {
    padding: 10px 90px 0px 10px !important;
  }

  .banner .dropbtn {
    padding: 18px 0;
  }

  .banner .dropbtn:after {
    height: calc(100px - 65px);
    top: 10px;
  }

  .banner .dropbtn:before {
    top: 22px;
  }

  .licence-header p {
    margin-top: 10px;
  }
}

@media (max-width: 1279px) {
  .collection--main p {
    max-width: 400px;
  }

  .banner--main-inside {
    padding: 0 30px;
  }

  .welcome--inside-content {
    width: 100%;
    padding-left: 0;
  }

  .aboutPage-bnOuter {
    margin: 30px 0 0;
  }

  .about-founder .welcome--inside {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .footer--main-menu {
    width: 33.33%;
    margin: 0 0 30px;
  }

  .footer--main-menu:last-child,
  .footer--main-menu:nth-child(4) {
    padding-right: 0;
    width: 50%;
    margin-top: 20px;
  }

  .footer--main {
    padding: 60px 0 30px;
  }

  .aboutUs-banner {
    padding-top: 35px;
    padding-bottom: 40px;
  }

  .welcome--inside-images,
  .welcome--inside-content {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }

  .about-founder .welcome--inside-content {
    padding-right: 0;
    padding-left: 0;
  }

  .collection--multiimg-view a {
    font-size: 14px;
    min-width: 60px;
    line-height: 28px;
  }

  .faq-column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer--main-menu h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .footer--main-menu li {
    font-size: 14px;
  }

  .footer--main-menu li+li {
    margin-top: 10px;
  }

  .footer--main-menu {
    padding-bottom: 0px;
    margin: 0;
  }

  .member:before {
    content: "";
    height: 27px;
    background-size: 50px;
  }

  .member--main {
    padding: 50px 0 90px;
  }

  .auth-page-outer .section-heading {
    margin-bottom: 20px;
  }

  .collection--multiimg-view {
    align-items: flex-start;
  }

  .collection--multiimg-view p {
    line-height: 28px;
  }

  .collection--multiimg-view.author-info-outer {
    align-items: center;
  }
}

@media (max-width: 991px) {
  .search-field-outer {
    margin-left: 32px;
  }

  .container {
    padding: 0 30px;
  }

  .collection--main h2:before,
  .collection--main h2:after {
    /* width: 69px;
     height: 70px;*/
    background-size: 100% 100%;
  }

  .navbar--menus .main-menu {
    display: none;
  }

  .collection--main h2:before {
    left: -113px;
  }

  .collection--main h2:after {
    right: -113px;
  }

  .footer--copyright {
    padding: 31px 20px 20px;
    flex-direction: column-reverse;
  }

  .footer--copyright-icons {
    margin: 0 0 15px;
  }

  .member--main {
    padding-top: 50px;
  }

  .footer--main-menu {
    width: 50%;
    padding-right: 15px;
    padding-bottom: 30px;
  }

  .footer--main-menu:nth-child(4) {
    margin-top: 0;
  }

  .footer--main {
    padding: 60px 0 30px;
  }

  .navbar {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .aboutUs-banner .banner--main-inside h1 {
    margin-bottom: 15px;
    font-size: 35px;
  }

  .welcome--inside-content h2 {
    margin-bottom: 18px;
  }

  .partner--logos {
    margin-top: 30px;
  }

  .partner {
    padding: 50px 20px;
  }

  .contactForm--main-left,
  .contactForm--main-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contactForm--main-left {
    padding-right: 0;
    margin-bottom: 30px;
    display: none;
  }

  .contactForm--main h2 {
    margin-bottom: 10px;
    text-align: center;
  }

  .inputField,
  .selectField {
    padding: 5px 20px;
    font-size: 14px;
  }

  textarea.inputField {
    line-height: 24px;
  }

  .auth-btn {
    padding: 4px 0;
  }

  .auth-container {
    padding: 40px;
  }

  /* .auth-container.register-container,
   .forgotpassword-outer .auth-container {
     padding: 0;
   } */

  .darkMode--on .auth-container.register-container,
  .darkMode--on .forgotpassword-outer .auth-container {
    padding: 40px;
  }

  .checkedbox-outer label {
    font-size: 14px;
  }

  .register-btn {
    margin: 30px auto 0;
  }

  .signuplink {
    line-height: 28px;
    text-align: center;
  }

  .signuplink a,
  .register-checkbox a {
    white-space: pre;
  }

  .navbar--menus {
    display: none;
  }

  .navbar--menus {
    display: block;
    position: absolute;
  }

  .humburger-icon {
    width: 40px;
    background: none;
    border: none;
    position: relative;
    margin-right: 46px;
    padding: 0 4px;
    /* z-index: 9999999; */
    transition: 0.3s all;
    right: 0;
  }

  .open-menu #humburger {
    right: 300px;
  }

  .humburger-icon span {
    width: 100%;
    height: 3px;
    background: #222222;
    display: block;
    margin: 7px 0;
    transform-origin: left;
    transition: 0.5s all;
    position: relative;
  }

  .darkMode--on .humburger-icon span {
    background: var(--pc-dark-heading-clr);
  }

  .home-header .humburger-icon span {
    background: var(--white-color);
  }

  .home-header.sticky-header .humburger-icon span {
    background: #222222;
  }

  .open-menu .humburger-icon span:first-child {
    transform: rotate(45deg);
  }

  .open-menu .humburger-icon span:nth-child(2) {
    opacity: 0;
    margin-right: 10px;
  }

  .open-menu .humburger-icon span:last-child {
    transform: rotate(-45deg);
    top: 3px;
  }

  .navbar--menus .main-menu {
    display: block;
  }

  .navbar--menus {
    display: block;
    position: absolute;
    width: 100%;
  }

  .navbar--menus {
    display: block;
    position: fixed;
    width: 350px;
    right: 0;
    height: 100vh;
    z-index: 999999;
    background: #000000;
    top: 0;
  }

  .navbar--menus ul {
    margin-right: 0;
  }

  .navbar--menus ul li {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 0 !important;
  }

  .navbar--menus ul li a {
    font-size: var(--pc-primary-text);
    line-height: 24px;
    color: var(--white-color);
    font-weight: 400;
    position: relative;
    width: 100%;
    display: block;
    padding: 9px 28px;
  }

  .navbar:not(.home-header) .navbar--menus-dropdown:before {
    border-color: var(--white-color);
  }

  .navbar--menus-dropdown:before {
    top: 14px;
    right: 29px;
    transition: 0.3s all;
  }

  .navbar--menus {
    /* display: none; */
    position: fixed;
    width: 350px;
    right: -430px;
    height: 100vh;
    z-index: 999999;
    background: #000000;
    top: 0;
    padding: 60px 0 0;
    transition: 0.3s all;
  }

  .navbar--submenu-mega {
    padding: 0 20px 0;
  }

  .navbar--submenu {
    position: static;
    background: none;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    transform: translate(0%, 0px);
  }

  .openDropdown .navbar--menus-dropdown:before {
    transform: rotate(-45deg);
  }

  .navbar--submenu-content:before {
    display: none;
  }

  .pichastock--main-imgs {
    margin-top: 45px;
  }

  body #root {
    position: relative;
    transition: 0.3s all;
    left: 0;
  }

  body.open-menu #root {
    left: -350px !important;
  }

  .open-menu .navbar--menus {
    right: -69px;
  }

  .collection--multiimg {
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .collection--multiimg-box {
    width: calc(50% - 30px);
  }

  .pichastock--imgs-content {
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .contributor {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .welcome--inside-content p {
    font-size: 14px;
  }

  .pichastock--main-imgs {
    column-count: 2;
  }

  #cart .navbar--submenu {
    display: none;
  }

  .contactForm--main-full {
    margin-top: 30px;
  }

  .login-image-wrap {
    display: none;
  }

  .forminner-part {
    margin-right: auto;
  }

  #socialBtn {
    overflow: hidden;
  }

  .login-page-form-outer .auth-container {
    padding: 40px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .collection--multiimg-box {
    width: 100%;
    margin-left: 0;
  }

  form.contactForm--content label {
    padding-left: 20px;
    width: 100%;
  }

  .register-container .field-outer,
  .contactForm--content .field-outer {
    width: 100%;
  }

  .register-container .field-outer.m-0,
  .contactForm--content .field-outer.m-0 {
    margin-top: 20px !important;
  }

  .contactForm--content {
    margin-left: 0;
    margin-right: 0;
  }

  .contactForm {
    padding: 50px 0;
  }

  .collection--main p {
    margin-top: 0;
  }

  .collection-tags {
    padding: 6px;
  }

  .member--main p {
    font-size: 16px;
    margin: 20px auto 0;
    line-height: 28px;
  }

  .member--main .btn-outer {
    margin-top: 40px;
  }

  .footer--main-menu {
    width: 100%;
    margin: 0;
  }

  .footer--main-menu:last-child {
    width: 100%;
    margin-top: 0px;
  }

  .member:before {
    height: 23px;
    background-size: 45px;
  }

  .member--main {
    padding: 50px 0 80px;
  }

  .collection--main h2:before {
    left: -93px;
  }

  .collection--main h2:after {
    right: -93px;
  }

  .footer--copyright p {
    line-height: 28px;
    text-align: center;
    font-size: 14px;
  }

  .collection--main h2:before,
  .collection--main h2:after,
  .outerShape-btn:before,
  .outerShape-btn:after {
    display: none;
  }

  .pichastock--main-imgs {
    margin-top: 30px;
  }

  .banner--main-inside h1 {
    font-size: 28px;
    line-height: 34px;
  }

  .banner .dropbtn {
    font-size: 14px;
  }

  .banner-search-btn {
    font-size: 18px;
    line-height: 28px;
    width: 50px;
  }

  .banner .search_box .search_field .input {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 52px;
  }

  .banner--trending ul li+li {
    margin-left: 10px;
  }

  .banner {
    padding-top: 153px;
    padding-bottom: 65px;
  }

  .btn {
    padding: 12px 30px;
  }

  .collection--multiimg-view {
    padding: 10px 10px 0px;
  }

  .collection--main h2 {
    margin: 0 auto 10px;
  }

  .partner--logos {
    flex-wrap: wrap;
  }

  .partner {
    padding: 35px 20px 50px;
  }

  .partner--logos-img {
    width: 50%;
  }

  .login-page-form-outer .auth-container {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 40px;
  }

  .pichastock-user p {
    font-size: 14px;
  }

  .pichastock-user img {
    height: 40px;
    width: 40px !important;
  }

  .navbar {
    padding: 10px 0;
  }

  .conatactForm-btn {
    min-width: 100%;
    width: 100%;
  }

  .banner .dropdown {
    max-width: 160px;
    flex: 0 0 160px;
  }

  .banner .css-1jruifh-MuiStack-root {
    width: calc(100% - 210px);
  }

  .banner .search_box input[type="search"] {
    padding-right: 0 !important;
  }
}

@media (max-width: 574px) {
  .section-heading {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 32px;
  }

  .pichastock--main-imgs {
    column-count: 1;
  }

  .collection--main .mt-40 {
    margin-top: 10px;
  }

  .collection--main {
    padding-bottom: 40px;
    padding-top: 30px;
  }

  .pichastock-btn-outer {
    margin-top: 20px;
  }

  .contributor {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .member--main {
    padding: 40px 0 70px;
  }

  .footer--main {
    padding: 40px 0 0px;
  }

  .member--main p {
    font-size: 14px;
  }

  .footer--main-menu h3 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .navbar--menus {
    width: 250px;
  }

  body.open-menu #root {
    left: -250px !important;
  }

  .open-menu #humburger {
    right: 160px;
    z-index: 9999999;
  }

  .about-founder .founder--socials li+li {
    padding-left: 0;
    border-left: none;
    margin-top: 10px;
    display: block;
  }

  .welcome {
    padding: 50px 0;
  }

  .welcome--inside-content p+p {
    margin-top: 20px;
  }

  .welcome--inside-btn {
    margin-top: 30px;
  }

  .banner .dropdown {
    max-width: 130px;
    flex: 0 0 130px;
  }

  .banner .css-1jruifh-MuiStack-root {
    width: calc(100% - 180px);
  }

  .field-outer.forgotpassword-outer {
    margin-top: 0;
  }

  .forgotpassword,
  .checkedbox-outer {
    margin-top: 15px;
  }
}

/* Fallback */
.fallback {
  filter: brightness(0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}

.darkMode--on .fallback {
  filter: brightness(1);
}

/*  humburger */

/* faq page css start */

.faq-main {
  padding: 70px 0;
}

.faq-column {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.faq-tab {
  box-shadow: 0 0 14px rgb(0 0 0 / 10%);
}

.faq-tab+.faq-tab {
  margin-top: 20px;
}

.open-tab .faq-tab-click {
  background-color: #000000;
  color: var(--white-color);
}

.faq-tab-content h6 {
  font-size: 16px;
  color: #666666;
  line-height: 24px;
  padding: 20px;
}

.open-tab .faq-tab-content {
  max-height: 100vh;
  height: auto;
}

.faq-tab-click span {
  position: absolute;
  width: 40px;
  height: 100%;
  right: 0;
  z-index: 2;
  top: 0;
  display: block;
  cursor: pointer;
}

.faq-tab-click span:after,
.faq-tab-click span:before {
  content: "";
  position: absolute;
  background-color: #000000;
}

.faq-tab-click span:after {
  width: 2px;
  height: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.faq-tab-click span:before {
  width: 16px;
  height: 2px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.open-tab .faq-tab-click span:after {
  display: none;
}

.open-tab .faq-tab-click span:after,
.open-tab .faq-tab-click span:before {
  background-color: var(--white-color);
}

#socialBtn {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin: 20px 0 0;
}

/* search error message */

.search_field .validation-error {
  font-size: 20px;
  left: 6px;
  right: 0px;
  left: auto;
  color: var(--white-color);
  font-weight: 500;
  background: #e53247;
  height: auto;
  bottom: -43px;
  font-size: 12px;
  padding: 8px 16px;
}

.search_field .validation-error:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  top: -7px;
  border-bottom: 8px solid #e53247;
  right: 9px;
}

.faishonPhotos-content .section-heading {
  padding: 60px 0;
}

/*  metirial ui */

.search_box .search_field>[class*="css-1"] {
  width: 100%;
  height: 100%;
  border: none;
}

.search_box .search_field>[class*="css-1"] .MuiOutlinedInput-root {
  padding: 0;
  height: 100%;
}

.search_bo .MuiAutocomplete-root {
  height: 100%;
}

.search_box .MuiFormControl-root {
  height: 100%;
  width: 100%;
}

.search_box input[type="search"] {
  border: none;
  padding: 0 90px 0 40px !important;
  height: 100%;
}

.search_box .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: 50%;
  transform: translate(0%, -50%);
  left: 28px;
}

.search_field>[class*="css-1"] .MuiAutocomplete-root {
  height: 100%;
}

.banner-search-btn {
  z-index: 9;
}

.search_box .search_field * {
  border: none !important;
}

.static-page-wrapper {
  padding-top: 0;
  padding-bottom: 80px;
  margin-top: 50px;
}

.static-contributor {
  text-align: center;
}

.static-page-wrapper .content-img img {
  width: 100%;
}

.static-page-wrapper .content-img {
  background-color: black;
}

/* .static-page-wrapper .content-img .image {
   width: auto;
 } */

.signup-btn {
  color: #fff;
  border-color: #f90;
  background-color: #f90;
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
}

.static-page-wrapper .bottom-hr {
  background-color: #767676;
  border: none;
  box-sizing: content-box;
  height: 2px;
  max-width: 160px;
  margin: 70px auto;
}

.static-page-wrapper .content-para {
  margin: 45px 0px;
  padding: 20px 3px !important;
}

.bg-Chamoisee {
  background-color: #9f7b5e;
}

.bg-Marigold {
  background-color: #f4a521;
}

.bg-HookerGreen {
  background-color: #45715f;
}

.bg-TuftsBlue {
  background-color: #4089d2;
}

.bg-Desire {
  background-color: #e83759;
}

.bg-Eucalyptus {
  background-color: #49cfae;
}

.static-page-wrapper .content-btn {
  margin: 25px 0px;
}

.static-page-wrapper .join-btns {
  border-radius: 3px;
  margin-left: 10px !important;
  padding: 9px 15px !important;
  font-size: 16px;
  color: #ffff;
  background-color: #ab3431 !important;
  border: none;
  line-height: 15px !important;
  font-weight: 700;
}

/* .affiliate p {
   color: #7a7a7a;
 } */

.static-page-wrapper p {
  line-height: 30px;
}

.static-page-wrapper p~p {
  margin-top: 30px;
}

.wp-block-image {
  margin: 30px 0 0;
}

.static-page-wrapper h2 {
  margin-bottom: 25px;
  font-weight: 500;
  min-height: 57px;
  overflow: hidden;
  font-size: 22px;
}

.static-page-wrapper h4 {
  font-size: 18px;
  margin: 30px 0 20px;
}

.static-page-wrapper h3 {
  margin: 0 0 20px;
}

.static-page-wrapper ol ol,
.static-page-wrapper ul {
  margin: 20px 0;
}

.static-page-wrapper ol ol li,
.static-page-wrapper ul li {
  line-height: 30px;
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 20px;
}

.static-page-wrapper ol ol li:after,
.static-page-wrapper ul li:after {
  content: "";
  width: 6px;
  height: 6px;
  border-top: 1px solid var(--pc-heading-clr);
  border-right: 1px solid var(--pc-heading-clr);
  position: absolute;
  left: 0;
  top: 12px;
  transform: rotate(45deg);
}

.awards-step .static-page-wrapper ul li:after {
  border-color: var(--white-color);
}

.flex-column {
  flex: 0 0 50%;
}

.flex-column-10 {
  flex: 0 0 10%;
}

.flex-column-20 {
  flex: 0 0 15%;
}

.flex-column-30 {
  flex: 0 0 30%;
}

.flex-column-60 {
  flex: 0 0 60%;
  padding: 0 25px;
}

.picha-grants-wrap .flex-column-60 {
  flex: 0 0 70%;
  padding: 0 25px;
}

.flex-column-80 {
  flex: 0 0 80%;
  padding: 0 25px;
}

.flex-column-90 {
  flex: 0 0 90%;
}

.banner-text {
  padding: 35px 0px;
}

.banner-text button {
  margin: 15px 0px;
  margin-left: 100px;
}

.static-page-wrapper h1 {
  color: #28324a;
  font-size: 48px;
  font-weight: 700;
}

.picha-awards {
  background-image: url("https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/1680690355_award-hero-bg_.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.picha-grants {
  /* background-image: url("https://pichastockv2-prod-resize-media.s3.amazonaws.com/assets/1680690400_grants-hero-bg.jpg"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.picha-grants-wrap .banner-text {
  max-width: 550px;
  margin: 0 auto;
}

.picha-grants-wrap .container {
  max-width: 1170px;
  margin: 0 auto;
  padding-bottom: 0;
}

.picha-grants-wrap .flex-column-20 img {
  width: 100%;
  max-width: 250px;
}

.picha-grants-wrap .flex-column-30 img {
  margin-bottom: 0;
  max-width: 100%;
  height: auto;
}

.awards-register {
  padding: 15px 0px;
}

.awards-register .content-inside {
  width: 100%;
}

.awards-register .content-inside img {
  width: 100%;
}

.picha-grants-wrap .award-categories {
  padding: 50px 0 80px;
}

.awards-register {
  padding: 30px 0px 10px;
}

.awards-register .para-inside {
  padding-top: 0px;
  margin: auto;
  width: 70%;
  margin-right: 0px;
}

.para-inside button {
  margin-top: 150px;
}

.award-categories .flex-row {
  justify-content: center;
}

.divider {
  border-top: 1px solid #000;
  margin: 35px;
}

.flex-column-30 img {
  margin: 50px 0;
}

.awards-step {
  color: #ffff;
  padding: 0px 0px 75px;
}

.picha-grants-wrap .static-page-wrapper.awards-step h2 {
  min-height: initial;
  font-weight: 600;
}

.bg-Eucalyptus .para-inside button {
  margin-top: 30px;
  /* max-width: 150px;
  padding: 5px 0; */
}

.static-page-wrapper.awards-step p~p {
  margin-top: 11px;
}

.static-page-wrapper.awards-step p {
  font-weight: 600;
}

#still-life {
  margin-bottom: 50px;
}

.bg-HookerGreen {
  padding: 55px 0;
}

.bg-HookerGreen .signup-btn {
  padding: 0;
  margin: 0;
  height: 64px;
  width: 100%;
  max-width: 472px;
  display: inline-block;
  font-size: 24px;
  overflow: #fff;
  font-weight: 700;
}

.flex-column-60 .category-para {
  margin-top: 55px;
  /* max-width: 10%; */
  /* padding-left: 55px; */
}

.awards-step .flex-column {
  max-width: 45%;
  padding-right: 35px;
}

.check-icon svg path {
  fill: #ffff !important;
}

.copyRight {
  padding-top: 50px;
  padding-bottom: 20px;
}

.grants-step .flex-column-20 {
  margin-top: 50px !important;
  margin-bottom: 25px !important;
  margin: auto;
}

.resources-static .flex-row {
  justify-content: center;
}

.flex-column-20 .inner-column {
  margin-top: 58px;
  margin-left: 20px;
}

.inner-column img {
  margin-bottom: 20px;
  width: 350px;
  height: 200px;
}

.copyRight p {
  margin: 0 0 20px;
}

.darkMode--on .static-page-wrapper p,
.darkMode--on .static-page-wrapper h2,
.darkMode--on .static-page-wrapper h3,
.darkMode--on .static-page-wrapper li,
.darkMode--on .static-page-wrapper ol ol li {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .bg-HookerGreen .static-page-wrapper h2,
.darkMode--on .bg-HookerGreen .static-page-wrapper p,
.darkMode--on .bg-HookerGreen .static-page-wrapper ul li,
.darkMode--on .awards-register p,
.darkMode--on .static-page-wrapper h1 {
  color: #fff;
}

.darkMode--on .copyRight path {
  fill: #fff;
}

.darkMode--on .awards-register .para-inside {
  padding-top: 20px;
}

.darkMode--on .picha-grants-wrap .banner-text p,
.darkMode--on .picha-grants-wrap .banner-text h1 {
  color: var(--pc-heading-clr);
}

.darkMode--on .bg-HookerGreen .static-page-wrapper ol ol li:after,
.darkMode--on .bg-HookerGreen .static-page-wrapper ul li:after {
  border-color: var(--white-color);
}

.darkMode--on .static-page-wrapper ol ol li:after,
.darkMode--on .static-page-wrapper ul li:after {
  border-color: var(--pc-dark-text-clr);
}

.darkMode--on .navbar--searchbar input {
  /* background-color: #222222; */
  background-color: #010101;
}

.darkMode--on .navbar--searchbar svg path {
  fill: #c3e711;
}

.darkMode--on .search-action:hover {
  background: #242424;
}

/* content page */

.content-page-wrap {
  padding: 70px 0;
}

.content-page-wrap * {
  color: var(--pc-heading-clr);
}

.content-page-wrap h3 {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.content-page-wrap h4 {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 15px;
  line-height: 30px;
}

.content-page-wrap h6 {
  font-size: 18px;
  margin-top: 20px;
  line-height: 28px;
}

.content-page-wrap ul li,
.content-page-wrap p {
  font-size: 16px;
  line-height: 28px;
}

.content-page-wrap p b {
  font-size: 20px;
}

.content-page-wrap address {
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
}

.content-page-wrap p~p {
  margin-top: 10px;
}

.content-page-wrap ul li {
  line-height: 30px;
  margin: 12px 0;
  padding: 0 0 0 20px;
  position: relative;
}

.content-page-wrap ul li:after {
  border-right: 1px solid #333;
  border-top: 1px solid #333;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  top: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 6px;
}

.darkMode--on .content-page-wrap ul li:after {
  border-right: 1px solid var(--white-color);
  border-top: 1px solid var(--white-color);
}

@media (max-width: 991px) {
  .navbar--menus ul li p {
    margin-left: 28px;
  }

  .navbar--menus .navbar--submenu {
    overflow: hidden;
    max-height: 0;
    height: 100%;
    display: block;
  }

  .navbar--menus .openDropdown .navbar--submenu {
    display: block;
    max-height: 100vh;
    height: 100%;
  }

  .main-menu svg path,
  .search-menu path {
    fill: #fff;
  }

  .faq-column {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }

  .full-width.faq-tab-content-inner {
    padding: 0;
    margin: 20px 0 0;
  }

  .faq-main .flex-row {
    margin: 0;
  }

  .footer--main-menu:last-child {
    margin-top: 0;
  }

  .static-contributedForm+.auth-page-outer .auth-container.register-container {
    padding: 40px;
  }

  .static-page-wrapper h1 {
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  .static-page-wrapper h1 {
    font-size: 24px;
  }
}

.darkMode--on .forgotpswrdForm-reg-login li a:hover,
.darkMode--on .forgotpswrdForm-pichastck:hover,
.register-checkbox a:hover {
  color: #ff5be5;
}

.register-success {
  text-align: center;
  line-height: 28px;
  font-size: 24px;
  color: var(--pc-heading-clr);
}

/* filter css  */

.mt-20 {
  margin-top: 20px !important;
}

.filter-form-inner-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.filter-form-inner-wrap .field-outer+.field-outer {
  margin-top: 0;
}

@media (min-width: 768px) {
  .m-3-full-width {
    max-width: 33.33%;
    flex: 0 0 33.33%;
    padding: 0 15px;
  }
}

.input-label {
  font-size: 16px;
  text-transform: capitalize;
  margin: 0 0 9px;
  display: block;
  color: var(--pc-heading-clr);
}

.icon-btn {
  background: var(--pc-heading-clr);
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.icon-btn:hover {
  background: #000000;
}

.icon-btn svg {
  height: 20px;
}

.icon-btn svg path {
  fill: var(--white-color);
}

.icon-btn+.icon-btn {
  margin-left: 10px;
}

.filter-form-outer {
  padding: 0px;
  display: none;
  flex: 0 0 100%;
  transition: 0.3s all;
  /* overflow: hidden; */
  /* max-height: 0;; */
  padding-right: 140px;
  position: relative;
}

.filter-actionOuter {
  position: absolute;
  right: 20px;
  bottom: 28px;
}

.filter-form-outer.open-filter {
  /* max-height:100vh; */
  display: block;
}

.filter-form-inner-wrap {
  padding: 20px;
}

.darkMode--on .filter-form-outer {
  background: #222222;
}

.darkMode--on .input-label {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on select {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .icon-btn {
  background-color: var(--pc-btn-primary-bg);
}

.darkMode--on .icon-btn:hover {
  background: #c33dad;
}

.search-variation {
  position: relative;
}

.search-variation-submenu {
  background: #f5f5f5;
  position: absolute;
  top: 57px;
  width: 100%;
  z-index: 999;
  left: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s all;
}

.search-variation-list {
  padding: 10px 0;
}

.search-variation-submenu li {
  padding: 12px 23px;
  cursor: pointer;
  font-size: 16px;
  color: var(--pc-heading-clr);
  /*text-transform: capitalize;*/
}

.search-variation-submenu li:hover {
  background: #f1f1f1;
}

.search-variation.open-filter-variation .search-variation-submenu {
  max-height: 100vh;
}

.search-variation .inputField {
  position: relative;
  padding-right: 60px;
  cursor: pointer;
  text-transform: capitalize;
}

.search-variation svg {
  height: 20px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.search-variation.open-filter-variation svg {
  transform: translate(0, -50%) rotate(-180deg);
}

.darkMode--on .search-variation .inputField,
.darkMode--on .search-variation-submenu li {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .search-variation-submenu {
  background: #111111;
}

.darkMode--on .search-variation-submenu li:hover {
  background: var(--pc-heading-clr);
}

.darkMode--on .search-variation path {
  fill: var(--pc-dark-heading-clr);
}

.filter-form-outer.open-filter {
  display: block;
}

/* pdp */

.pdp-main {
  padding: 70px 0;
}

.feature-gallery {
  padding-bottom: 100px;
}

.feature-gallery .section-heading {
  margin: 0 0 70px;
  font-size: 35px;
}

.feature-gallery img {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 20px;
  object-fit: cover;
}

/* .feature-gallery-inner {
  padding: 0 15px;
} */

.collection-carousel .react-multiple-carousel__arrow--left {
  left: 30px;
}

.collection-carousel .react-multiple-carousel__arrow--right {
  right: 30px;
}

/* .react-multi-carousel-list .react-multiple-carousel__arrow {
  background: rgba(254, 91, 234, 0.8);
} */

.react-multi-carousel-list .react-multiple-carousel__arrow {
  background: no-repeat;
  border: 1px solid rgba(var(--secondary-color), 1);
}

.react-multi-carousel-list .react-multiple-carousel__arrow::before {
  color: rgba(var(--secondary-color), 1);
  transition: 0.3s all;
}

.react-multi-carousel-list .react-multiple-carousel__arrow:hover {
  background: rgba(var(--secondary-color), 1);
}

.react-multi-carousel-list .react-multiple-carousel__arrow:hover::before {
  color: #ffffff;
}

.feature-gallery .react-multiple-carousel__arrow--left {
  left: 20px;
}

.feature-gallery .react-multiple-carousel__arrow--right {
  right: 20px;
}

/**/
.collection--main .react-multi-carousel-list .react-multiple-carousel__arrow {
  background: no-repeat;
  border: 1px solid rgba(var(--primary-color), 1);
}

.collection--main .react-multi-carousel-list .react-multiple-carousel__arrow::before {
  color: rgba(var(--primary-color), 1);
  transition: 0.3s all;
}

.collection--main .react-multi-carousel-list .react-multiple-carousel__arrow:hover {
  background: rgba(var(--primary-color), 1);
}

.collection--main .react-multi-carousel-list .react-multiple-carousel__arrow:hover::before {
  color: #ffffff;
}

/* .react-multi-carousel-list .react-multiple-carousel__arrow:hover {
  background: rgba(254, 91, 234, 1);
} */

@media (min-width: 992px) {
  .column-two-half {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.section-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.pdp-product-image img {
  display: block;
  max-width: 100%;
  width: 100%;
}

.pdp-product-image {
  padding-right: 50px;
  position: relative;
}

.pdp-product-content {
  padding-left: 50px;

}

.pdp-product-info {
  background: #F9F9F9;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 0 50.5px rgba(0, 0, 0, 0.13);

}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.ms-20 {
  margin-left: 20px;
}

.ps-0 {
  padding-left: 0 !important;
}

.align-items-center {
  align-items: center;
}

.pdp-info-header img {
  width: 67px;
  height: 67px;
  border-radius: 50%;
}

.pdp-info-header-inner {
  padding-left: 15px;
}

.author-name {
  font-size: 18px;
  color: var(--pc-heading-clr);
  font-weight: 700;
  cursor: pointer;
}

.follow-link {
  font-size: 13px;
  /* display: block; */
  background: #ff5be5;
  color: #ffffff;
  padding: 5px 7px;
  border-radius: 20px;
  text-align: center;
  text-decoration: none;
  margin: 5px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .darkMode--on .follow-link{
  background: #0d0d0d;
} */

.follow-link svg {
  margin-right: 7px;
}

.pdp-download-detail h5 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--pc-heading-clr);
}

.pdp-download-detail {
  padding-top: 30px;
}

.pdp-download-detail-list,
.pdp-download-detail-list li,
.pdp-detal-content {
  display: flex;
  margin: 26px 0 0 0;
  flex-wrap: wrap;
}

.pdp-download-detail-list {
  margin: 24px -8px 0;
}

.pdp-download-detail-list li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-inline: 8px;
}

.pdp-detal-content,
.pdp-download-detail-list li {
  margin: 0;
}

/* .pdp-radio-content {
  padding-left: 20px;
} */

.pdp-download-detail-list p {
  font-size: 16px;
  color: var(--pc-heading-clr);
  line-height: 24px;
}

.pdp-detal-content-wrap {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  padding: 10px 20px;
  flex-wrap: wrap;
  background: #eee;
  margin: 10px 0;
  border-radius: 40px;
  justify-content: space-between;
  border: 2px solid #ddd;
  cursor: pointer;
  position: relative;
}

.darkMode--on .pdp-detal-content-wrap {
  background: #0d0d0d;
  border: 2px solid #0d0d0d;
}

.pdp-detal-content-wrap.active,
.darkMode--on .pdp-detal-content-wrap.active {
  border-color: #fe5bea;
  background: #dddddd;
}

.darkMode--on .pdp-detal-content-wrap.active {
  border-color: #fe5bea;
  background: #060606;
}

.pdp-actionbtn-outer button {
  width: calc(50% - 8px);
  margin: 0 !important;
}

.pdp-detal-content+.pdp-detal-content {
  padding-left: 20px;
}

.pdp-actionbtn-outer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.pdp-btn {
  border: 1px solid var(--pc-btn-primary-bg);
  color: var(--white-color);
  background-color: var(--pc-btn-primary-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdp-btn svg {
  width: 20px;
  margin-right: 10px;
}

.pdp-btn:hover {
  background-color: var(--pc-btn-primary-bg);
  color: var(--white-color);
}

.pdp-btn:hover path {
  fill: var(--white-color);
}

.pdp-btn:hover path {
  fill: var(--white-color);
}

.bg-none {
  background-color: transparent;
  color: var(--pc-heading-clr);
}

.buy-btn {
  margin-left: 15px;
}

.buy-btn path {
  fill: var(--white-color);
}

.pdp-product-description {
  margin: 30px 0 0;
  background: #F9F9F9;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 0 50.5px rgba(0, 0, 0, 0.13);

}

.des-title-main {
  font-weight: 600;
  font-size: 30px;
  color: var(--pc-heading-clr);
}

.des-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--pc-heading-clr);
  margin: 20px 0 10px;
  text-transform: capitalize;
}

.pdp-product-description p,
.pdp-image-info li {
  font-size: 16px;
  line-height: 26px;
  color: var(--pc-heading-clr);
}

.pdp-image-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.pdp-image-info li {
  padding: 0 40px 0 0;
  line-height: 18px;
}

.pdp-image-info li+li {
  padding-left: 40px;
  border-left: 1px solid var(--pc-heading-clr);
}

.pdp-product-description .collection--main-btns {
  justify-content: flex-start;
  margin: 0 -7px;
}

.pdp-product-description .collection-tags {
  padding: 7px;
}

.pdp-product-description .collection--main-btns .collection-multi-btn {
  padding: 8px 20px;
}

.pdp-detal-content input:not([type="text"]) {
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
}

.wishlist-outer .pdp-detal-content input:not([type="text"]) {
  height: auto;
}

.wishlist-outer .pdp-radio-content::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
  border: 2px solid var(--white-color);
}

.wishlist-outer input:checked+.pdp-radio-content::after {
  background-color: #000;
}

.pdp-radio-content {
  position: relative;
  padding-left: 0px;
}

/* .pdp-radio-content,
.pdp-detal-content {
  position: relative;
} */

/* .pdp-radio-content:after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 5px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
  border: 2px solid var(--white-color);
} */

/* .pdp-detal-content input:checked+.pdp-radio-content:after {
  background: #000000;
} */

/* 
.secondary-btn:hover {
  background: #111111;
} */

/* dark mode */

.darkMode--on .pdp-product-info {
  background: #222222;
}

.darkMode--on .des-title-main,
.darkMode--on .des-title {
  color: var(--pc-dark-heading-clr);
}

/* .darkMode--on .follow-link, */
.darkMode--on .pdp-download-detail h5,
.darkMode--on .pdp-download-detail-list p,
.darkMode--on .pdp-product-description p,
.darkMode--on .pdp-image-info li {
  color: #888888;
}

.darkMode--on .pdp-radio-content:after {
  border: 2px solid #222222;
  box-shadow: 0 0 0 2px var(--pc-dark-heading-clr);
}

.darkMode--on .pdp-detal-content input:checked+.pdp-radio-content:after {
  background: var(--pc-dark-heading-clr);
}

.darkMode--on .pdp-btn {
  border: 1px solid var(--pc-btn-primary-bg);
  color: var(--white-color);
  background-color: var(--pc-btn-primary-bg);
  display: flex;
  align-items: center;
}

.whishListForm .pdp-btn {
  padding: 0;
  border: 0;
}

.whishListForm .pdp-btn:hover,
.darkMode--on .bg-none:hover {
  background: none !important;
}

.darkMode--on .bg-none {
  background-color: transparent;
  color: var(--pc-btn-primary-bg);
}

.darkMode--on .pdp-btn:hover {
  background: #c33dad;
  color: var(--white-color);
  border-color: #c33dad;
}

.darkMode--on .pdp-btn.bg-none svg {
  fill: var(--pc-btn-primary-bg);
}

.darkMode--on .bg-none:hover {
  background: #c33dad;
  color: var(--white-color);
  border-color: #c33dad;
}

.darkMode--on .secondary-btn {
  background-color: var(--pc-btn-primary-bg);
  border: 1px solid var(--pc-btn-primary-bg);
  color: #ffffff;
}

.darkMode--on .secondary-btn:hover {
  background: #c33dad;
  color: var(--white-color);
  border-color: transparent;
}

/* TESTING MASNORY..................................................... */

/* * {
   box-sizing: border-box;
 } */

@media (max-width: 1399px) {
  .pdp-btn {
    padding: 8px 37px;
  }

  .pdp-product-content {
    padding-left: 0;
  }
}

@media (max-width: 1359px) {
  .pdp-actionbtn-outer {
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .pdp-actionbtn-outer {
    flex-direction: row;
  }
}

/* ---- grid ---- */

.grid {
  max-width: 1200px;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  width: auto;
  flex-wrap: wrap;
}

.wishlist-detail-content .my-masonry-grid {
  width: 100%;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

@media (max-width: 1500px) {
  /* .my-masonry-grid_column {
    flex: 0 0 25%;
  } */
}

/* Style your items */
.my-masonry-grid_column .contributorCardWrap {
  /* overflow: hidden; */
  /* background: grey; */
  margin-bottom: 30px;
}

.imgWidth,
.contributorImgBlock img {
  max-width: 100%;
  /* max-height: 400px;*/
  /* object-fit: fill;  */
  border-radius: 20px;
  width: 100%;
}

.contributorCardWrap:hover .contributorImgBlock img {
  opacity: 0.6;
}

/* \package */
.package-header {
  padding: 67px 0 63px;
}

.package-header p {
  font-size: 20px;
  color: #666666;
  line-height: 30px;
}

.package-list-col-inner .section-heading {
  background: #eaeaea;
  padding: 30px 0;
  font-size: 30px;
}

.package-list-content {
  padding: 20px 48px 48px;
}

.package-charges {
  margin: 0 0 20px;
  font-size: 20px;
  font-weight: 500;
  color: #666666;
  height: 61px;
}

.package-list-col:last-child .package-charges {
  display: flex;
  align-items: center;
}

.package-charges b {
  color: var(--pc-heading-clr);
  margin: 0;
  display: block;
}

.package-list-col-inner ul li {
  font-size: 16px;
  color: var(--pc-heading-clr);
  padding: 0px 0 0px 15px;
  position: relative;
  margin: 7px 0;
  line-height: 26px;
}

.package-list-col-inner ul li:after {
  content: "";
  width: 6px;
  height: 6px;
  background: var(--pc-heading-clr);
  left: 0;
  position: absolute;
  border-radius: 50%;
  top: 10px;
}

.plan-heightlighted-price {
  font-size: 50px;
  font-weight: 700;
  color: var(--pc-heading-clr);
}

.plan-heightlighted-price span {
  font-size: 30px;
}

.package-main {
  padding-bottom: 100px;
}

.package-list-outer {
  display: flex;
  margin: 0 -30px;
  flex-wrap: wrap;
  justify-content: center;
}

/* .faishonPhotos-main  .container {
   padding: 0;
 } */

.package-list-col {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0 30px;
}

.package-list-col-inner {
  box-shadow: 0 0 80.5px rgba(0, 0, 0, 0.06);
  height: 100%;
}

.package-list-content ul {
  min-height: 152px;
}

/* dashboard profile */

@media (min-width: 768px) {
  .m-three-column {
    padding-left: 15px;
    padding-right: 15px;
  }

  .field-outer.m-three-column {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}

.dashboard-row {
  display: flex;
}

.dashboard-sidebar {
  flex: 0 0 360px;
  max-width: 360px;
  background-color: #f5f5f5;
  /* height:100%; */
  transition: 0.3s all;
  /* margin-top: 40px;Adjusted to be just below the banner */
}

.dashboard-sidebar li a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 19px 0 19px 20px;
  border-left: 5px solid transparent;
  transition: 0.3s all;
}

.dashboard-sidebar li svg {
  height: 18px;
  margin-right: 11px;
  width: 18px;
  transition: 0.3s all;
  fill: #666;
}

.dashboard-sidebar li.active a,
.dashboard-sidebar li a.active,
.dashboard-sidebar li a:hover {
  border-color: #991212;
  background: var(--white-color);
}

.dashboard-sidebar li.active a svg,
.dashboard-sidebar li a.active svg,
.dashboard-sidebar li a:hover svg {
  fill: #991212;
}

.dashboard-content {
  padding-left: 65px;
  padding-top: 50px;
  max-width: calc(100% - 360px);
  flex: 0 0 calc(100% - 360px);
}

.dashboard-title {
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0 20px;
  color: var(--pc-heading-clr);
  text-transform: capitalize;
}

.darkMode--on .dashboard-sidebar {
  background-color: #222222;
}

.darkMode--on .dashboard-sidebar li a.active,
.darkMode--on .dashboard-sidebar li a:hover {
  color: var(--pc-dark-heading-clr);
  background: #0d0d0d;
}

.darkMode--on .dashboard-sidebar li a,
.darkMode--on .dashboard-title {
  color: var(--pc-dark-heading-clr);
}

.dashboard-content-inner .conatactForm-btn {
  text-transform: capitalize;
  max-width: 204px;
  width: 100%;
}

.dashboard-content-inner .no-bg {
  background: none;
  color: var(--pc-heading-clr);
}

.darkMode--on .dashboard-content-inner .conatactForm-btn.no-bg {
  border-color: var(--pc-btn-primary-bg);
  color: var(--pc-btn-primary-bg);
}

.darkMode--on .dashboard-content-inner .conatactForm-btn.no-bg:hover {
  color: var(--white-color);
  border-color: transparent;
}

.dashboard-content-inner .conatactForm-btn+.conatactForm-btn {
  margin-left: 10px;
}

.dashboard-content-inner .conatactForm-btn:hover {
  color: var(--white-color);
}

.topbtn {
  width: 40px;
  height: 40px;
  background: var(--pc-btn-primary-bg);
  border: navajowhite;
  border-radius: 3px;
  cursor: pointer;
  position: fixed;
  right: 13px;
  bottom: 30px;
  z-index: 9999;
}

.topbtn svg {
  fill: var(--white-color);
  height: 20px;
  box-shadow: 0 0 80.5px rgba(0, 0, 0, 0.06);
}

.darkMode--on .topbtn {
  background: #ebebeb;
}

.darkMode--on .topbtn svg {
  fill: #333333;
}

.main-page-outer {
  padding: 70px 0;
}

.section-subheading {
  font-size: 30px;
  line-height: var(--pc-heading-lh);
  color: var(--pc-heading-clr);
  font-weight: var(--pc-heading-fw);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.darkMode--on .section-subheading {
  color: #888888;
}

.preview-image {
  width: auto;
  height: 120px;
  max-width: 100%;
  border-radius: 20px;
  margin-bottom: 0;
  border: 3px solid #c3e711;
  border-left: 3px solid #fe5bea;
  border-bottom: 3px solid #fe5bea;
  margin: 0;
}

.badge-image {
  width: auto;
  height: 120px;
  max-width: 100%;
  border-radius: 50%;
  margin-bottom: 0;
  border: 3px solid #c3e711;
  border-left: 3px solid #fe5bea;
  border-bottom: 3px solid #fe5bea;
  margin: 0;
  margin-left: 10px;
  opacity: 0;
  /* Initially hide the image */
  transition: opacity 0.3s ease;
  /* display: block; */
}

.modal-badge-image {
  width: auto;
  height: 120px;
  max-width: 100%;
  border-radius: 50%;
  margin-bottom: 0;
  border: 3px solid #c3e711;
  border-left: 3px solid #fe5bea;
  border-bottom: 3px solid #fe5bea;
  margin: 0;
  margin-left: 10px;
  /* opacity: 0;  */
  transition: opacity 0.3s ease;
  /* display: block; */
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.badge-card {
  flex-basis: 100%;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.badge-container {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.badge-title {
  font-size: 16px;
  color: #333;
}

/* Responsive Layout */
@media (min-width: 768px) {

  /* 2 per row on medium screens */
  .badge-card {
    flex-basis: calc(50% - 20px);
  }
}

@media (min-width: 992px) {

  /* 3 per row on large screens */
  .badge-card {
    flex-basis: calc(33.333% - 20px);
  }
}

.badge-image.loaded {
  opacity: 1;
  /* Show the image when it's loaded */
}

.shimmer-effect {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #f0f0f0 20%, #f9f9f9 50%, #f0f0f0 80%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  z-index: 1;
  border-radius: 8px;
  display: block;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.badge-image.loaded+.shimmer-effect {
  display: none;
}

.browse-images-wrap {
  margin: 20px 0;
}

.browse-images-wrap h3 {
  font-size: 16px;
  margin-top: 13px;
}

.darkMode--on .browse-images-wrap h3 {
  color: var(--white-color);
}

.avtarImage-column {
  /* flex: 0 0 25%;
  max-width: 25%; */
  padding: 0 15px;
}

.avtarImage-column h4 {
  margin-top: 10px;
  max-width: 560px;
  line-height: 1.5;
}

.avtarImage-column .preview-image {
  margin-top: 16px;
}

.avtarImage {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 24px;
}

.avtarImage .preview-image {
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
}

.dashboard-content-inner .contactForm--submit-btn {
  margin-top: 47px;
}

.darkMode--on .avtarImage-column h4,
.darkMode--on .avtarImage-column h2 {
  color: #fff;
}

/* package page media query */

.darkMode--on .package-charges,
.darkMode--on .package-header p,
.darkMode--on .package-list-col-inner ul li {
  color: #888888;
}

.darkMode--on .package-list-col-inner ul li:after {
  background: #888888;
}

.darkMode--on .package-list-col-inner .section-heading,
.darkMode--on .plan-heightlighted-price {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .package-list-col-inner .section-heading {
  background: #222222;
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .package-list-col-inner {
  box-shadow: 0 0 80.5px rgb(255 255 255 / 6%);
}

/* image update on dashboard*/

.dashboard-imageupdate {
  background: #F9F9F9;
  padding: 40px;
  text-align: center;
  border-radius: 20px;
}

.imageupdate-intro {
  font-size: 20px;
  color: var(--pc-heading-clr);
  line-height: 28px;
  font-weight: 500;
}

.imageupdate-uploading-area svg {
  width: 70px;
}

.imageupdate-uploading-area {
  margin: 50px auto 40px;
  max-width: 800px;
  padding: 40px;
  background: var(--white-color);
  border: 1px dashed #e3e3e3;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: 0.3s all;
}

.imageupdate-uploading-area:hover {
  border: 1px dashed #3e3e3e;
}

.imageupdate-uploading-area input {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
}

/* .uploadimages-inforow {
  flex-wrap: wrap;
  max-width: 630px;
  margin: 0 auto;
  width: 90%;
} */

.uploadimages-inforow-outer {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
}

.uploadimages-infowrap {
  /* flex: 0 0 50%;
  max-width: 50%; */
  text-align: left;
  padding: 15px 15px 15px 22px;
  position: relative;
}

.uploadimages-infowrap svg {
  padding: 0;
  position: absolute;
  left: 0;
  top: 15px;
}

.imageupdate-uploading-area p {
  font-size: 16px;
  margin-top: 20px;
  color: #666666;
}

/* .dashboard-imageupdate .btn {
  padding: 0;
} */

.dashboard-imageupdate-footer {
  margin-top: 30px;
}

.dashboard-imageupdate-footer p {
  font-size: 16px;
  color: #666666;
  line-height: 28px;
}

.dashboard-imageupdate-footer p a {
  text-decoration: underline;
  color: #a11511;
}

.dashboard-imageupdate-column {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
}

.dashboard-imageupdate-inner {
  width: 100%;
  position: relative;
  /* max-height: 290px; */
  overflow: hidden;
  border-radius: 20px;
  margin: 10px 0;
}

.dashboard-imageupdate-row {
  display: flex;
  flex-wrap: wrap;
  margin: 15px -15px 40px;
  justify-content: center;
}

.dashboard-imageupdate-inner img {
  max-width: 100%;
  border-radius: 15px;
  max-height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}

.dashboard-imageupdate-inner.imageupload-progress:after {
  width: 100%;
  height: 100%;
  content: "";
  /* background: rgba(190,231,64, 0.5); */
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 15px;
}

.css-fl5ss-MuiLinearProgress-root {
  height: 20px !important;
}

/* .MuiBox-root {
  z-index: 11;
  display: none;
} */

.css-pazuet p {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 24px;
  text-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

.css-pazuet {
  bottom: 0;
  padding: 0 !important;
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-8ub8io-MuiLinearProgress-dashed {
  background-image: radial-gradient(rgb(161, 21, 17) 0%,
      rgb(161, 21, 17) 16%,
      transparent 42%) !important;
}

/* .MuiBox-root {
   width: 100%;
   padding: 0px !important;
   position: absolute;
   bottom: 0;
   transform: translate(0%, 0%);
 } */

.css-qhoknl-MuiLinearProgress-bar1 {
  background-color: rgb(161, 21, 17) !important;
}

.css-fl5ss-MuiLinearProgress-root {
  border-radius: 0 0 14px 14px;
}

.css-1qdnzt4-MuiLinearProgress-bar2 {
  background-color: rgba(161, 21, 17, 0.7) !important;
}

.Toastify {
  position: sticky;
  top: 11%;
  left: 50%;
  transform: translate(-10%, 0%);
  z-index: 999999;
}

/*dark mode*/

.darkMode--on .dashboard-imageupdate {
  background: #222222;
  color: var(--pc-dark-text-clr);
}

.darkMode--on .imageupdate-intro,
.darkMode--on .dashboard-imageupdate-footer p,
.darkMode--on .dashboard-imageupdate-footer p a {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .imageupdate-uploading-area {
  background: #0d0d0d;
  border: 1px dashed #555;
}

.darkMode--on .imageupdate-uploading-area svg {
  fill: var(--pc-dark-heading-clr);
}

/* checkout page */

.checkout-main .container {
  max-width: 1650px;
  margin: 0 auto;
}

.checkout-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 10px 0;
}

.checkout-header {
  background: #222222;
  padding: 18px 0;
}

.checkout-clm:first-child {
  flex: 0 0 calc(100% - 350px);
}

.checkout-clm:nth-child(2) {
  flex: 0 0 220px;
  max-width: 220px;
}

.checkout-clm {
  flex: 0 0 120px;
  max-width: 100%;
  padding: 0 10px;
}

.checkout-row.checkout-header p {
  color: var(--pc-dark-heading-clr);
  font-size: 16px;
}

.checkout-delete svg {
  width: 15px;
  cursor: pointer;
}

.checkout-delete svg path {
  fill: #a71116;
}

.checkout-delete {
  display: flex;
  align-items: center;
}

.checkout-delete span {
  margin-left: 10px;
}

/* .checkout-row.checkout-action .btn {
  padding: 0 38px;
} */

.checkout-cnt-image {
  display: flex;
  align-items: center;
}

.checkout-cnt-inner-image {
  flex: 0 0 70px;
  max-width: 70px;
  text-align: center;
}

.checkout-cnt-image img {
  max-width: 100%;
  border-radius: 5px;
  max-height: 50px;
}

.checkout-cnt-image .checkout-title {
  margin-left: 10px;
}

.checkout-content .checkout-row:nth-child(even) {
  background: #f7f7f7;
}

.checkout-row.checkout-action {
  margin-top: 30px;
  align-items: flex-end;
  border-top: 1px solid #d7d7d7;
  padding-top: 20px;
}

.darkMode--on .checkout-content .checkout-row:nth-child(even) {
  background: #222222;
}

.checkout-action {
  justify-content: space-between;
}

.checkout-action .checkout-clm {
  flex: 0 0 auto;
}

.checkout-total-amount {
  font-size: 20px;
  font-weight: 600;
}

.checkout-payment-row {
  display: flex;
  align-items: center;
}

.checkout-payment-row .field-outer+.field-outer {
  margin-top: 0px;
}

.checkout-payment-method {
  background: #f7f7f7;
  padding: 30px 40px;
}

.darkMode--on .checkout-payment-method {
  background: #222222;
}

.darkMode--on .checkout-payment-title,
.darkMode--on .payment-card label {
  color: #d7d7d7;
}

.checkout-payment-row {
  margin-bottom: 20px;
}

.checkout-payment-title {
  font-size: 28px;
  font-weight: 400;
  color: var(--pc-heading-clr);
  margin: 0 0 24px;
}

.payment-radiobtn {
  position: relative;
  padding-left: 25px;
  color: var(--pc-heading-clr);
}

.darkMode--on .payment-radiobtn,
.darkMode--on .checkout-payment-info .field-outer p {
  color: #d7d7d7;
}

.payment-radiobtn:after {
  width: 10px;
  height: 10px;
  background: #f7f7f7;
  content: "";
  position: absolute;
  left: 0;
  border-radius: 20px;
  border: 2px solid #f7f7f7;
  box-shadow: 0 0 0px 2px #888888;
  top: 1px;
}

.darkMode--on .payment-radiobtn:after {
  background: #22222200;
  border: 2px solid #222222;
  box-shadow: 0 0 0px 2px #d7d7d7;
}

input:checked+.payment-radiobtn:after {
  background: #a71116;
  border: 2px solid #fff;
  box-shadow: 0 0 0px 2px #a71116;
}

.darkMode--on input:checked+.payment-radiobtn:after {
  border: 2px solid #222;
}

.radio-outer input {
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 18px;
  top: -1px;
  left: -1px;
  opacity: 0;
  cursor: pointer;
}

.field-outer.radio-outer+.radio-outer {
  margin-left: 50px;
}

.payment-card .field-outer+.field-outer {
  margin: 0;
}

.payment-card {
  align-items: flex-end;
  margin: 0 -15px;
}

.payment-card .inputField {
  margin: 15px 0 0;
  background: var(--white-color);
  box-shadow: 0 0 40px -11px rgba(0, 0, 0, 0.1);
}

.payment-card .inputField {
  margin: 15px 0 0;
  padding: 5px 20px;
}

.payment-card .field-outer {
  padding: 0 15px;
  max-width: 30%;
  flex: 0 0 30%;
}

.payment-card .field-outer:nth-child(2)~.field-outer {
  max-width: 20%;
  flex: 0 0 20%;
}

.darkMode--on .payment-card .inputField {
  background: #111111;
}

.payment-card label {
  font-size: 16px;
  line-height: 38px;
  color: var(--pc-heading-clr);
  font-weight: 500;
}

.checkout-payment-info {
  margin: 30px 0 0;
}

.checkout-person-info .payment-card .field-outer {
  max-width: 33.33% !important;
  flex: 0 1 33.33% !important;
}

.justify-content-between {
  justify-content: space-between;
}

.checkout-payment-info .field-outer p {
  font-size: 14px;
  color: var(--pc-heading-clr);
}

.purchaseTotal {
  font-size: 24px;
  font-weight: 700;
}

.purchase-info-total {
  margin-top: 40px;
}

.checkout-total-amount {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.checkout-action .checkout-clm {
  flex: 0 0 262px;
  max-width: 262px;
}

.checkout-action .checkout-clm label {
  max-width: 150px;
  flex: 0 0 150px;
}

.checkout-total-amount.checkout_discount {
  color: var(--pc-btn-primary-bg);
}

@media (max-width: 1359px) {
  .package-list-col-inner .section-heading {
    padding: 20px 0;
    font-size: 22px;
  }

  .package-list-col {
    padding: 0 20px;
  }

  .plan-heightlighted-price {
    font-size: 35px;
  }

  .package-charges {
    font-size: 18px;
  }

  .pdp-download-detail-list li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .imgupload-content .dashboard-imageupdate-column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 1279px) {
  .package-list-content {
    padding: 20px 20px 50px;
  }

  .package-list-col-inner .section-heading {
    font-size: 16px;
  }

  .pdp-product-info {
    background: #F9F9F9;
    padding: 20px;
    border-radius: 3px;
  }

  .pdp-product-content {
    padding-left: 0;
  }

  .pdp-image-info li {
    line-height: 28px;
  }

  .pdp-product-image img {
    width: 100%;
  }

  .sitelogo img {
    transition: 0.3s all;
    max-width: 100%;
  }

  .navbar {
    padding-left: 20px;
  }

  .package-list-content ul {
    min-height: 178px;
  }

  .dashboard-title {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .package-list-col-inner .section-heading {
    padding: 15px 0;
    font-size: 20px;
  }

  .package-list-outer {
    display: flex;
    margin: 0 -30px;
    flex-wrap: wrap;
  }

  .package-list-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 15px 0;
  }

  .package-header p {
    font-size: 16px;
    line-height: 28px;
  }

  .filter-form-outer {
    padding-right: 0;
  }

  .filter-actionOuter {
    position: static;
    width: 100%;
    margin-top: 15px !important;
  }

  .pdp-product-content {
    margin-top: 20px;
    padding: 0;

  }

  .pdp-product-image {
    padding: 0;
  }

  .feature-gallery .section-heading {
    margin: 0 0 30px;
  }

  .feature-gallery {
    padding-bottom: 60px;
  }

  .pdp-main {
    padding: 50px 0 20px;
  }

  .sitelogo {
    max-width: 80px;
  }

  .dashboard-title {
    font-size: 24px;
  }

  .checkout-payment-title {
    font-size: 24px;
  }

  .purchaseTotal {
    margin: 0 0 20px;
  }

  .uploadimages-infowrap {
    /* flex: 0 0 50%;
    max-width: 50%; */
    padding: 10px 10px 10px 22px;
  }
}

@media (max-width: 767px) {
  .filter-form-inner-wrap .field-outer {
    width: 100%;
    padding: 0px;
  }

  .filter-form-inner-wrap .field-outer+.field-outer {
    margin-top: 15px;
  }

  .aboutUs-banner .banner--main-inside h1 {
    margin-bottom: 15px;
    font-size: 24px;
  }

  .package-list-content ul {
    min-height: initial;
  }

  .checkout-clm:first-child {
    flex-basis: 50%;
    max-width: 50%;
  }

  .checkout-clm:nth-child(2),
  .checkout-clm {
    flex-basis: 25%;
    max-width: 25%;
  }

  .checkout-action .checkout-clm {
    flex: 0 0 auto !important;
    max-width: initial;
  }

  .checkout-payment-method {
    padding: 15px;
  }

  .checkout-payment-title {
    font-size: 20px;
  }

  .checkout-wrap-outer {
    overflow: scroll;
  }

  .checkout-wrap {
    width: 768px;
  }

  .uploadimages-infowrap {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 574px) {
  .buy-btn {
    margin-left: 0;
    margin-top: 15px;
  }

  .pdp-actionbtn-outer {
    flex-direction: column;
  }

  .pdp-download-detail-list p {
    font-size: 14px;
  }

  .des-title-main {
    font-size: 24px;
  }

  .pdp-main {
    padding: 30px 0 20px;
  }

  .checkout-payment-row {
    flex-wrap: wrap;
  }

  .pdp-actionbtn-outer {
    flex-direction: column;
  }

  .field-outer.radio-outer+.radio-outer {
    margin-left: 0;
    margin-top: 20px;
  }

  .checkout-row.checkout-action {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .checkout-action .checkout-clm {
    padding: 0;
  }

  .checkout-action .checkout-clm+.checkout-clm {
    padding: 0 0 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1359px) {
  .buy-btn {
    margin-left: 0;
    margin-top: 15px;
  }
}

/* Profile Page ---- Profile Banner -------*/

.profile-banner .flex-row {
  justify-content: space-between;
  padding: 40px 0px;
}

.profile-banner .profile-image {
  border-radius: 20px;
  border: 3px solid #fe5bea;
  border-top: 3px solid #c3e711;
  border-right: 3px solid #c3e711;
}

.inner-flex-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.inner-text {
  margin-left: 15px;
}

.inner-text i {
  margin: 15px 22px 0 0;
  margin: 15px 22px 0 0;
}

.profile-banner .inner-icons {
  display: flex;
  flex-wrap: wrap;
}

.profile-banner p {
  margin: 15px 0px;
  margin-right: 15px;
  font-size: 22px;
}

.dashboard-main {
  margin-top: 98px;
}

.dashboard-main .dashboard-content {
  padding-bottom: 70px;
}

.navbar--submenu-mega svg {
  margin-left: 10px;
}

.FB-button {
  font-size: 18px;
  background-color: #5f8bcb;
  color: #fff;
  padding: 14px;
  cursor: pointer;
}

.social-btn-fb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
}

.dashboard-content-inner .field-outer label {
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
}

.dashboard-content-inner .field-outer.tabcontent-inner label {
  margin-bottom: 0px;
}

.darkMode--on .dashboard-content-inner .field-outer label {
  color: #d7d7d7;
}

/* picha stock profile page */

.profile-banner {
  color: white;
  padding: 90px 0;
  position: relative;
}

.profile-banner::after {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  content: "";
  inset: 0;
}

.pichaprofile-row .pichastock--btn {
  position: absolute;
  right: 0;
  padding: 8px 32px;
  top: 5px;
}

.pichaprofile-row .btn+.btn {
  margin-right: 0;
}

.pichaprofile-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}

.pichaprofile-image {
  max-width: 220px;
  flex-basis: 220px;
}

.pichaprofile-image img {
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.pichaprofile-content {
  padding-left: 50px;
  max-width: calc(100% - 220px);
  flex: 0 0 calc(100% - 220px);
}

.pichaprofile-name {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 18px;
  padding-right: 130px;
}

.pichaprofile-location li {
  font-size: 16px;
  font-weight: 500;
  margin: 18px 0;
  display: flex;
  align-items: center;
}

.pichaprofile-location li svg,
.pichaprofile-tags li svg {
  width: 19px;
  height: 19px;
  margin-right: 15px;
}

.pichaprofile-location li svg path,
.pichaprofile-tags li path {
  fill: var(--white-color);
}

.pichaprofile-tags ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pichaprofile-tags ul li {
  display: flex;
  align-items: center;
  margin-right: 50px;
  text-transform: capitalize;
  margin-top: 18px;
}

.pichaprofile-tags ul li b {
  margin-left: 4px;
}

.faishonPhotos-btns-filter .tab-links {
  display: flex;
  align-items: center;
}

.faishonPhotos-buttons {
  margin-top: 0;
  display: flex;
}

/* 
 .pichafaishonPhotos-main .pichastock .container {
   padding: 0;
 } */

.inputField.file-browser {
  height: auto;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
}

.dashboard-content-inner .field-outer {
  position: relative;
}

.field-outer [type="file"] {
  position: absolute;
  z-index: 1;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

hr {
  opacity: 0.2;
}

.dividerWrap {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.dividerWrap:after {
  content: "";
  width: 100px;
  height: 2px;
  background: var(--pc-dark-heading-clr);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.dividerWrap p {
  width: 40px;
  display: inline-block;
  background: #fff;
  z-index: 1;
  text-align: center;
  color: var(--pc-heading-clr);
  position: relative;
}

.darkMode--on .dividerWrap p {
  background: #222222;
  color: var(--white-color);
}

.register-container-wrap .login-image-wrap {
  padding-right: 0;
  text-align: right;
}

#socialBtn {
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
}

#socialBtn .S9gUrf-YoZ4jf {
  position: relative;
  /* max-width: 400px; */
  display: block;
  width: 100%;
}

#socialBtn .S9gUrf-YoZ4jf {
  position: relative;
  display: block;
  width: 100%;
  background: #e34941;
  border: none;
  border-radius: 3px;
  padding: 9px 10px;
}

#socialBtn .S9gUrf-YoZ4jf:hover {
  background: #c33e37;
}

#socialBtn .S9gUrf-YoZ4jf * {
  background: none !important;
  border: none !important;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
}

/* #socialBtn .S9gUrf-YoZ4jf path{
   fill:var(--white-color);;
 } */

.social-btn-fb.register-socialbtn {
  margin: 20px auto 0;
}

/* .social-btn-fb.register-socialbtn button {
   border-radius: 3px;
   padding: 20px 28px;
   width: 100%;
   font-size: 16px;
   color: var(--white-color);
   ;
   background: #395996;
   font-weight: 500;
   cursor: pointer;
 } */
.social-btn-fb.register-socialbtn button {
  border-radius: 50px;
  padding: 17px 35px;
  width: 100%;
  font-size: 14px;
  color: #2a2929;
  background: #ffffff;
  font-weight: 600;
  cursor: pointer;
  margin-right: 0;
  color: #585454;
  letter-spacing: 0.25px;
  /* font-family: "Google Sans", arial, sans-serif; */
  font-family: "Artega", sans-serif;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-btn-fb.register-socialbtn button span {
  margin-left: 30px;
}

.social-btn-fb.register-socialbtn button svg {
  float: left;
  color: #2d4676;
  font-size: 19px;
}

.social-btn-fb.register-socialbtn button:hover {
  background: #f3f3f3;
}

.google-button button {
  box-shadow: none !important;
  border: 1px solid lightgrey !important;
  border-radius: 30px !important;
  height: 55px !important;
  padding: 0 30px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #585454 !important;
  margin: 0 !important;
  width: 100% !important;
}

.google-button button div div:last-child {
  text-align: center !important;
}

.inputField.file-browser {
  /* height: 100%; */
  position: relative;
  cursor: pointer;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
}

.inputField.file-browser:after {
  content: "choose file";
  position: absolute;
  top: 50%;
  height: 30px;
  padding: 2px 10px;
  line-height: 30px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  color: var(--white-color);
  background: #222222;
  border-radius: 3px;
  right: 11px;
  transform: translate(0, -50%);
  display: block;
}

.inputField.file-browser:before {
  content: "";
  width: 130px;
  height: 100%;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  background: #f4f4f4;
}

.darkMode--on .inputField.file-browser:before {
  background: #222222;
}

.darkMode--on .inputField.file-browser:after {
  background: #010101;
}

.dashboard-content-inner .field-outer {
  position: relative;
}

.field-outer [type="file"] {
  position: absolute;
  z-index: 1;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.dashboard-content-inner .MuiAutocomplete-hasPopupIcon {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

/* SEARCH PAGE TAG SLIDER ADDED BY HIMANSHU */
.react-multi-carousel-list {
  position: unset !important;
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

/* SEARCH PAGE TAG SLIDER End */

.image {
  max-width: 100%;
}

@media (min-width: 1359px) {
  .pichafaishonPhotos-main .faishonPhotos-btns-filter .tab-links .icon {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 1679px) {
  .pichaprofile-tags ul li {
    margin-right: 30px;
  }

  .pichaprofile-image {
    max-width: 180px;
    flex-basis: 180px;
  }

  .pichaprofile-content {
    padding-left: 50px;
    max-width: calc(100% - 180px);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 180px);
  }

  .pichafaishonPhotos-main {
    margin-top: 0px;
  }
}

@media (max-width: 1500px) {
  .navbar--menus ul li a {
    font-size: 14px;
  }

  .navbar--menus ul li+li {
    margin-left: 25px;
  }
}

@media (max-width: 1359px) {
  .dashboard-sidebar {
    flex: 0 0 250px;
    max-width: 250px;
  }

  .dashboard-content {
    padding-left: 40px;
    padding-top: 20px;
    max-width: calc(100% - 250px);
    flex-basis: calc(100% - 250px);
    padding-bottom: 60px;
  }

  .dashboard-content-inner .conatactForm-btn {
    max-width: 144px;
    padding: 5px 0;
  }

  .pdp-image-info li {
    padding: 0;
    line-height: 18px;
  }

  .pdp-image-info li+li {
    padding-left: 0;
    border-left: none;
    margin-top: 11px;
  }

  .pdp-image-info {
    flex-wrap: wrap;
    flex-flow: column;
    align-items: flex-start;
  }

  .resources-static .flex-column-20 {
    flex: 0 0 25%;
  }

  .static-page-wrapper.resources-static h2 {
    margin-bottom: 25px;
    font-weight: 500;
    min-height: auto;
    overflow: hidden;
    font-size: 18px;
  }
}

@media (max-width: 1279px) {
  .static-page-wrapper img {
    max-width: 100%;
  }

  .dashboard-sidebar li a {
    padding: 15px 0 14px 20px;
    font-size: 14px;
  }

  .dashboard-sidebar li svg {
    height: 15px;
    width: 15px;
  }

  .dashboard-content {
    max-width: calc(100% - 250px);
    flex-basis: calc(100% - 250px);
    padding-left: 40px;
    padding-top: 0px;
  }

  .dashboard-content .field-outer.m-three-column {
    max-width: 50%;
    flex: 0 0 50%;
    margin-top: 20px !important;
  }

  /* .inputField.file-browser:after {
   line-height: 48px;
 } */

  .picha-grants-wrap .category-para p:first-child {
    margin-top: 0 !important;
  }

  p.section-heading {
    margin: 0 0 20px !important;
  }

  .main-page-outer {
    padding: 40px 0 40px;
  }

  .search-field-outer {
    margin-left: 30px;
  }
}

@media (max-width: 991px) {
  .navbar .dark-mode--btn {
    top: 21px;
    /* top: 61px; Adjusted to be just below the banner */
  }

  .faishonPhotos-btns-filter .tab-links+.tab-links {
    margin-left: 25px;
  }

  .faishonPhotos-btns-filter .tab-links .icon {
    margin-right: 6px;
    width: 18px;
    box-sizing: border-box;
  }

  main {
    margin-top: 72px;
  }

  .my-masonry-grid {
    margin-top: 25px;
  }

  .dashboard-main .container {
    padding-left: 0;
  }

  .dashboard-sidebar li a {
    text-indent: -999px;
    font-size: 0;
    min-height: 47px;
  }

  .dashboard-sidebar {
    flex: 0 0 60px;
    max-width: 60px;
  }

  .dashboard-sidebar ul {
    width: 250px;
  }

  .browse-images-wrap {
    padding: 0 15px;
  }

  .dashboard-imageupdate-row {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 40px;
    justify-content: center;
  }

  .dashboard-imageupdate-inner img {
    max-width: 100%;
    border-radius: 15px;
  }

  .dashboard-content {
    max-width: calc(100% - 60px);
    flex-basis: calc(100% - 60px);
    padding-bottom: 70px;
  }

  .dashboard-row {
    overflow: hidden;
  }

  .dashboard-sidebar:hover li a {
    text-indent: 0;
    font-size: 14px;
  }

  .dashboard-sidebar:hover {
    flex: 0 0 250px;
    max-width: 250px;
  }

  .inputField.file-browser {
    font-size: 14px;
  }

  .content-page-wrap h3 {
    font-size: 24px;
    margin-top: 10px;
  }

  .content-page-wrap h4 {
    font-size: 20px;
  }

  .content-page-wrap {
    padding: 30px 0;
  }

  .open-menu .home-header .humburger-icon span {
    background: var(--white-color);
  }

  .package-list-content ul {
    min-height: initial;
  }

  .package-charges {
    height: initial;
  }

  .navbar--submenu .navbar--submenu-mega ul li a {
    color: var(--pc-dark-heading-clr);
  }

  .contactForm {
    padding: 40px 0 30px;
  }

  .forgotpswrdForm-subheading {
    margin-bottom: 30px;
    text-align: center;
  }

  .forgotpswrdForm-reg-login {
    margin: 30px 0 0;
  }

  .browse-images-wrap h3 {
    font-size: 14px;
  }

  .section-heading.mb-50 {
    margin-bottom: 15px;
  }

  .dashboard-main {
    margin-top: 96px;
  }
}

@media (max-width: 767px) {

  .content-page-wrap p,
  .content-page-wrap ul li {
    font-size: 14px;
  }

  .pichaprofile-content {
    padding-left: 0;
    max-width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    margin-top: 25px;
  }

  .profile-banner {
    padding: 40px 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top center !important;
  }

  .faishonPhotos-buttons {
    flex-wrap: wrap;
  }

  .faishonPhotos-btns-filter .tab-links {
    flex: 0 0 100%;
    margin: 0 !important;
    border-bottom: 2px solid #eee;
    padding: 15px 0;
    max-width: 100%;
  }

  .faishonPhotos-btns-filter {
    border-bottom: none;
  }

  .faishonPhotos-buttons,
  .faishonPhotos-btns-filter {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dashboard-content .field-outer.m-three-column {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .dashboard-title {
    margin: 10px 0;
  }

  .imageupdate-intro {
    font-size: 16px;
  }

  .avtarImage-column {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .dashboard-content {
    padding-left: 30px;
  }

  .youtube-vedio iframe {
    width: 100%;
    height: 100%;
    min-height: 230px;
  }

  .resources-static .inner-column img {
    max-width: 350px;
    width: 100%;
  }

  .resources-static .flex-column-20 {
    flex: 0 0 50%;
  }

  .static-page-wrapper.resources-static h2 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .static-page-wrapper.resources-static {
    padding-top: 0;
    padding-bottom: 80px;
    margin-top: 0;
  }

  .picha-grants-wrap .flex-column-60,
  .picha-grants-wrap .flex-column-30 {
    flex: 0 0 100%;
  }

  .picha-grants-wrap .flex-column-30 {
    text-align: center;
  }

  .picha-grants-wrap .static-page-wrapper h1 {
    font-size: 28px;
  }

  .picha-grants-wrap .banner-text p {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }

  .picha-grants-wrap .banner-text button {
    margin: 15px 0px;
    margin-left: 0;
    width: 100%;
  }

  .picha-grants-wrap .flex-column {
    flex: 0 0 100%;
  }

  .awards-register .para-inside {
    padding-top: 30px;
    margin: auto;
    width: 100%;
  }

  .awards-step .flex-column {
    max-width: 100%;
  }

  .faishonPhotos-filter {
    margin-top: 20px;
  }

  .react-multi-carousel-list .react-multiple-carousel__arrow {
    min-width: 30px;
    min-height: 30px;
  }

  .react-multi-carousel-list .react-multiple-carousel__arrow::before {
    font-size: 14px;
  }

  .welcome .collection--multiimg-left {
    width: calc(50% - 10px);
  }

  .welcome .collection--multiimg-right {
    width: 50%;
  }

  .faishon .collection--main-btns {
    margin: 30px 30px 50px;
  }

  .darkMode--on .auth-container.register-container,
  .darkMode--on .forgotpassword-outer .auth-container {
    padding: 30px;
    width: 100%;
  }

  /* .auth-page-outer {
    padding: 50px 30px;
  } */

  .forgotpswrdForm-subheading {
    font-size: 14px;
  }

  .dashboard-imageupdate {
    padding: 20px;
  }

  .imageupdate-uploading-area {
    padding: 20px;
  }

  .purchase-history.result-table {
    overflow: auto;
  }

  .purchase-history.result-table table {
    width: 768px;
  }

  .weaving-stories-wrap .resources-static .flex-column-20 {
    flex: 0 0 50%;
  }
}

@media (max-width: 574px) {
  .pichaprofile-image {
    max-width: 100px;
    flex-basis: 100px;
  }

  .pichaprofile-location li {
    margin: 10px 0;
  }

  .pichaprofile-tags ul li {
    margin-top: 15px;
  }

  .search_box input[type="search"] {
    padding: 0 40px 0 10px !important;
    font-size: 12px;
  }

  .banner .dropbtn {
    font-size: 12px;
  }

  .banner {
    padding-top: 90px;
  }

  .banner--main-inside h1 {
    font-size: 24px;
  }

  .dashboard-content-inner .field-outer {
    position: relative;
    display: flex;
    /* flex-direction: column-reverse; */
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  /* .dashboard-content-inner .imgupload-sidebar .field-outer {
    flex-direction: column;
  }

  .dashboard-content-inner .contactForm--submit-btn.field-outer {
   flex-direction: column;
  }
   */

  .dashboard-content-inner .contactForm--submit-btn.field-outer .btn+.btn {
    margin-left: 0;
    margin-top: 10px;
  }

  .imgupload-sidebar .field-outer.field-outer-radio-wrap {
    flex-direction: row !important;
  }

  .dashboard-content-inner .conatactForm-btn {
    max-width: 100%;
  }

  .dashboard-content-inner .conatactForm-btn+.conatactForm-btn {
    margin-left: 0;
    margin-bottom: 14px;
  }

  .avtarImage-column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .avtarImage-column+.avtarImage-column {
    margin-top: 10px;
  }

  .inputField.file-browser:after {
    line-height: 30px;
    padding: 0 10px;
    height: auto;
    font-size: 10px;
    top: 50%;
    transform: translate(0, -50%);
    right: 9px;
  }

  .resources-static .flex-column-20 {
    flex: 0 0 100%;
  }

  .resources-static .flex-column-20 .inner-column {
    margin-top: 30px;
  }

  .grants-step .flex-column-20 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    margin: auto;
    flex: 0 0 50%;
    padding: 0 10px;
  }

  .open-menu .humburger-icon span {
    background: var(--white-color);
  }

  .weaving-stories-wrap .resources-static .flex-column-20 {
    flex: 0 0 100%;
  }
}

/* Download Progress Css */

.progress {
  overflow: hidden;
  height: 20px;
  background-color: #f7f7f7;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.progress-bar {
  width: 0;
  height: 100%;
  color: #fff;
  text-align: center;
  background-color: #fe5bea;
  border-radius: 20px;
}

.progress {
  overflow: hidden;
  height: 20px;
  background-color: #f7f7f7;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  border-radius: 20px;
}

.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 10px;
  text-align: left;
}

.progress-bar-header p {
  font-size: 14px;
  color: #f5f1f1;
}

.progress-bar-header h3 {
  font-size: 18px;
  margin: 5px 0 0;
  color: rgb(248, 242, 242);
}

.progress-bar-container {
  padding: 40px;
  max-width: 650px;
  width: 90%;
  background: #0c0c0c;
  border-radius: 20px;
  margin: 0 auto;
  position: fixed;
  z-index: 999;
  bottom: 15px;
  right: 15px;
  transition: 0.5s all cubic-bezier(0.55, 0.06, 0.68, 0.19);
}

.darkMode--on .progress-bar-container {
  background: #f7f3f3;
}

.darkMode--on .progress-bar-header p,
.darkMode--on .progress-bar-header h3 {
  color: #333;
}

/* PRODUCT LISTING */
.imgupload-form {
  text-align: left;
}

.imgupload-form .inputField {
  background: var(--white-color);
}

.imgupload-wrap {
  text-align: left;
}

.imgupload-wrap {
  display: flex;
  flex-wrap: wrap;
}

.imgupload-content {
  flex: 0 0 calc(100% - 322px);

  max-width: calc(100% - 322px);
  padding-right: 40px;
}

.imgupload-sidebar {
  flex-basis: 322px;
  max-width: 322px;
}

.dashboard-imageupdate.imgupload-sidebar-white {
  padding-right: 10px;
}

.imgupload-sidebar-white .imgupload-sidebar {
  background: #fff;
  padding: 20px 30px;
  margin: -30px 0px -30px 0px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.imgupload-sidebar-white .imgupload-form .inputField,
.imgupload-sidebar-white .rti--container .rti--input {
  background: #f4f4f4;
}

.imgupload-info {
  font-weight: 500;
  font-size: 20px;
  color: var(--pc-heading-clr);
}

.imgupload-content-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

/* .dashboard-imageupdate .delete-btn {
  padding: 0 30px;
} */

.imgupload-form label {
  font-size: 16px;
  color: var(--pc-heading-clr);
  margin: 0 0 10px;
  display: block;
  font-weight: 500;
}

.imgupload-form .field-outer>label {
  flex: 0 0 100%;
  max-width: 100%;
}

.imgupload-form .inputField,
.rti--container .rti--input {
  background: var(--white-color);
  padding: 5px 13px;
}

.dashboard-content-inner .rti--container .rti--input {
  background: #f4f4f4;
  padding: 5px 13px;
}

.field-outer .rti--container {
  border: none;
  padding: 0;
  background: none;
}

.field-outer-radio-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.field-outer-radio-wrap .radio-wrap {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 0 95px;
}

.field-outer-radio-wrap .radio-wrap label {
  margin: 0;
  position: relative;
  padding-left: 25px;
}

.imgupload-form .btn {
  width: 100%;
}

.imgupload-content .dashboard-imageupdate-column {
  padding: 15px;
}

@media (max-width: 1359px) {
  .imgupload-content .dashboard-imageupdate-column {
    padding: 5px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .field-outer-radio-wrap .radio-wrap {
    display: block;
    flex: 0 0 auto;
    margin: 0 0 14px;
  }
}

/*****Cart Popup CSS Starts Here*******/
.pc-menu-row {
  display: flex;
  position: relative;
}

.pc-menu-row img {
  max-width: 50px;
  max-height: 50px;
  position: absolute;
  top: 4px;
}

.pc-menu-wrap {
  max-height: 360px;
  overflow: auto;
  width: calc(20px + 100%);
}

.prodcuct-cart-menu {
  overflow: hidden;
}

.pc-menu-column {
  padding-left: 65px;
}

.pc-menu-title {
  font-size: 16px;
}

.pc-menu-title {
  font-size: 16px;
  white-space: nowrap;
  max-width: 198px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0px;
  font-weight: 500;
  color: var(--pc-heading-clr);
}

.darkMode--on .pc-menu-title,
.darkMode--on .pc-menu-column p,
.darkMode--on .pc-menu-footer p {
  color: #d7d7d7;
}

.pc-menu-column p {
  font-size: 14px;
  color: var(--pc-heading-clr);
}

.pc-menu-row:hover .pc-menu-title,
.pc-menu-row:hover .pc-menu-column p {
  color: var(--pc-btn-primary-bg);
}

.pc-menu-row+.pc-menu-row {
  margin: 20px 0 0;
}

.btn.primary-btn.checkout-btn {
  width: 100%;
  padding: 0;
}

.pc-menu-footer {
  justify-content: space-between;
  margin: 20px 0 0;
  border-top: 1px solid #f4f4f4;
  padding-top: 15px;
}

.cart-item {
  font-weight: 600;
}

.pc-menu-footer p {
  color: var(--pc-heading-clr);
}

.btn.primary-btn.checkout-btn {
  width: 100%;
  padding: 8px 0;
  font-weight: 600;
  color: #fff !important;
  font-size: 15px;
}

span.cart-count {
  background: var(--pc-btn-primary-bg);
  border-radius: 20px;
  color: var(--white-color);
  font-size: 10px;
  height: 16px;
  line-height: 17px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: 0;
  top: -6px;
  width: 16px;
}

.checkout-main {
  padding: 70px 0;
}

#cart .navbar--submenu {
  left: auto;
  right: -31px;
  transform: translate(0, 0);
  min-width: 380px;
  padding: 20px 20px 30px;
  box-shadow: rgb(0 0 0 / 40%) 0px 10px 15px -3px,
    rgb(0 0 0 / 5%) 0px 4px 6px -2px;
}

#cart ul li,
#cart .navbar--submenu-mega {
  width: 100%;
}

.pc-menu-wrap h1 {
  font-size: 18px;
  color: #333;
  text-align: center;
  padding: 20px 0;
  font-weight: 500;
  width: 100%;
}

.darkMode--on .pc-menu-wrap h1 {
  color: #d7d7d7;
}

#cart .navbar--submenu-content:before {
  left: auto;
  right: -5px;
  top: -31px;
}

.imgupload-sidebar .field-outer+.field-outer {
  margin-top: 28px;
}

.imgupload-content-checkbox label {
  position: relative;
  padding: 5px 0 3px 40px;
  margin: 0;
  font-weight: 500;
  color: #666666;
}

.imgupload-content-checkbox label:before,
.imgupload-content-checkbox label:after,
.field-outer-radio-wrap .radio-wrap label:after,
.field-outer-radio-wrap .radio-wrap label:before {
  content: "";
  position: absolute;
}

.imgupload-content-checkbox label:before {
  width: 21px;
  height: 21px;
  border: 2px solid #a71116;
  background-color: #f4f4f4;
  left: 0;
  top: 0;
  border-radius: 3px;
}

.imgupload-content-checkbox label:after {
  width: 12px;
  height: 6px;
  border-left: 4px solid var(--white-color);
  border-bottom: 4px solid var(--white-color);
  margin: 0;
  left: 0;
  top: 5px;
  left: 4px;
  transform: rotate(-45deg);
  opacity: 0;
  border-radius: 2px;
}

.imgupload-content-checkbox {
  position: relative;
}

.imgupload-content-checkbox input[type="checkbox"],
.radio-wrap input[type="radio"] {
  width: 26px;
  height: 26px;
  top: 0;
  position: absolute;
  left: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.radio-wrap input[type="radio"] {
  width: 16px;
  height: 17px;
}

.imgupload-content-checkbox input:checked+label:before {
  background-color: #a71116;
}

.imgupload-content-checkbox input:checked+label:after {
  opacity: 1;
}

.field-outer-radio-wrap .radio-wrap label:before {
  width: 10px;
  height: 10px;
  border: 2px solid #f4f4f4;
  border-radius: 30px;
  background-color: #f4f4f4;
  box-shadow: 0 0 0px 2px #a71116;
  left: 0;
  top: 3px;
}

.field-outer-radio-wrap .radio-wrap input:checked+label:before {
  background-color: #a71116;
}

.imgupload-sidebar .field-outer p {
  font-size: 16px;
  color: var(--pc-heading-clr);
  margin: 0 0 8px;
}

.darkMode--on .imgupload-content-checkbox label:before {
  background-color: #222222;
}

.darkMode--on .imgupload-info,
.darkMode--on .imgupload-form label,
.darkMode--on .imgupload-sidebar .field-outer p {
  color: var(--pc-dark-heading-clr);
}

.darkMode--on .field-outer-radio-wrap .radio-wrap label:before {
  border: 2px solid #222222;
  background: #222222;
}

.darkMode--on .imgupload-form .inputField,
.darkMode--on .rti--container .rti--input {
  background: #101010;
  padding: 5px 13px;
}

.darkMode--on .rti--container .rti--input,
.darkMode--on .dashboard-content-inner .inputField {
  background-color: #222222;
}

.darkMode--on .dashboard-content-inner .imgupload-sidebar .inputField,
.darkMode--on .dashboard-content-inner .imgupload-sidebar .rti--input {
  background: #101010;
}

.darkMode--on .rti--container .rti--input,
.darkMode--on .inputField {
  color: #d7d7d7;
}

.darkMode--on .rti--tag {
  background: #222222;
  color: #ffffff;
  border: 1px solid rgba(var(--secondary-color), 1);
}

.darkMode--on .rti--tag button {
  color: #d7d7d7;
}

.search-variation-list {
  max-height: 200px;
  overflow: auto;
}

.search-variation-list::-webkit-scrollbar {
  width: 5px;
}

.search-variation-list::-webkit-scrollbar-thumb {
  background-color: #bbbbbb;
  border-radius: 10px;
}

.imgupload-wrap .field-outer [type="file"] {
  bottom: 0;
  height: 50px;
  width: 100%;
}

.thankyou-page {
  padding: 70px 0;
}

.tble-clm {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

@media (max-width: 1400px) {
  .imgupload-sidebar {
    flex-basis: 250px;
    max-width: 250px;
  }

  .imgupload-sidebar-white .imgupload-sidebar {
    padding: 20px;
  }

  .imgupload-content {
    flex-basis: calc(100% - 250px);
    max-width: calc(100% - 250px);
  }
}

@media (max-width: 1279px) {
  .imgupload-content {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .imgupload-sidebar-white .imgupload-sidebar {
    margin: 0;
  }

  .imgupload-content .dashboard-imageupdate-column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .imgupload-wrap {
    justify-content: center;
  }

  .imgupload-sidebar {
    flex-basis: 100%;
    max-width: 400px;
  }
}

@media (max-width: 991px) {
  .tble-clm {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .dashboard-imageupdate-row {
    margin: 30px 0 0;
  }

  .imgupload-content-flex {
    align-items: self-start;
    justify-content: flex-start;
    margin-top: 0px;
    flex-flow: column;
  }

  .imgupload-content-checkbox {
    margin-bottom: 20px;
  }

  .imgupload-content .dashboard-imageupdate-column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .imgupload-form .btn {
    padding: 10px;
    font-size: 14px;
  }

  .imgupload-content .pichastock-btn-outer {
    margin: 0 0 50px;
  }

  .imgupload-content .imgupload-form .btn {
    padding: 10px;
    font-size: 14px;
    margin-top: 20px !important;
  }
}

/*****Cart Popup CSS Ends Here*******/
.thankyou-page table {
  width: 100%;
  border-collapse: collapse;
}

.thankyou-page table th {
  background: #222222;
  color: var(--white-color);
  text-align: left;
  text-transform: capitalize;
  padding: 10px 20px;
}

.thankyou-page table td {
  padding: 10px 20px;
}

.thankyou-page .tble-clm .section-heading {
  font-size: 18px;
  margin-bottom: 20px;
}

.section-heading.thanxyou-title {
  margin-bottom: 40px;
}

.dashboard-imageupdate-inner {
  border: 2px solid transparent;
}

.selected-img .dashboard-imageupdate-inner {
  border: 2px solid #a71116;
}

.dashboard-imageupdate-inner input[type="checkbox"] {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.selected-img .dashboard-imageupdate-inner img {
  opacity: 0.8;
}

.invoice-row {
  display: flex;
  flex-wrap: wrap;
}

.invoice-left {
  max-width: 20%;
  flex: 0 0 30%;
}

.invoice-right {
  flex: 0 0 70%;
  max-width: 70%;
  padding-left: 30px;
}

.invoice-main label {
  font-size: 18px;
  font-weight: 600;
}

.invoce-info-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 0;
}

.invoce-info-row label {
  max-width: 40%;
  flex: 0 0 40%;
}

.invoce-info {
  margin: 50px 0;
}

.invoce-itemstbl {
  width: 100%;
  border-collapse: collapse;
}

.invoce-itemstbl td,
.invoce-itemstbl th {
  border: 1px solid #eeeeee;
}

.invoce-itemstbl td {
  padding: 9px 20px;
  font-size: 18px;
}

.invoce-itemstbl th {
  background: #f4f4f4;
  padding: 8px 0;
  font-size: 20px;
}

.invoce-itemstbl td:first-child {
  width: 50%;
}

.invoce-itemstbl+.invoce-itemstbl {
  margin-top: 20px;
}

.invoce-itemstbl td+td {
  text-align: right;
}

.invoce-total td {
  font-size: 20px;
  font-weight: 600;
}

.invoice-main * {
  color: #333333;
}

.darkMode--on .invoice-main * {
  color: #d7d7d7;
}

.darkMode--on .invoce-itemstbl th {
  background: #222222;
}

.checkout-content {
  min-height: 250px;
}

.pdp-product-image button {
  padding: 3px 10px;
  background: var(--white-color);
  margin: 10px 0 0;
  position: absolute;
  top: 0px;
  z-index: 99;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  left: 25px;
}

.darkMode--on .pdp-product-image button {
  background: var(--pc-btn-primary-bg);
  color: var(--white-color);
}

.pdp-product-image button svg {
  margin-right: 8px;
}

.paypal-wrap {
  position: relative;
}

.paypal-wrap .primary-btn {
  opacity: 0;
}

.bt2 {
  position: absolute;
  top: 0;
  opacity: 1;
}

.bt2-disable {
  cursor: not-allowed;
}

.dwnload-btn {
  background: #a71116;
  border: none;
  padding: 4px 10px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

.dwnload-btn:hover {
  background: #850c10;
}

.darkMode--on .thankyou-page table td {
  color: var(--pc-dark-heading-clr);
}

.StripeElement.StripeElement--empty {
  padding: 0px 0;
  background: #fff;
  padding: 14px 20px;
  margin: 50px 0 20px;
}

.StripeElement.StripeElement--empty iframe {
  height: 23px !important;
}

.darkMode--on .StripeElement.StripeElement--empty {
  background: #000;
}

.darkMode--on .checkout-main h1 {
  color: #d7d7d7;
}

@media (max-width: 991px) {
  .tble-clm {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tble-clm+.tble-clm {
    margin-top: 30px;
  }

  .checkout-main {
    padding: 50px 0 10px;
  }

  .checkout-person-info .payment-card .field-outer {
    max-width: 50% !important;
    flex: 0 1 50% !important;
  }

  .dashboard-imageupdate-column {
    flex: 0 0 33%;
    max-width: 33%;
  }
}

@media (max-width: 767px) {
  .checkout-person-info .payment-card .field-outer {
    max-width: 100% !important;
    flex: 0 1 100% !important;
  }

  .dashboard-imageupdate-column {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .invoice-row {
    flex-direction: column;
  }

  .invoice-right {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }

  .invoice-main {
    padding: 20px 0;
  }

  .invoce-info {
    margin: 20px 0;
  }
}

@media (max-width: 575px) {
  .invoce-info-row label {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 7px;
  }

  .invoce-itemstbl th {
    font-size: 16px;
  }

  .invoce-itemstbl td {
    font-size: 14px;
  }

  .dashboard-imageupdate-column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .lableMb {
    margin-bottom: 25px;
  }
}

.min-h-empty {
  min-height: 70vh;
}

.primary-btn[disabled] {
  cursor: not-allowed;
}

.close-btn {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: none;
  top: 10px;
  position: absolute;
  right: 12px;
  color: #ffffff;
  background: var(--pc-btn-primary-bg);
}

/* .MuiBox-root.css-1ur8laq {
  background: none;
  position: static;
  min-height: 80vh;
} */

h1.MuiTypography-root.MuiTypography-h1.css-o2w69a-MuiTypography-root {
  font-size: 300px;
  font-weight: 700;
  background-image: url("https://pichastockv2-prod-resize-media.s3.amazonaws.com/2410/resized/images/815-w-C0StwkqRCF1XfpKB_1669452977.jpg");
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Artega', sans-serif; */
  letter-spacing: 3px;
  color: transparent !important;
  background-size: 630px;
  background-repeat: no-repeat;
  text-shadow: 0 0px 10px rgb(0 0 0 / 10%);
}

.css-2ulfj5-MuiTypography-root {
  color: #333333 !important;
  margin: 0px 0 50px 0px !important;
  font-size: 40px !important;
  font-family: "Montserrat", sans-serif !important;
  /* font-family: 'Artega', sans-serif !important; */
}

/* button.MuiButtonBase-root {
  background: var(--pc-btn-primary-bg) !important;
  padding: 14px 50px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  cursor: pointer;
} */

.dashboard-content-inner button.MuiButtonBase-root,
.login-page-form-outer button.MuiButtonBase-root {
  /* background: none !important; */
  /* padding: 0 !important; */
  /* color: #333333 !important; */
}

.dashboard-content-inner .MuiOutlinedInput-notchedOutline,
.login-page-form-outer .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.login-page-form-outer .MuiAutocomplete-hasPopupIcon {
  width: 100%;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

/***********Challenge Details Page Start********/

.challenge-content-column {
  flex: 0 0 calc(100% - 443px);
  max-width: calc(100% - 443px);
  padding-right: 70px;
  padding-bottom: 50px;
}

.challenge-sidebar-column {
  flex: 0 0 40%;
  max-width: 40%;
}

.challenge-bannerimg {
  max-width: 100%;
  width: 100%;
  margin: 0 0 40px;
}

.challenge-content-column h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.darkMode--on .challenge-content-column h2 {
  color: #d7d7d7;
}

.challenge-content-column h3 {
  font-size: 26px;
  margin-bottom: 20px;
}

.challenge-content-column h5 {
  font-size: 20px;
  margin-bottom: 20px;
}

.challenge-content-column h5 .line-through {
  text-decoration: line-through;
  font-weight: 400;
}

.challenge-content-column h4 {
  font-size: 24px;
  margin-bottom: 20px;
}

.challenge-content-column p {
  font-size: 16px;
  line-height: 28px;
}

.challenge-content-column p+p {
  margin: 15px 0;
}

.challenge-sidebar-wrap {
  background: #f7f7f7;
  padding: 30px;
}

.challenge-sidebar-section+.challenge-sidebar-section {
  margin-top: 30px;
}

.sidebar-title {
  font-size: 28px;
  margin: 0 0 20px;
  font-weight: 600;
}

.joinChallenge {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.joinChallenge img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.joinChallenge span {
  margin-left: 15px;
}

.primary-btn.joinbtn {
  min-width: 100%;
}

.primary-btn.joinbtn:disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* .challenge-sidebar-section {
 margin-top: 50px;
 
 } */

.photographer-wrap {
  display: flex;

  flex-wrap: wrap;
  gap: 20px;
}

.photographer-wrap img {
  border-radius: 100px;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.challenge-sidebar-column {
  flex: 0 0 443px;
  max-width: 443px;
}

.photographer-col {
  padding: 10px;
  width: 75px;
  height: 75px;
}

.challenge-wrap>.container {
  padding-top: 100px;
  padding-bottom: 40px;
}

.challenge-wrap .section-heading {
  text-align: left;
}

.challenge-wrap .view_all {
  font-size: 16px;
  line-height: 38px;
  color: var(--pc-heading-clr);
  font-weight: 600;
  text-transform: capitalize;
  transition: var(--pc-default-transition);
  text-align: right;
  float: right;
  width: 100%;
}

.darkMode--on .challenge-wrap .view_all {
  color: #ffffff;
}

.hide_cls {
  display: none !important;
}

.darkMode--on .challenge-sidebar-wrap {
  background: #222222;
}

.darkMode--on .challenge-content-column h5,
.darkMode--on .sidebar-title,
.darkMode--on .challenge-sidebar-wrap * {
  color: #ffffff;
}

@media (max-width: 991px) {
  .challenge-wrap.dashboard-main .container {
    padding-inline: 20px;
  }

  .challenge-sidebar-column {
    flex: 0 0 330px;
    max-width: 330px;
  }

  .challenge-content-column {
    flex-basis: calc(100% - 330px);
    max-width: calc(100% - 330px);
  }

  .photographer-wrap {
    gap: 0;
  }

  .photographer-col {
    padding: 8px;
  }

  .challenge-wrap>.container {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .challenge-content-column {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .challenge-sidebar-column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/***********Challenge Details Page Ends********/

/***********Wishlist Page Ends********/
/* .create-wishlist-btn {
   background: #0a0a0a;
   color: #ffffff !important;
   font-size: 16px !important;
   font-weight: 600 !important;
   border-radius: 0;
   padding: 12px 30px !important;
   border: none !important;
   text-align: center;
   width: auto !important;
   max-width: 100% !important;
   min-width: 150px;
   margin-left: 19px;
 } */

.create_wishlist {
  background: #0a0a0a;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 0;
  padding: 12px 30px !important;
  border: none !important;
  text-align: center;
  width: auto !important;
  max-width: 100% !important;
  min-width: 150px;
  margin-left: 19px;
  float: right;
}

/*********** Wishlist Page Ends********/
/***********Gallery CSS starts********/

/* .MuiTabs-flexContainer {
  background-color: #ebebeb;
  overflow: auto;
} */

.dashboard-content-inner .dashboard-tabs-outer .MuiButtonBase-root {
  padding: 10px 30px !important;
  display: inline-block;
  border-bottom: 2px solid transparent !important;
  background: none !important;
  color: #333333 !important;
  font-size: 16px !important;
  line-height: 25px;
}

.dashboard-content-inner .dashboard-tabs-outer .MuiButtonBase-root>span {
  position: relative;
}

.dashboard-content-inner .dashboard-tabs-outer .MuiButtonBase-root span span {
  background: #333;
  background: var(--pc-heading-clr);
  border-radius: 20px;
  color: #fff;
  color: var(--white-color);
  display: inline-block;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  margin: 0 0 0 6px;
  position: absolute;
  text-align: center;
  top: -13px;
  width: 20px;
  right: -12px;
}

.tabcontentcolumn-inner {
  padding: 15px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard-tabs-outer .MuiButtonBase-root[aria-selected="true"] {
  border-bottom: 2px solid #222222;
}

.MuiTabs-scroller .css-1aquho2-MuiTabs-indicator {
  background-color: #333333;
}

.tabcontent-filter {
  display: flex;
  margin: 20px 0 0;
  justify-content: space-between;
}

.tabcontent-filter .search-variation-outer .search-variation .inputField {
  min-width: 190px;
  padding: 2px 15px;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #f7f7f7;
  background: #f1f1f1;
  border-radius: 3px;
  max-width: 200px;
  width: 100%;
}

.tabcontent-inner {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.tabcontent-inner+.tabcontent-inner {
  justify-content: flex-end;
  margin: 0;
}

.tabcontent-inner .search-variation svg {
  height: 15px;
  right: 15px;
}

.tabcontent-inner label~label {
  margin-left: 20px;
}

.tabcontent-column {
  padding: 15px;
}

.tabcontent-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.tabcontent-column {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
}

.tabcontentcolumn-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 0;
}

.tabcontentcolumn-info p {
  text-align: center;
}

.tabcontent-filter .search-variation-outer .search-variation {
  margin: 0;
  max-width: initial;
  width: auto;
}

/* .info-count {
   display: block;
 } */

.info-count {
  color: var(--pc-btn-primary-bg);
  /* display: block; */
}

.tabcontentcolumn-inner img {
  max-width: 100%;
  max-height: 157px;
  min-height: 200px;
  object-fit: cover;
}

.galleryEditbtn {
  color: #ffffff;
  position: absolute;
  left: 26px;
  top: 26px;
  padding: 4px 12px;
}

/* .dashboard-tabs-outer .MuiBox-root {
  position: relative;
} */

/* .darkMode--on .dashboard-tabs-outer .MuiTabs-flexContainer {
  background-color: #222222;
} */

.darkMode--on .dashboard-tabs-outer .MuiButtonBase-root {
  color: #d7d7d7 !important;
}

.darkMode--on .tabcontent-inner label {
  color: #d7d7d7;
}

.darkMode--on .tabcontent-filter select {
  border: 1px solid #222222;
  background: #222222;
}

.darkMode--on .dashboard-tabs-outer .MuiButtonBase-root[aria-selected="true"] {
  border-bottom: 2px solid #d7d7d7 !important;
}

.darkMode--on .tabcontentcolumn-inner {
  border: 1px solid #222222;
}

@media (max-width: 1359px) {
  .tabcontent-column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 991px) {
  .tabcontent-filter select {
    min-width: 100px;
    max-width: 150px;
  }

  .tabcontentcolumn-inner img {
    max-height: 120px;
    min-height: 118px;
  }
}

@media (max-width: 767px) {
  .tabcontent-filter {
    flex-wrap: wrap;
  }

  .tabcontent-inner+.tabcontent-inner {
    justify-content: flex-start;
    margin-top: 14px;
  }

  .tabcontent-column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .tabcontent-inner label {
    flex: 0 0 130px;
  }

  .tabcontent-filter {
    flex-direction: column;
  }

  .tabcontentcolumn-info {
    padding: 10px 0px 0;
  }
}

@media (max-width: 574px) {
  .tabcontent-column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dashboard-content-inner .tabcontent-filter .field-outer {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .tabcontent-filter .search-variation-outer .search-variation {
    margin: 7px 0 0;
  }
}

/***********Gallery CSS  Ends********/

.main-page-outer.challenge-wrap.dashboard-main {
  padding-top: 0;
}

.no-records {
  padding-left: 30px;
}

.no-records h2 {
  color: var(--pc-heading-clr);
}

.darkMode--on .no-records h2 {
  color: #757575;
}

.result-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.result-table table tr:nth-child(even) {
  background-color: #f3f3f3;
}

.result-table table th,
.result-table table td {
  padding: 20px;
  color: #333333;
  font-size: 15px;
}

.result-table table th {
  color: #ffffff;
  background: #333333;
  font-size: 16px;
  white-space: nowrap;
}

.result-table button.btn {
  padding: 8px 18px;
  font-size: 14px;
  line-height: inherit;
}

.main.purchase-history-wrap {
  overflow: hidden;
  width: 100%;
}

.purchase-history.result-table {
  overflow-x: auto;
}

.purchase-history td img {
  max-height: 100px;
}

.darkMode--on .result-table table td {
  color: #d7d7d7;
}

.darkMode--on .result-table table tr:nth-child(even) {
  background-color: #000000;
}

.invoice-main {
  padding: 70px 0;
}

.search-variation-outer .search-variation {
  margin: 20px auto 0;
  max-width: 800px;
}

.search-variation-outer .search-variation-submenu {
  background: #ffffff;
  text-align: left;
}

.darkMode--on .search-variation-outer .search-variation-submenu {
  background: #101010;
}

.search-variation-outer .search-variation .inputField {
  background: #ffffff;
  text-align: left;
}

.invoice-main .section-heading {
  margin-bottom: 50px;
}

.invoice-right>label {
  margin: 0 0 10px;
  display: block;
}

.darkMode--on .search-variation-outer .search-variation .inputField {
  background: #0d0d0d;
}

.darkMode--on .tabcontent-filter .search-variation-outer .search-variation .inputField {
  background: #222222;
  border: 1px solid #222222;
}

.tabcontent-filter .search-variation-outer .search-variation-submenu {
  top: 45px;
  border-radius: 0 0 3px 3px;
}

.darkMode--on .tabcontent-filter .search-variation-outer .search-variation-submenu {
  background: #222222;
}

@media (max-width: 991px) {
  .tble-clm {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tble-clm+.tble-clm {
    margin-top: 30px;
  }

  .tabcontent-filter select {
    min-width: 160px;
    padding: 10px 20px;
    font-size: 15px;
  }

  .search-variation-outer .search-variation {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .result-table button.btn {
    font-size: 13px;
    padding: 8px 14px;
  }

  .main.purchase-history-wrap {
    margin-top: 15px;
  }
}

.static-contributedForm+.auth-page-outer .section-heading {
  font-size: 30px;
  margin-bottom: 20px;
}

.tabcontent-wrap {
  margin-top: 6px;
}

/* .dashboard-tabs-outer .MuiBox-root {
  display: block;
  padding: 0;
} */

.tabcontent-inner label {
  font-size: 16px;
  margin-right: 10px;
  font-weight: 500;
}

/* checkbox couen code */

.coupon_div {
  display: flex;
  flex-wrap: wrap;
}

.coupon_div .inputField {
  background: #d7d7d7;
  border-radius: 3px 0 0 3px;
  max-width: 300px;
  flex: 0 0 300px;
}

.coupon_div .primary-btn {
  border-radius: 0px 3px 3px 0px;
}

.darkMode--on .coupon_div .inputField {
  background: #111111;
}

@media (max-width: 574px) {

  .coupon_div .inputField,
  .coupon_div .primary-btn {
    max-width: 100%;
    flex: 0 0 100%;
    border-radius: 3px;
  }

  .coupon_div .inputField {
    margin-bottom: 10px;
  }

  .checkout-payment-row .field-outer {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .imgupload-sidebar[aria-disabled="false"] {
    opacity: 1;
    pointer-events: visible;
  }

  .imgupload-sidebar[aria-disabled="true"] {
    opacity: 0.4;
    pointer-events: none;
  }
}

.customPagination ul {
  display: flex;
  justify-content: center;
  margin: 70px 0 0;
  align-items: center;
}

.customPagination ul li {
  padding: 0 5px;
}

.customPagination ul li a {
  display: inline-block;
  padding: 8px 10px;
  border-radius: 3px;
  border: 1px solid #ff5be5;
  background: none;
  color: #ff5be5;
  min-width: 40px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
}

.customPagination ul li a:hover,
.customPagination ul li.selected a {
  color: #ffffff;
  background: #ff5be5;
}

.customPagination ul li.disabled a {
  border: 1px solid #ff9ff0;
  background: #ff9ff0;
  pointer-events: none;
  color: #ffffff;
  opacity: 0.7;
}

.wishlist-column .pdp-radio-content,
.wishlist-column .pdp-detal-content {
  flex: 0 0 100%;
  max-width: 100%;
}

.wishlist-column .pdp-radio-content {
  margin-bottom: 10px;
  padding-left: 25px;
}

.wishlist-column {
  margin-bottom: 15px;
}

/* .wishlist-outer .inputField, .wishlist-outer .btn {
  padding: 0;
} */

/* .MuiBox-root.css-5lkezp {
  border-radius: 3px;
  border: none !important;
} */

.wishlist-textfield {
  flex-grow: 1;
}

.wishlist-textfield {
  height: 0;
  overflow: hidden;
  max-height: 0;
  transition: 0.3s all;
}

.wishlist-open-column .wishlist-textfield {
  height: 100%;
  max-height: 250px;
  overflow: initial;
  position: relative;
  margin-bottom: 25px;
}

.wishlist-outer .inputField {
  padding: 10px;
}

.wishlist-outer .pdp-btn {
  margin-right: 15px;
}

/* .darkMode--on .MuiBox-root.css-5lkezp {
  border-radius: 3px;
  border: none !important;
  background: #222222;
} */

/** Sources Page Columns Css jan 09***/

.resources-static h2 {
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  min-height: auto;
  margin-bottom: 15px;
}

.resources-static .inner-column p {
  text-align: left;
  color: #424242;
  font-size: 16px;
  margin-bottom: 20px;
}

.resources-static ul {
  margin: 0;
}

.resources-static ul li {
  text-align: left;
}

.wishlist-detail-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
}

.wishlist-detail-content {
  flex: 0 0 70%;
  max-width: 70%;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
}

.wishlist-sidebar {
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 50px;
}

.wishlist-detail-col {
  /* max-width: 33.33%;
  flex: 0 0 33.33%; */
  max-width: 100%;
  flex: 0 0 100%;
  padding: 15px 0;
}

.wishlist-detail-inner {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  /* height: 240px; */
}

.wishlist-detail-inner img {
  max-width: 100%;
  object-fit: cover;
}

.wishlist-detail-inner h2 {
  font-size: 18px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 2;
  transition: 0.3s all;
  opacity: 0;
}

.wishlist-detail-inner .close_icon {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  z-index: 2;
  text-align: center;
  padding: 3px 0;
  transition: 0.3s all;
  opacity: 0;
}

.wishlist-detail-inner:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  transition: 0.3s all;
  opacity: 0;
}

.wishlist-detail-inner:hover h2,
.wishlist-detail-inner:hover:after,
.wishlist-detail-inner:hover .close_icon {
  opacity: 1;
}

.wishlist-detail-inner:hover:after {
  top: 0;
}

.resource-main {
  margin: 70px 0;
}

.resource-main .container {
  max-width: 1024px;
  margin: 0 auto;
}

.resource-main img {
  max-width: 100%;
}

.resource-row {
  display: flex;
  flex-wrap: wrap;
}

.resource-column {
  max-width: 50%;
  flex: 0 0 50%;
}

.resource-column-content {
  padding-right: 30px;
}

.resource-column-image {
  padding-left: 20px;
}

.resource-row-reverse {
  flex-direction: row-reverse;
}

.resource-column-image img {
  max-width: 100%;
}

.resource-row-reverse .resource-column-content {
  padding-right: 0px;
  padding-left: 30px;
}

.resource-row-reverse .resource-column-image {
  padding-left: 0px;
  padding-right: 20px;
}

.resource-main .section-heading {
  font-size: 30px;
  line-height: 38px;
  color: #333333;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 10px;
  justify-content: flex-start;
  margin-top: 20px;
}

.resource-main h1 {
  font-size: 38px;
  margin-bottom: 20px;
  color: #333333;
}

.resource-main p,
.unorder-list-wrap li {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.resource-main p+p {
  margin-top: 15px;
}

.resource-row+.resource-row {
  margin-top: 50px;
}

.justify-content-center {
  justify-content: center;
}

.unorder-list-wrap {
  margin: 0;
  padding: 0;
  list-style: none;
}

.unorder-list-wrap li {
  position: relative;
  padding: 0 0 0 20px;
  margin: 7px 0;
  display: inline-block;
}

.unorder-list-wrap li:after {
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 6px;
  border-top: 1px solid #333333;
  border-right: 1px solid #333333;
  transform: rotate(45deg);
  top: 8px;
}

.resource-banner {
  position: relative;
  min-height: 200px;
  background: #000;
  margin-bottom: 50px;
}

.resource-banner-image {
  width: 100%;
}

.resource-banner-content {
  position: absolute;
  left: 15%;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 230px;
}

.resource-banner-content h2 {
  font-size: 35px;
  color: #333333;
  margin: 0 0 15px;
}

.resource-banner-content p {
  font-size: 18px;
  color: #333333;
  margin: 0;
}

.resource-banner-content a {
  color: #333333;
}

.resource-banner-content a:hover {
  color: var(--pc-btn-primary-bg);
}

.subheading {
  font-size: 22px;
  color: #333333;
  margin: 30px 0 10px;
}

.subheading-innerColumn {
  font-size: 18px;
  color: #333333;
  margin: 15px 0 7px;
}

.pdffooter {
  margin-top: 50px;
  text-align: center;
}

.resource-main .pdffooter .section-heading {
  text-align: center;
}

.resource-main .collection--multiimg-box-img+.collection--multiimg-box-img {
  margin-top: 40px;
}

.checkout-description-wrap {
  padding: 20px 0;
}

.checkout-description-wrap label {
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  margin: 15px 0 8px;
}

.checkout-description-wrap p {
  font-size: 16px;
  color: #333333;
}

.darkMode--on .wishlist-sidebar .inputField {
  background-color: #222222;
}

.darkMode--on .resources-static .inner-column p,
.darkMode--on .subheading-innerColumn,
.darkMode--on .resource-main .section-heading,
.darkMode--on .resource-main p,
.darkMode--on .resource-main li {
  color: var(--pc-dark-heading-clr);
}

.resource-multi-column {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
}

.resource-multi-column .column-3 {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  padding: 15px;
}

.darkMode--on .resources-static .inner-column p,
.darkMode--on .resource-main .section-heading,
.darkMode--on .resource-main p,
.darkMode--on .resource-main li {
  color: var(--pc-dark-heading-clr);
}

.resource-main h3 {
  color: #333333;
  margin: 20px 0 10px;
}

@media (max-width: 1279px) {
  .wishlist-sidebar {
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .resource-column {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
  }

  .resource-column-image {
    margin: 20px 0;
  }

  .resource-row-reverse .resource-column-content {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .wishlist-sidebar {
    padding-left: 0px;
    margin-top: 30px;
  }

  .wishlist-detail-content,
  .wishlist-sidebar {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .wishlist-detail-col {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .resource-banner-content {
    left: 26%;
  }
}

/* input[type="radio"]:checked ~ .popup-wishlist.wishlist-textfield {
   overflow: initial;
 }
 
 .wishlist-outer .pdp-btn {
   margin-right: 15px;
 } */

.release-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.release-action svg {
  width: 20px;
  height: 20px;
  fill: #333333;
}

.release-action span {
  cursor: pointer;
  margin: 0 5px;
}

.darkMode--on .release-action svg {
  fill: #d7d7d7;
}

/* .release-edit svg{
   fill: var(--toastify-color-info);
 }
 
 .release-view svg{
   fill: var(--toastify-color-dark);
 }
 
 .release-delete svg{
   fill: var(--toastify-color-error);
 } */

.create-releasebtn {
  padding: 0;
}

.nodDtaFound {
  font-size: 18px;
  color: #333;
  margin: 30px 0 0;
}

.darkMode--on .nodDtaFound {
  color: #d7d7d7;
}

.whishListForm {
  max-width: 500px;
  width: 85%;
  margin: 100px 0;
}

.licensingOuter-wrap {
  padding: 40px 0;
}

.licensingOuter {
  padding: 50px 0;
}

.licensingOuterContent {
  padding-top: 50px;
}

.licensingOuterContent p {
  margin: 0 0 10px;
  line-height: 28px;
  color: #333333;
}

.darkMode--on .licensingOuterContent p {
  color: #d7d7d7;
}

.whishListForm {
  max-width: 500px;
  width: 85%;
  margin: 100px 0;
}

.licensingOuter img {
  max-width: 100%;
}

.wishlist-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collection--multiimg {
  margin-left: 0;
}

@media (max-width: 991px) {
  .licensingOuter {
    padding: 20px 0;
  }
}

@media (max-width: 991px) {
  .wishlist-header .primary-btn {
    /* padding: 0 10px; */
    font-size: 14px;
  }
}

/* 27 jan */
.banner {
  position: relative;
  z-index: 99;
}

.banner:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: -2;
}

.bannervideo-outer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.tabcontentcolumn-info {
  position: relative;
}

.tabcontentcolumn-info:after {
  content: "";
  position: absolute;
  right: -3px;
  width: 10px;
  height: 10px;
  /* background: #73f20b; */
  border-radius: 10px;
  top: -7px;
}

.tabcontentcolumn-info.live:after {
  background: #0e7e04;
}

.tabcontentcolumn-info.pending:after {
  background: #d8b805;
}

.tabcontentcolumn-info.draft:after {
  background: #d82505;
}

.login-page-form-outer:not(.register-container-wrap) #socialBtn,
.login-page-form-outer:not(.register-container-wrap) .social-btn-fb.register-socialbtn {
  max-width: 100%;
}

.dashboard-main .container {
  padding-left: 0;
}

.field-outer.field-outerclose {
  overflow: hidden;
  height: 0;
}

.field-outer.field-outeropen {
  height: auto;
}

/* .contributor-card-outer {
   max-width: 314px;
   border: 1px solid grey;
   border-radius: 15px;
   padding: 10px;
   margin: 10px;
 } */

.pichaPopupOuter {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 9999;
}

.pichaPopupOuterContent {
  max-width: 900px;
  width: 80%;
}

.contributerPrefrence {
  display: flex;
  background: #fff;
  border-radius: 10px;
}

.prefrenceImage,
.prefrencecontentWrap {
  flex: 0 0 50%;
  max-width: 50%;
}

.prefrencecontentrow {
  display: flex;
  /* margin-left: -15px;
   margin-right: -15px; */
  flex-wrap: wrap;
}

.prefrencecontentWrap {
  padding: 30px 30px 30px 20px;
}

/* .prefrencecontentWrap .btn {
  padding: 0px 15px;
} */

/* .prefrencecontentWrap .skipbtn {
  min-width: 50px;
  padding: 4px 0;
  line-height: 18px;
  font-size: 13px;
} */

.prefrenceImage img {
  max-width: 100%;
  border-radius: 10px 0 0 10px;
  height: 100%;
  object-fit: cover;
}

.prefrencecontentColumn {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  padding: 12px 10px;
  position: relative;
  text-transform: capitalize;
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.prefrencecontentColumn .imgOverlay {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  height: 50px;
  width: calc(100% - 18px);
  top: 12px;
  left: 9px;
  border-radius: 5px;
  z-index: 1;
}

.prefbtn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.prefbtn-wrap .btn+.btn {
  margin-left: 15px;
}

.prefrencecontentrow>p {
  font-size: 16px;
  margin-top: 20px;
  color: #ff0000;
}

.prefrencecontentWrap h2 {
  margin: 0 0 10px;
}

.contributerPrefrence.hide {
  display: none;
}

.prefrencecontentrow {
  display: flex;
  flex-wrap: wrap;
  max-height: 480px;
  margin: 0 -7px 20px;
}

.contributerPrefrenceThird .prefrencecontentrow.popupcontributorList {
  height: 500px;
  overflow-y: scroll;
  margin: 16px 0 0 0;
}

.prefrencecontentColumn input[type="checkbox"] {
  position: absolute;
  left: 9px;
  top: 12px;
  height: calc(100% - 25px);
  width: calc(100% - 18px);
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.prefrencecontentColumn img {
  width: 100%;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 6px;
  border: 2px solid transparent;
}

.prefrencecontentColumn.selected-img img {
  border-color: var(--pc-btn-primary-bg);
}

.mx-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.py-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.contributoruser {
  flex: 0 0 70px;
  max-width: 70px;
}

.input-field {
  width: 100%;
  padding: 15px 15px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  background: #f2f2f2;
}

.contributoruser span {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 70px;
}

.contributoruser span img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 70px;
}

.contributorbuttonAction .btn {
  padding: 0;
}

.contributorinfo {
  max-width: 60%;
  flex: 0 0 30%;
}

.slectedDot {
  color: green;
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  background: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* licenced */

.standard-image-part {
  padding: 0 0 70px;
}

.licence-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.licence-col-3 {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  padding-left: 15px;
  padding-right: 15px;
}

.licence-col-3 img {
  max-width: 100%;
}

.licence-features h2 {
  margin: 0;
  padding: 34px 38px;
  font-size: 26px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 3px 3px 0 0;
}

.images-content-col h2 {
  background: #ff5be5;
}

.video-content-col h2 {
  background: #bee740;
  color: #333333;
}

.extend-contend-col h2 {
  background: #333333;
}

.licence-content-text {
  padding: 40px 41px;
}

.licence-content-text p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--pc-heading-clr);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  background-color: #e5e5e5;
  padding: 15px 25px;
  border-radius: var(--pc-btn-text-lh);
}

.darkMode--on .licence-features {
  background: #212121;
}

.darkMode--on .licence-features .licence-images-inner {
  background: #111111;
}

.licence-features {
  background: #f1f1f1;
  padding: 65px 0;
}

.darkMode--on .licence-content-text p {
  color: #d7d7d7;
  background: #222;
}

.licence-features .licence-images-inner {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: #ffffff;
  height: 100%;
}

.explore-collection-outer {
  padding: 50px 0;
}

/* .explore-collection-content {
  text-transform: capitalize;
} */

.explore-collection-content+.explore-collection-content {
  margin-top: 40px;
}

.popupcontributorInner {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 8px 10px 0px;
}

.popupcontributoruser span {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
}

.popupcontributoruser span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  max-width: 100%;
  border-radius: 120px;
}

.popupcontributoruser {
  position: relative;
  padding: 0 10px 15px 60px;
  flex: 0 0 70%;
  max-width: 70%;
  overflow: hidden;
}

.popupcontributoruser h3 {
  font-size: 14px;
}

.popupcontributoruser p {
  font-size: 14px;
}

.popupcontributorInner .btn {
  font-size: 12px;
  min-width: initial;
  padding: 0 11px;
  line-height: 25px;
  margin-left: auto;
}

/*  */

.contributor-card-row {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
}

.contributor-card-outer {
  padding: 15px;
  flex: 0 0 25%;
  max-width: 25%;
  cursor: pointer;
}

.contributor-card-image {
  background: #ddd;
  border-radius: 20px 20px 0 0;
}

.contributor-card-inner {
  border: 1px solid #f1f1f1;
  border-radius: 22px;
}

.contributor-card-content {
  padding: 0 0 30px;
  text-align: center;
  background: #dddddd;
  border-radius: 0 0 20px 20px;
}

.contributor-card-user {
  display: inline-block;
  background: #ff5be5;
  border-radius: 100px;
  height: 70px;
  width: 70px;
  padding: 20px;
  margin-top: -30px;
  transform: translate(0, -18%);
}

.contributor-card-user img {
  max-width: 100%;
}

.contributor-card-content h1,
.contributor-card-content h3 {
  margin: 0 0 10px;
  font-size: 24px;
  color: #333333;
}

.contributor-card-content h3 {
  font-size: 18px;
}

.contributor-card-content .pichastock--btn {
  padding: 0;
  margin: 15px 0 0;
}

/* 9th feb */

.profile-avtar {
  width: 35px;
  border-radius: 100%;
  height: 35px;
  padding: 3px;
}

.pichaPopupOuter .Toastify {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 99999;
}

.darkMode--on .contributor-card-image,
.darkMode--on .contributor-card-content {
  background: #222222;
}

.darkMode--on h3,
.darkMode--on .contributor-card-content h1,
.darkMode--on .contributor-card-content h3 {
  color: #d7d7d7;
}

.darkMode--on .contributor-card-inner {
  border: 1px solid #222222;
}

.lh-28 {
  line-height: 28px;
}

.licence-header {
  text-align: center;
  margin: 50px 0 30px;
}

.licence-header p {
  margin-top: 30px;
}

.explore-collection-content h4,
.explore-collection-content p {
  color: var(--pc-heading-clr);
}

.explore-collection-content h4 {
  margin: 0 0 15px;
  font-size: 24px;
}

.explore-collection-content p {
  line-height: 28px;
}

.darkMode--on .explore-collection-content h4,
.darkMode--on .explore-collection-content p {
  color: #d7d7d7;
}

.footer--main .validation-error {
  left: 0;
  bottom: -12px;
}

.showmore-btn {
  padding: 10px;
  line-height: 24px;
  border: none;
  border-radius: 70px;
  height: 43px;
  margin: 0;
  margin-top: 8px;
  /* dashboard */
}

.dasboard-column-outer {
  background: var(--pc-form-input-bg);
  padding: 30px;
  border-radius: 10px;
  position: relative;
  margin: 8px 0;
  overflow: hidden;
}

.darkMode--on .dasboard-column-outer {
  background: rgba(0, 0, 0, 1);
}

.dasboard-column-outer h2 {
  font-size: 24px;
  color: #333333;
  margin: 0 0 20px;
}

.darkMode--on .dasboard-column-outer h2,
.darkMode--on .dasboard-column-outer .dash-count {
  color: #d7d7d7;
}

.darkMode--on .dasboard-column-outer path {
  fill: #ffffff
}

.darkMode--on .dasboard-column-outer:after {
  background: rgba(255, 255, 255, 0.09);
}

.dash-icon {
  position: absolute;
  top: 30px;
  right: 30px;
}

.dash-count {
  font-size: 16px;
  font-weight: 600;
}

.dash-icon svg {
  width: 50px;
  height: auto;
  opacity: 1;
}

.dasboard-column-outer path {
  fill: rgba(var(--secondary-color), 1);
}

/* .dasboard-column-outer.das-totalDownload {
   background: #c3e711;
 }
 
 .dasboard-column-outer.das-totalLikes {
   background: #fe5bea;
 }
 
 .dasboard-column-outer.das-totalView {
   background: #333;
 }
 
 .dasboard-column-outer.das-totalOrder {
   background: #666666;
 } */

/* .das-totalView h2, .das-totalView .dash-count, .das-totalOrder h2, .das-totalOrder .dashcount {
   color: #ffffff;
 } */

.dasboard-column-outer:after {
  background: rgba(0, 0, 0, 0.09);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(0deg);
  width: 70%;
  clip-path: ellipse(90% 120% at 0% 0px);
}

.btn[hidden] {
  display: none;
}

.view-btn {
  cursor: pointer;
}

/* @-moz-document url-prefix() {
  .bt2 {
    opacity: 1;
  }

  .paypal-wrap .primary-btn {
    opacity: 0;
  }
} */

@media screen and (min-width: 992px) and (max-width: 1359px) {
  .wishlist-btn {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .pdp-actionbtn-outer button {
    width: 100%;
  }
}

@media (max-width: 1800px) {
  .licence-features h2 {
    padding: 0px 38px;
    display: flex;
    align-items: center;
    min-height: 102px;
  }

  .images-licencing-wrap .licence-features h2 {
    min-height: inherit;
    padding: 15px 38px;
  }
}

@media (max-width: 1279px) {
  .contributor-card-outer {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .licence-features h2 {
    padding: 21px 18px;
    font-size: 18px;
  }

  .licence-content-text {
    padding: 30px 20px;
  }
}

@media (max-width: 991px) {
  .contributor-card-outer {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .contributor-card-content h1,
  .contributor-card-content h3 {
    font-size: 18px;
  }

  .popupcontributorInner .btn {
    padding: 5px 11px;
    line-height: 15px;
  }

  .dashboard-contributed {
    margin-inline: -10px;
  }

  .dashboard-contributed .m-3-full-width {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 10px;
  }
}

@media (max-width: 767px) {
  .contributor-card-outer {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .licence-features h2 {
    min-height: initial;
  }

  .contributerPrefrence {
    flex-wrap: wrap;
  }

  .prefrenceImage img {
    display: none;
  }

  .dashboard-contributed .m-3-full-width,
  .prefrenceImage,
  .prefrencecontentWrap {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 574px) {
  .wishlist-btn {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .licence-col-3 {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 15px;
  }

  .dashboard-content-inner .graph-outer .field-outer {
    flex-direction: column;
  }

  .graph-outer .m-3-full-width {
    flex: 0 0 100%;
    width: 100%;
  }
}

.package-link {
  font-weight: 600;
}

iframe[title="Sign in with Google Button"] {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 50px !important;
}

div#socialBtn>div {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
}

.pdp-product-image .premium-svg {
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;


}

.premiumImg {
  /* width: 100px; */
  margin: 10px;
}


.pdp-product-image .premium-svg svg {
  width: 20px;
  margin: 0;
}

.invoice-logo {
  filter: brightness(0);
}

.darkMode--on .invoice-logo {
  filter: brightness(1);
}

div[hidden],
li[hidden] {
  display: none;
}

/* new sprint*/

.header-search-bar {
  max-width: 35%;
  flex: 0 0 35%;
  padding: 0 20px;
}

.header-search-bar .navbar--searchbar-outer {
  position: relative;
  top: 0;
  right: 0;
  opacity: 1;
  max-height: initial;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.header-search-bar .navbar--searchbar input {
  padding: 13px 60px 13px 15px;
}

.header-search-bar .search-action svg {
  height: 15px;
}

.author-info-image {
  max-width: 40px;
  border-radius: 40px;
  border: 1px solid rgba(var(--primary-color), 1);
  padding: 2px;
  margin-right: 5px;
}

.author-info-image img {
  border-radius: 40px;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.author-info-wrap p {
  max-width: initial;
  flex: 0 0 calc(100% - 50px);
  padding-left: 14px;
  line-height: 22px;
  font-size: 16px;
  min-height: 44px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.author-info-wrap {
  flex: 1 0;
  padding: 20px 0 13px;
}

.follow-btn {
  padding: 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.follow-btn svg {
  margin-right: 5px;
  vertical-align: middle;
}


.contributed-information {
  position: absolute;
  bottom: 90px;
  background: #ffffff;
  width: 300px;
  left: 34%;
  padding: 10px;
  border-radius: 5px;
  transform: translate(-50%, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  transition: 0.3s opacity;
}

.darkMode--on .contributed-information {
  background: #222222;
}

.contributed-information-header-inner {
  flex: 1 0 0;
}

.cntb-imagelibrary-col {
  flex: 1 0 0;
}

.cntb-imagelibrary-col img {
  width: 100%;
  height: 70px;
  margin: 0 !important;
  padding: 2px;
  border-radius: 0;
  border: none;
}

.cntb-imagelibrary-col:first-child img {
  border-radius: 10px 0 0 10px;
}

.cntb-imagelibrary-col:last-child img {
  border-radius: 0 10px 10px 0;
}

.contributed-information-header {
  margin-bottom: 10px;
}

.contributed-information:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  bottom: -5px;
  transform: rotate(45deg);
  left: 77px;
  z-index: -1;
}

.author-info-wrap:hover .contributed-information {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  bottom: 80px;
}

.collection--multiimg-view.author-info-outer {
  padding: 0;
}

.darkMode--on .author-info-wrap:hover .contributed-information,
.darkMode--on .contributed-information:after {
  background: #222222;
}

.share-profile-link {
  background: #f4f4f4;
}

.share-profile-link input {
  width: calc(100% - 42px);
}

.share-social button {
  margin-right: 8px;
}

.contributed-list {
  text-align: left;
}

.contributed-list li {
  padding-left: 65px;
  position: relative;
}

.contributed-list li+li {
  margin-top: 20px;
}

.contributed-list h5 {
  font-size: 20px;
  margin: 0 0 7px;
  font-weight: 600;
}

.contributed-list p {
  font-size: 16px;
  margin: 0;
  color: #d7d7d7;
}

.contributed-list .listicon {
  width: 45px;
  height: 45px;
  text-align: center;
  background: rgba(var(--primary-color), 1);
  display: inline-block;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1px;
  left: 0;
  color: #ffffff;
}

.contributed-list .listicon .svg-inline--fa {
  height: 1.4rem;
}

.avtarAdd {
  position: relative;
  margin-right: 16px;
}

.field-outer .avtarAdd [type="file"] {
  top: 14px;
  padding: 15px;
  line-height: inherit;
}

.contributed-information-header-inner p {
  font-size: 14px;
  max-width: 90px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Additinal Setting */
.additionalSetting.full-width {
  max-width: 800px;
}

.updateUsername {
  background: #ddd;
  border-radius: 5px;
  padding: 4px 10px;
}

.additionalSetting h3 {
  margin-bottom: 20px;
}

.additionalSetting p {
  font-weight: 500;
  line-height: 1.4;
  font-size: 15px;
}

.additionalSetting .btn {
  min-width: 120px;
  margin-right: 16px;
}

/* Switch */
.switchWrap {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-left: 16px;
}

.switchWrap .switchLable {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

.switchWrap .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switchWrap .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchWrap .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.switchWrap .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #efefef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.switchWrap input:checked+.slider {
  background-color: rgba(var(--primary-color), 1);
}

.switchWrap input:focus+.slider {
  box-shadow: 0 0 1px rgba(var(--primary-color), 1);
}

.switchWrap input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.darkMode--on .updateUsername {
  background: #222222;
}

.darkMode--on .switchWrap .slider {
  background-color: #333333;
}

.pdpPopup .pdp-download-detail-list li {
  flex: 0 0 100%;
  max-width: 100%;
  padding-inline: 0;
}

.pdpPopup .pdp-download-detail {
  padding-top: 16px;
}

.pdpPopup .pdp-download-detail-list {
  margin-top: 16px;
}

.pdpPopup .pdp-product-image {
  padding-right: 30px;
}

.profile_desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* .pdpPopup .MuiBox-root {
  border-radius: 20px;
  width: 90%;
  border: 0;
  max-width: 900px;
} */

.pdpPopup .pdp-actionbtn-outer button {
  width: 100%;
}

.pdpPopup .section-row {
  margin: 0;
}

/* .darkMode--on .pdpPopup .MuiBox-root {
  background: #010101;
} */

.noteAddImage {
  max-width: 800px;
  text-align: left;
  margin: 30px auto 10px;
}

.noteAddImage p {
  line-height: 1.5;
}

.noteAddImage p a {
  text-decoration: underline;
}

.noRecord {
  flex: 0 0 100%;
}

.darkMode--on h2,
.darkMode--on .noRecord h1 {
  color: #ffffff;
}

.darkMode--on .tabcontentcolumn-info path {
  fill: #d7d7d7;
}

.darkMode--on .swal2-popup {
  background: #222222;
}

@media (max-width: 1279px) {
  .header-search-bar {
    max-width: 30%;
    flex: 0 0 30%;
  }

  .navbar--menus ul li+li {
    margin-left: 14px;
  }

  .contributed-information {
    left: 49%;
  }
}

@media (max-width: 991px) {
  .header-search-bar {
    display: none;
  }

  .search-field-outer {
    display: block;
  }

  .open-search .navbar--searchbar-outer {
    position: static;
    margin-top: 10px;
  }

  .contributed-list {
    max-width: 100%;
    margin-top: 40px;
  }

  .contributor--inside .outer-row {
    flex-flow: column-reverse;
  }

  .pdpPopup .pdp-product-image {
    display: none;
  }

  .contributed-information {
    display: none;
  }
}

[hidden] {
  display: none !important;
}

.social-btn {
  border: none;
}

.darkMode--on .swal2-html-container {
  color: #fff;
}

.pdpPopup {
  z-index: 9999 !important;
}

@media (max-width: 767px) {
  .pdpPopup .pdp-detal-content-wrap {
    padding: 6px 17px;
  }

  /* .pdpPopup .MuiBox-root {
    padding: 22px;
  } */

  .pdpPopup .pdp-actionbtn-outer button {
    padding: 10px 0;
  }

  .pdpPopup .pdp-download-detail-list p {
    padding-left: 0;
  }

  .header-search-bar .navbar--searchbar input {
    display: none;
  }
}

@media (max-width: 574px) {
  .pdp-download-detail .pdp-actionbtn-outer button {
    width: 100%;
    margin: 7px 0 !important;
  }
}

.static-page-wrapper img {
  max-width: 100%;
}

.popup-wishlist button.MuiButtonBase-root {
  padding: 0 !important;
}

.popup-wishlist .MuiAutocomplete-clearIndicator {
  margin-right: 5px;
}

.wishlist-outer .popup-wishlist .inputField {
  padding: 0;
}

/**/

.imageLicensing-content-header {
  font-size: 24px;
  text-align: center;
  padding: 0 0 20px;
  border-bottom: 1px solid #eee;
}

.imageLicensing-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
  align-items: flex-start;
}

/* .imageLicensing-column-lg-3 {
  max-width: 25%;
  flex: 0 0 25%;
} */

.px-15 {
  /* padding-left:15px;
  padding-right:15px; */
  padding: 15px;
}

.imageLicensing-content {
  padding: 70px 0;
}

.imageLicensing-column-inner {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}

.imageLicensing-info {
  width: 180px;
  height: 180px;
  background: #f3f3f3;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
}

.imageLicensing-info h2 {
  margin: 0;
  /* font-size: 45px; */
  font-weight: 500;
  color: #333333;
  font-size: 50px;
}

.imageLicensing-content-header {
  text-align: center;
  padding: 0 0 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 35px;
  text-transform: capitalize;
}

.imageLicensing-info h2 span {
  font-size: 12px;
  font-weight: 400;
}

.imageLicensing-info p {
  font-size: 12px;
  margin-top: 5px;
  padding: 0 10px;
}

.imageLicensing-info-content {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 20px;
}

.imageLicensing-info-content p {
  line-height: 26px;
  font-size: 14px;
}

.subheader {
  margin-bottom: 50px;
  font-weight: 500;
  color: #333333;
}

.imageLicensing-offers {
  background: #e4e4e4;
  padding: 70px 0;
}

.imageLicensing-offers .imageLicensing-content-header {
  border: none;
  font-size: 44px;
  margin: 0 0 30px;
  padding: 0;
}

.imageLicensing-price svg {
  height: 15px;
}

.imageLicensing-title-outer {
  width: 100%;
}

.imageLicensing-title {
  max-width: 300px;
  margin: 0 auto;
  background: #d3d3d3;
  padding: 15px 10px;
  border-radius: 40px;
  width: 90%;
  font-size: 18px;
  font-weight: 600;
}

.imageLicensing-price {
  margin-top: 20px;
  font-weight: 600;
}

.imageLicensing-content-header svg {
  height: 22px;
  width: 22px;
}

.explore-collection-content {
  padding: 80px 0 0;
}

.explore-collection-content+.explore-collection-content {
  margin: 0;
  padding-bottom: 80px;
}

.explore-collection-content p {
  margin: 0 !important;
}

.thumnail-wrap {
  display: flex;
  align-items: center;
}

.thumnail-wrap img {
  max-width: 60px;
  margin-right: 16px;
}

@media (min-width: 575px) {}

@media (min-width: 768px) {
  .imageLicensing-column-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .imageLicensing-column-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .imageLicensing-column-lg-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}

@media (min-width: 1200px) {
  .imageLicensing-column-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

/* dark*/

.darkMode--on {
  background: #0d0d0d;
}

.darkMode--on .imageLicensing-content-header,
.darkMode--on .imageLicensing-info-content p,
.darkMode--on .imageLicensing-info h2,
.darkMode--on .imageLicensing-info p,
.darkMode--on .imageLicensing-price {
  color: #d7d7d7;
}

.darkMode--on .imageLicensing-info {
  background: #222222;
}

.darkMode--on .imageLicensing-offers {
  background: #424242;
}

.darkMode--on .imageLicensing-price path,
.darkMode--on .imageLicensing-content-header path {
  fill: #d7d7d7;
}

.gflag {
  vertical-align: middle;
  font-size: 16px;
  padding: 1px 0;
  background-repeat: no-repeat;
  background-image: url(//gtranslate.net/flags/16.png);
  margin-right: 5px;
}

.gflag img {
  border: 0;
}

.gflag:hover {
  background-image: url(//gtranslate.net/flags/16a.png);
}

.VideoPlayer video {
  border-radius: 20px;
}

.container-guide-upload-wrap {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.imgOuter img {
  max-width: 100%;
}

.swal2-container {
  z-index: 9999 !important;
}

.g-translate {
  z-index: 11;
  position: fixed;
  right: 10px;
  top: 7px;
  /* top: 37px !important; Adjusted to be just below the banner */
}

@media (max-width: 991px) {
  .g-translate {
    top: 3px;
    /* top: 23px;Adjusted to be just below the banner */
  }

  .navbar {
    padding-top: 16px;
  }

  .outer-row.mt-5.flex-wrap {
    margin-top: 20px !important;
  }

  .member--main .outer-row .column-medium-6 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .graph-outer-dashboard .m-half-width {
    width: 100%;
  }
}

.licence-pagemain .section-heading {
  --pc-heading: 35px;
}

.licence-features h2 {
  font-size: 18px;
}

.imageLicensing-content-header {
  font-size: 25px;
}

.imageLicensing-info h2 {
  font-size: 40px;
}

.imageLicensing-offers .imageLicensing-content-header {
  font-size: 35px;
}

.footerCaptcha div {
  width: auto !important;
  height: auto !important;
}

.danger-bg {
  background-color: #ffa8a8;
  color: #000;
}

.success-bg {
  background-color: #9fe19f;
  color: #000;
}

.eventImage img {
  border-radius: 3px;
  max-width: 100%;
}

.static-page-wrapper ol ol li:after,
.static-page-wrapper ul ul li:after {
  background: #222;
  border: none;
}

.remove-button {
  position: absolute;
  right: -6px;
  top: -7px;
  background: #333;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.remove-button svg {
  width: 16px;
  height: 16px;
  margin: 0;
}

.remove-button svg path {
  fill: #fff;
}

.overflow-visible {
  overflow: visible;
}

.field-outer .react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 53px;
  height: 100%;
  padding: 0 0 0 15px;
  border-radius: 3px 0 0 8px;
}

.field-outer .react-tel-input .flag-dropdown.open .selected-flag,
.field-outer .react-tel-input .selected-flag:hover,
.field-outer .react-tel-input .selected-flag:focus {
  background: #e3e3e3;
}

.field-outer .react-tel-input .flag-dropdown {
  border: none;
  left: 0;
}

.field-outer .react-tel-input .form-control {
  border: none;
  background-color: #f4f4f4;
  color: #666666;
}

.bannervideo-wrap video {
  object-fit: cover;
}

/* new changes in video banner*/

.banner {
  overflow: hidden;
}

.bannervideo-wrap {
  width: 100% !important;
  height: 100% !important;
}

.bannervideo-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videobanner-loader {
  position: absolute;
  width: 100%;
  top: 0;
  /* left: 50%;
  top: 26%;
  transform: translate(-50%, -50%);*/
  z-index: 1;
  opacity: 1;
  transition: all 1s ease-in-out;
}

.videobanner-loader.hideImage {
  opacity: 0;
}

.videobanner-loader img {
  width: 100%;
}

.shimmer-block {
  background: #f6f7f8;
  background-image: linear-gradient(to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.shimmer-title {
  height: 24px;
  margin-bottom: 16px;
  width: 70%;
}

.shimmer-btn {
  height: 20px;
  width: 120px;
  margin-right: 8px;
  border-radius: 10px;
}

.shimmer-carousel {
  display: flex;
  margin-top: 16px;
}

.shimmer-carousel-item {
  height: 200px;
  width: 100%;
  margin-right: 8px;
  border-radius: 10px;
}


.wrapper {
  text-align: center;
}

.wrapper .img {
  margin: auto;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin-bottom: 20px;
}

.wrapper .img i {
  font-size: 25px;
}

.wrapper .product {
  padding: 30px;
}

.wrapper .product h3 {
  font-weight: 500;
}

.wrapper p {
  font-size: 15px;
  margin-top: 20px;
  color: grey;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.product {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 0px 13px;
  margin: 10px;
  /* background:  linear-gradient(45deg, #ffafbd, #ffc3a0); */


}




.smallBtn {
  width: 70px;
  height: 40px;
  background-color: transparent;
  border-radius: 8px;
  padding: 10px;
  border-color: #dddddd;
  border: 1px solid #dddddd;
  margin: 10px;

}

.smallBtn2 {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border-radius: 8px;
  padding: 10px;
  border-color: #dddddd;
  border: 1px solid #dddddd;
  margin: 10px;

}


.ai-page-left {
  margin-left: 10px;
}


.input-error {
  border-color: #e74c3c;
}

.validation-error {
  color: #e74c3c;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 0;
}